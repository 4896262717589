<template>
  <ul class="article-list">
    <li
      v-for="(item, index) in props.contentList"
      :key="index"
      class="list-item"
    >
      <a :href="item.url">
        <h4 class="title">{{ item.title }}</h4>
        <span class="digest">{{ item.digest }}</span>
        <span class="time">{{
          item.publishTime ? formatDate(item.publishTime) : item.publishTime
        }}</span>
        <span class="icon"></span>
      </a>
    </li>
  </ul>
</template>
<script setup>
// #region 引用
import { formatDate } from '@/utils/common/date-util';
// #endregion

// #region props/emit
const props = defineProps({
  contentList: {
    type: Array,
    default: () => [],
  },
});
// #endregion

// #region 变量/常量

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

// #endregion
</script>
<style scoped>
.article-list {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 15px;
}

.article-list .list-item {
  width: 100%;
  font-size: 16px;
  text-align: left;
  background-color: #f4f4f4;
}

.article-list .list-item a {
  display: flex;
  align-items: center;
  width: 100%;
  line-height: 50px;
}

.article-list .list-item a .title {
  margin-left: 10px;
  color: #444;
  white-space: nowrap;
}

.article-list .list-item a .digest {
  padding-right: 10px;
  margin-left: 10px;
  overflow: hidden;
  color: #555;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.article-list .list-item a .time {
  margin-right: 10px;
  margin-left: auto;
  color: #555;
  white-space: nowrap;
}
.article-list .list-item a .icon {
  width: 26px;
  height: 26px;
  margin-right: 10px;
  background: url('./image/icon-right.png') no-repeat;
  background-size: 26px 26px;
}

.article-list .list-item::after {
  clear: both;
}
</style>
