<template>
  <div>
    <div
      class="image-text-container"
      @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
      @mouseleave="handleShowOperationBtn(false)"
    >
      <div
        class="content"
        :style="`color:${configDataForm.fontColor}`"
        v-html="formatHtmlContent(configDataForm.content)"
      ></div>

      <div class="overview">
        <div
          v-for="(item, index) in configDataForm.overviewItems"
          :key="index"
          class="overview-item"
          :style="`background-color:${item.bgColor};`"
        >
          <span class="num" :style="`color:${item.numFontColor}`">{{
            item.num
          }}</span>
          <span class="title" :style="`color:${item.titleFontColor}`">{{
            item.title
          }}</span>
        </div>
      </div>

      <client-only>
        <operation-container
          v-if="showOperationBtn && !showEditForm"
          :component-data="props.componentData"
          :show-edit-content-button="props.showEditContentButton"
          :show-edit-components-button="props.showEditComponentsButton"
          :show-delete-components-button="props.showDeleteComponentsButton"
          :show-insert-components-button="props.showInsertComponentsButton"
          :show-sort-components-button="props.showSortComponentsButton"
          @handle-show-edit-form="handleShowEditForm"
          @handle-change-components="handleChangeComponents"
          @handle-delete-components="handleDeleteComponents"
          @handle-insert-components="handleInsertComponents"
          @handle-sort-components="handleSortComponents"
        ></operation-container>
        <el-drawer
          v-model="showEditForm"
          size="60%"
          :title="props.componentData.componentName"
        >
          <image-text-edit-form
            v-if="showEditForm"
            v-model="configDataForm"
            :component-data="props.componentData"
            @handle-show-edit-form="handleShowEditForm"
            @handle-edit-components-content="handleEditComponentsContent"
          ></image-text-edit-form>
        </el-drawer>
      </client-only>
    </div>
  </div>
</template>

<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import OperationContainer from '../common/OperationContainer.vue';
import ImageTextEditForm from './ImageTextEditFormTwentyOne.vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量

// 表单绑定对象
const configDataForm = ref({
  content: '示例内容示例内容示例内容示例内容示例内容示例内容',
  fontColor: '#333333',
  overviewItems: [
    {
      title: '示例概况标题1',
      num: '8700',
      bgColor: '#e2e9f1',
      titleFontColor: '#666666',
      numFontColor: '#194193',
    },
    {
      title: '示例概况标题2',
      num: '700+',
      bgColor: '#e2e9f1',
      titleFontColor: '#666666',
      numFontColor: '#194193',
    },
  ],
});

if (props.componentData.configData) {
  // 服务器渲染的直接调用
  configDataForm.value = JSON.parse(props.componentData.configData);
}

// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 格式化Html显示内容
 */
const formatHtmlContent = (content) => {
  return content.replace(/\n/g, '<br/>');
};

/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};
/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};
/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // console.log('configDataForm--1:', configDataForm);
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};

// #endregion
</script>

<style scoped lang="scss">
@media screen and (device-width >= 1180px) {
  .image-text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: self-start;
    justify-content: center;
    width: 100%;
    padding: 15px;

    .content {
      display: block;
      font-size: 14px;
      line-height: 1.5em;
    }

    .overview {
      display: flex;
      gap: 15px;
      width: 100%;

      .overview-item {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        .num {
          font-size: 24px;
          font-weight: 700;
        }
        .title {
          margin-top: 10px;
          font-size: 16px;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media screen and (device-width <= 1180px) {
  .image-text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4vw;
    align-items: self-start;
    justify-content: center;
    width: 100%;
    padding: 3vw;

    .content {
      display: block;
      font-size: 3.6vw;
      line-height: 1.5em;
    }

    .overview {
      display: flex;
      gap: 3vw;
      width: 100%;

      .overview-item {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5vw 1vw;
        .num {
          font-size: 6vw;
          font-weight: 700;
        }
        .title {
          margin-top: 3vw;
          font-size: 4vw;
        }
      }
    }
  }
}
</style>
