<template>
  <div>
    <div
      class="column-content-container"
      :style="{
        backgroundImage: `url(${configDataForm.backgroundImageFullUrl})`,
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        'background-position': 'center',
      }"
      @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
      @mouseleave="handleShowOperationBtn(false)"
    >
      <a
        v-for="item in columnContentList"
        :key="item.id"
        :href="item.url ? item.url : '#'"
        class="column-content-item"
      >
        <span
          class="column-content-item-image"
          :style="{ 'background-color': item.backgroundColor }"
        >
          <img :src="item.coverImageUrl" fit="cover" />
        </span>
        <span class="column-content-item-content">
          <span class="column-content-item-content-title">
            {{ item.title }}
          </span>
          <span class="column-content-item-content-desc">
            {{ item.digest }}
          </span>
          <span class="column-content-item-content-date">
            {{
              new Date(item.publishTime).getFullYear() +
              '-' +
              (new Date(item.publishTime).getMonth() + 1) +
              '-' +
              new Date(item.publishTime).getDate()
            }}
          </span>
        </span>
      </a>
      <client-only>
        <operation-container
          v-if="showOperationBtn && !showEditForm"
          :component-data="props.componentData"
          :show-edit-content-button="props.showEditContentButton"
          :show-edit-components-button="props.showEditComponentsButton"
          :show-delete-components-button="props.showDeleteComponentsButton"
          :show-insert-components-button="props.showInsertComponentsButton"
          :show-sort-components-button="props.showSortComponentsButton"
          @handle-show-edit-form="handleShowEditForm"
          @handle-change-components="handleChangeComponents"
          @handle-delete-components="handleDeleteComponents"
          @handle-insert-components="handleInsertComponents"
          @handle-sort-components="handleSortComponents"
        ></operation-container>
      </client-only>
    </div>
    <client-only>
      <el-drawer
        v-model="showEditForm"
        size="70%"
        :title="props.componentData.componentName"
      >
        <column-content-edit-form
          v-if="showEditForm"
          v-model="configDataForm"
          :component-data="props.componentData"
          @handle-show-edit-form="handleShowEditForm"
          @handle-edit-components-content="handleEditComponentsContent"
        ></column-content-edit-form>
      </el-drawer>
    </client-only>
  </div>
</template>

<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import OperationContainer from '../common/OperationContainer.vue';
import columnContentEditForm from './ColumnContentEditFormFive.vue';

// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量
const columnContentList = ref([]);

// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);
// 创建变量设默认值
const configDataForm = ref('');
if (props.componentData.configData) {
  // 直接这样写SSR服务器渲可以得到数据
  configDataForm.value = JSON.parse(props.componentData.configData);
  columnContentList.value = configDataForm.value.items[0].materialInfo;
  /**
   * 后台编辑，需要等数据更新了都
   */
  nextTick(() => {
    configDataForm.value = JSON.parse(props.componentData.configData);
    columnContentList.value = configDataForm.value.items[0].materialInfo;
  });
}
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法
/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};

/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};
/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

// #endregion
</script>
<style scoped>
.column-content-container {
  position: relative;

  /* margin: 50px 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 446px;
}

.column-content-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 380px;
  padding: 14px;
  overflow: hidden;
  border: 1px solid var(--el-color-grey-light-9-238);
  transition: all 0.3s ease;
}

.column-content-item:hover {
  margin-top: -18px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.column-content-item:not(:last-of-type) {
  margin-right: 24px;
}

.column-content-item-image {
  width: 380px;
  height: 230px;
  padding: 8px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  transition: 0.4s;
}

.column-content-item-image img {
  width: 100%;
  height: 100%;
}

.column-content-item-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  transition: 0.4s;
}

.column-content-item-content-title {
  max-width: 350px;
  margin: 10px 0 0;
  overflow: hidden;
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  color: var(--el-color-black);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.column-content-item-content-desc {
  display: -webkit-box;
  max-width: 350px;

  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  height: 40px;
  margin: 0 0 15px;
  overflow: hidden;
  font-size: 16px;
  color: var(--el-color-grey-light-9);
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.column-content-item-content-date {
  display: -webkit-box;
  max-width: 350px;
  margin: 0 0 15px;

  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */

  overflow: hidden;
  font-size: 16px;
  color: var(--el-color-grey-light-9);
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
