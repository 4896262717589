<template>
  <div v-if="contentComponent">
    <component
      :is="dom[bundleComponent(item.componentType)]"
      v-for="item in componentList"
      :key="new Date().getTime() + Math.random() + item.componentType"
      :component-data="item"
      :show-edit-content-button="props.showEditContentButton"
      :show-edit-components-button="props.showEditComponentsButton"
      :show-delete-components-button="props.showDeleteComponentsButton"
      :show-insert-components-button="props.showInsertComponentsButton"
      :show-sort-components-button="props.showSortComponentsButton"
    ></component>
  </div>
</template>
<script setup>
// #region 引用
// 顶部导航第一种风格
import carousel from '@/views/components/website/carousel/Carousel.vue';
import areaTitle from '@/views/components/website/area-title/AreaTitle.vue';
import imageTextSet from '@/views/components/website/image-text-set/ImageTextSet.vue';
import columnContent from '@/views/components/website/column-content/ColumnContent.vue';
import imageText from '@/views/components/website/image-text/ImageText.vue';
import footerNav from '@/views/components/website/footer/Footer.vue';
import friendLink from '@/views/components/website/friend-link/FriendLink.vue';
import { OwComponentType } from '@/configs/enums/website';
import useSSRInitialStateStore from '@/stores/ssr-state';
import { getMaterialPageUrl } from '@/utils/business/website';

// #endregion

// #region props/emit

/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: false,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  templatePage: {
    type: Object,
    default() {},
  },
  fromType: {
    type: Number,
    default: 3, // 1是模板，2是配置，3是官网
  },
});
// console.log('🚀 ~ props:', props);

/**
 * 方法
 */
const emits = defineEmits([
  // 'handle-show-edit-configDataForm',
  // 'handle-change-components',
  'handle-delete-components',
  // 'handle-insert-components',
  'handle-sort-components',
  'handle-refresh-components',
]);
// #endregion

// #region 变量/常量

const previewId = ref();

if (!import.meta.env.SSR) {
  const ssrInitialState = useSSRInitialStateStore();
  previewId.value = ssrInitialState.data.current.previewId;
}

/**
 * 中间内容组件
 */

const contentComponent = props.templatePage.componentList.find(
  (f) =>
    f.componentType !== OwComponentType.版权区组件 &&
    f.componentType !== OwComponentType.顶部导航栏组件,
);

// console.log('contentComponent', contentComponent);

/**
 * 中间内容组件列表
 */
const componentList = props.templatePage.componentList.filter(
  (f) =>
    f.componentType !== OwComponentType.版权区组件 &&
    f.componentType !== OwComponentType.顶部导航栏组件,
);

// 在排序之前设置 fromType 属性

componentList.value = componentList.map((component) => ({
  ...component,
  fromType: 3,
}));

// 进行排序
componentList.sort((a, b) => {
  const sortNumA = Number(a.sortNum);
  const sortNumB = Number(b.sortNum);
  return sortNumA - sortNumB;
});

/**
 * 处理栏目内容跳转链接
 */

componentList.forEach((element) => {
  if (
    element.componentType === OwComponentType.栏目内容组件 &&
    element.configData
  ) {
    const configData = JSON.parse(element.configData);
    if (configData.items) {
      configData.items.forEach((f) => {
        f.materialInfo.forEach((f1) => {
          f1.url = getMaterialPageUrl(f.dataSourceId, f1.id, previewId.value);
          f.url = getMaterialPageUrl(f.dataSourceId, f1.id, previewId.value);
        });
      });
    }

    if (configData.leftItem) {
      configData.leftItem.materialInfo.forEach((f1) => {
        f1.url = getMaterialPageUrl(
          configData.rightItem.dataSourceId,
          f1.id,
          previewId.value,
        );
      });
    }

    if (configData.rightItem) {
      configData.rightItem.materialInfo.forEach((f1) => {
        f1.url = getMaterialPageUrl(
          configData.rightItem.dataSourceId,
          f1.id,
          previewId.value,
        );
      });
    }

    element.configData = JSON.stringify(configData);
  }
});
/**
 * 动态渲染组件
 */
const dom = shallowReactive({
  carousel,
  areaTitle,
  columnContent,
  imageText,
  imageTextSet,
  friendLink,
  footerNav,
});

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 绑定主键
 * @param {*} componentType
 */
const bundleComponent = (componentType) => {
  let component = '';
  switch (componentType) {
    case OwComponentType.顶部导航栏组件:
      component = 'topNav';
      break;
    case OwComponentType.轮播图组件:
      component = 'carousel';
      break;
    case OwComponentType.区域标题组件:
      component = 'areaTitle';
      break;
    case OwComponentType.栏目内容组件:
      component = 'columnContent';
      break;
    case OwComponentType.图文组合组件:
      component = 'imageText';
      break;
    case OwComponentType.图文组合集组件:
      component = 'imageTextSet';
      break;
    case OwComponentType.独立块状组件:
      component = 'friendLink';
      break;
    case OwComponentType.友情链接组件:
      component = 'friendLink';
      break;
    case OwComponentType.底部导航区组件:
      component = 'footerNav';
      break;
    case OwComponentType.版权区组件:
      component = 'copyrightNav';
      break;
    default:
      break;
  }

  return component;
};

// #endregion
</script>
