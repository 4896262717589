<template>
  <div class="footer-form-container">
    <el-form
      ref="ruleFormRef"
      :model="configDataForm"
      :rules="rules"
      class="account-form"
      :size="formSize"
    >
      <div class="card-frame-container">
        <el-row
          v-for="(item, index) in configDataForm"
          :key="item"
          class="el-row-customer"
        >
          <span class="copyright-form-item-form">
            <el-col :span="24">
              <el-form-item
                :label="'标题'"
                label-width="60px"
                :prop="`[${index}].title`"
                :rules="rules.text"
                @change="handleInputChange(`[${index}].title`)"
              >
                <el-input v-model="item.title" />
              </el-form-item>

              <el-form-item
                :label="'子项'"
                label-width="60px"
                :rules="rules.text"
              >
                <div class="sub-items-container">
                  <el-row
                    v-for="(subItem, subIndex) in item.items"
                    :key="subItem"
                    class="el-row-customer"
                  >
                    <span class="copyright-form-item-form">
                      <el-col :span="24">
                        <el-form-item
                          :label="'名称'"
                          label-width="100px"
                          :prop="`[${index}].items[${subIndex}].subTitle`"
                          :rules="rules.text"
                          @change="
                            handleInputChange(
                              `[${index}].items[${subIndex}].subTitle`,
                            )
                          "
                        >
                          <el-input v-model="subItem.subTitle" />
                        </el-form-item>
                        <el-form-item
                          :label="'跳转链接：'"
                          label-width="100px"
                          :prop="`[${index}].items[${subIndex}].linkUrl`"
                          :rules="rules.linkUrl"
                          @change="
                            handleInputChange(
                              `[${index}].items[${subIndex}].linkUrl`,
                            )
                          "
                        >
                          <el-input v-model="subItem.linkUrl" />
                        </el-form-item>
                      </el-col>
                    </span>
                    <span class="copyright-form-item-remove">
                      <template v-if="item.items.length > 1">
                        <el-link
                          type="danger"
                          @click="handleSubDel(index, subIndex)"
                        >
                          移除
                        </el-link>
                      </template>
                      <template v-else>
                        <el-tooltip
                          content="至少保留一项版权内容项"
                          placement="top"
                        >
                          <el-link type="danger" disabled> 移除 </el-link>
                        </el-tooltip>
                      </template>
                    </span>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <template v-if="item.items.length <= 100">
                        <el-button
                          style="width: 100%"
                          @click="handleSubAddItem(index)"
                        >
                          <el-icon><Plus /></el-icon>添加项</el-button
                        >
                      </template>
                      <template v-else>
                        <el-tooltip content="最多添加100项" placement="top">
                          <el-button style="width: 100%" disabled>
                            <el-icon><Plus /></el-icon>添加项</el-button
                          >
                        </el-tooltip>
                      </template>
                    </el-col>
                  </el-row>
                </div>
              </el-form-item>
            </el-col>
          </span>
          <span class="copyright-form-item-remove">
            <template v-if="configDataForm.length > 1">
              <el-link type="danger" @click="handleDel(index)"> 移除 </el-link>
            </template>
            <template v-else>
              <el-tooltip content="至少保留一项版权内容项" placement="top">
                <el-link type="danger" disabled> 移除 </el-link>
              </el-tooltip>
            </template>
          </span>
        </el-row>
        <el-row>
          <el-col :span="24">
            <template v-if="configDataForm.length < 100">
              <el-button style="width: 100%" @click="handleAddItem()">
                <el-icon><Plus /></el-icon>添加项</el-button
              >
            </template>
            <template v-else>
              <el-tooltip content="最多添加100项" placement="top">
                <el-button style="width: 100%" disabled>
                  <el-icon><Plus /></el-icon>添加项</el-button
                >
              </el-tooltip>
            </template>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <div class="footer-btn-container">
      <el-button @click="handleCancel()">取消</el-button>
      <el-button type="primary" @click="handleSave(ruleFormRef)"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script setup>
// #region 引用
import {
  verifyCharacterLength,
  verifyLinkUrl,
} from '@/utils/common/form-verify-util';
import { Plus } from '@element-plus/icons-vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  componentData: {
    type: Object,
    default() {},
  },
});
/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'update:modelValue',
  'handle-edit-components-content',
]);

// #endregion

// #region 变量/常量

// 栏目数据列表

// 表单size
const formSize = ref('default');
const ruleFormRef = ref();

// 表单绑定对象
const configDataForm = ref([
  {
    title: '示例标题1',
    fontColor: '#333333',
    items: [
      { subTitle: '示例子项标题1', linkUrl: '' },
      { subTitle: '示例子项标题2', linkUrl: '' },
    ],
  },
  {
    title: '示例标题2',
    fontColor: '#333333',
    items: [
      { subTitle: '示例子项标题1', linkUrl: '' },
      { subTitle: '示例子项标题2', linkUrl: '' },
    ],
  },
]);

if (props.componentData.configData) {
  configDataForm.value = JSON.parse(props.componentData.configData);
}
// 表单规则
const rules = ref({
  textColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  text: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 50, trigger: 'blur' },
  ],
  linkUrl: [{ validator: verifyLinkUrl, clen: 200, trigger: 'blur' }],
});
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 处理输入框变化的事件
 *
 * @param inputKey 输入框的键名
 */
const handleInputChange = (inputKey) => {
  ruleFormRef.value.validateField(inputKey, (valid) => {
    console.log(`ruleFormRef.value.validateField ~ ${inputKey}-valid:`, valid);
  });
};

/**
 * 处理取消
 */
const handleCancel = () => {
  emits('handle-show-edit-form', false);
};
/**
 * 处理保存
 */
const handleSave = (form) => {
  if (!form) return;
  form.validate((valid, fields) => {
    if (valid) {
      // 待数据更新完成后才执行
      nextTick(() => {
        emits('handle-edit-components-content', false);
        emits('update:modelValue', configDataForm.value);
      });
      // TODO 待完成保存逻辑
    } else {
      console.log('fields:', fields);
    }
  });
};

/**
 * 处理删除（图标）
 */
const handleDel = (index) => {
  configDataForm.value.splice(index, 1);
  //   handleResetRules();
};

/**
 * 处理删除（图标）-- 子项
 */
const handleSubDel = (index, subIndex) => {
  configDataForm.value[index].items.splice(subIndex, 1);
  //   handleResetRules();
};

/**
 * 添加项目
 */
const handleAddItem = () => {
  configDataForm.value.push({
    title: '',
    fontColor: '#333333',
    items: [
      { subTitle: '', linkUrl: '' },
      { subTitle: '', linkUrl: '' },
    ],
  });
};

/**
 * 添加项目 -- 子项
 */
const handleSubAddItem = (index) => {
  configDataForm.value[index].items.push({
    subTitle: '',
    linkUrl: '',
  });
};

// #endregion
</script>

<style scoped>
.footer-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin: auto;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.footer-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: var(--el-color-white);
}

.footer-btn-container button {
  margin: 8px;
}

:deep(.el-form) {
  width: 100%;
  padding: 20px;
}

.card-frame {
  margin-bottom: unset !important;
}

.el-row-customer {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  width: 100%;
  padding-top: 10px;
  margin: 10px 0;
  border: 1px solid;
  border-color: var(--el-color-grey-light-45);

  /* flex-direction: column; */
}

.sub-items-container {
  width: 100%;
}

.card-frame-container .el-form-item {
  margin-bottom: 18px;
}

.sub-items-container .el-form-item {
  margin-bottom: 18px;
}

.copyright-form-item-form {
  width: calc(100% - 44px) !important;
}

.copyright-form-item-remove {
  width: 24px !important;
  margin: 0 5px;
}

:deep(.el-color-picker__trigger) {
  width: 100px;
}
</style>
