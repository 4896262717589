<template>
  <div
    class="footer-four"
    :style="{
      color: configDataForm.fontColor,
      background: configDataForm.backGroundColor,
      backgroundImage: `url(${configDataForm.backgroundImageFullUrl})`,
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
      'background-position': 'center',
    }"
  >
    <div
      class="footer-container"
      @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
      @mouseleave="handleShowOperationBtn(false)"
    >
      <div class="connect">
        <span v-for="item in configDataForm.items" :key="item">
          <span class="connect-icon">
            <img :src="item.iconFullUrl" fit="cover" />
          </span>
          <span class="connect-desc">
            {{ item.text }}
          </span>
        </span>
      </div>
      <div class="logo">
        <img :src="configDataForm.logoImageFullUrl" fit="cover" />
      </div>
      <div class="qr-code">
        <div
          v-for="item in configDataForm.qrCodeItems"
          :key="item"
          class="qr-code-item"
        >
          <span class="qr-code-item-image">
            <img :src="item.iconFullUrl" fit="cover" />
          </span>
          <span class="qr-code-item-desc">{{ item.text }}</span>
        </div>
      </div>
      <client-only>
        <operation-container
          v-if="showOperationBtn && !showEditForm"
          :component-data="props.componentData"
          :show-edit-content-button="props.showEditContentButton"
          :show-edit-components-button="props.showEditComponentsButton"
          :show-delete-components-button="props.showDeleteComponentsButton"
          :show-insert-components-button="props.showInsertComponentsButton"
          :show-sort-components-button="props.showSortComponentsButton"
          @handle-show-edit-form="handleShowEditForm"
          @handle-change-components="handleChangeComponents"
          @handle-delete-components="handleDeleteComponents"
          @handle-insert-components="handleInsertComponents"
          @handle-sort-components="handleSortComponents"
        ></operation-container>
      </client-only>
    </div>
    <client-only>
      <el-drawer
        v-model="showEditForm"
        size="50%"
        :title="props.componentData.componentName"
      >
        <footer-edit-form
          v-if="showEditForm"
          v-model="configDataForm"
          :component-data="props.componentData"
          @handle-show-edit-form="handleShowEditForm"
          @handle-edit-components-content="handleEditComponentsContent"
        ></footer-edit-form>
      </el-drawer>
    </client-only>
  </div>
</template>
<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import OperationContainer from '../common/OperationContainer.vue';
import FooterEditForm from './FooterEditFormFour.vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

// console.log('props:',props);

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量
const configDataForm = ref();

if (props.componentData.configData) {
  // 服务器渲染的直接调用
  configDataForm.value = JSON.parse(props.componentData.configData);
  configDataForm.value.items.sort((a, b) => {
    return a.sortNum - b.sortNum;
  });
  configDataForm.value.qrCodeItems.sort((a, b) => {
    return a.sortNum - b.sortNum;
  });

  // 后台编辑的处理一下
  // nextTick(() => {
  //   configDataForm.value = JSON.parse(props.componentData.configData);
  //   configDataForm.value.items.sort((a, b) => {
  //     return a.sortNum - b.sortNum;
  //   });
  //   configDataForm.value.qrCodeItems.sort((a, b) => {
  //     return a.sortNum - b.sortNum;
  //   });
  // });
}

// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法
/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};
/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};
/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // console.log('configDataForm--1-FooterThree:', configDataForm);
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};

// #endregion
</script>
<style scoped lang="scss">
/* 在屏幕宽度大于等于 1180px 时应用以下样式 */
@media screen and (device-width >= 1180px) {
  .footer-four {
    position: relative;
    padding: 20px 100px 0;
    .footer-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid #656b7b;

      .connect {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .connect-icon {
          justify-content: center;
          width: 28px;
          height: 28px;
          margin-right: 20px;
          background: #555c6c;
          border-radius: 50%;

          img {
            width: 20px;
            height: 20px;
          }
        }

        .connect-desc {
          height: 40px;
          font-size: 18px;
        }
      }

      .logo {
        grid-row: 1;
        width: 547px;
        height: 167px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .qr-code {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .qr-code-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          width: 200px;
          height: 180px;

          .qr-code-item-image {
            width: 147px;
            height: 147px;

            img {
              width: 100%;
              height: 100%;
            }
          }
          .qr-code-item-desc {
            font-size: 16px;
            text-align: center;
          }
        }

        .qr-code-item:last-of-type {
          margin-right: -28px;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
/* 在屏幕宽度大于等于 1180px 时应用以下样式 */
@media screen and (device-width <= 1180px) {
  .footer-four {
    position: relative;
    width: 100%;
    height: auto;
    padding: 4vw;
    .footer-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 4vw;
      border-bottom: 0.2vw solid #656b7b;

      .connect {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .connect-icon {
          justify-content: center;
          width: 5.8vw;
          height: 5.8vw;
          margin-right: 4vw;
          background: #555c6c;
          border-radius: 50%;

          img {
            width: 4vw;
            height: 4vw;
          }
        }

        .connect-desc {
          height: 8.3vw;
          font-size: 3.75vw;
        }
      }

      .logo {
        grid-row: 1;
        width: 92vw;
        height: 46vw;
        padding: 4vw 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .qr-code {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 92vw;
        .qr-code-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          height: 46vw;

          .qr-code-item-image {
            width: 24vw;
            height: 24vw;

            img {
              width: 100%;
              height: 100%;
            }
          }
          .qr-code-item-desc {
            font-size: 3.3vw;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
