/**
 * 获取枚举对象中每个名称的数组
 * @param enumObject 枚举对象
 * @returns 枚举名称数组
 */
export const getEnumNames = <T>(enumObject: T): (keyof T)[] => {
  // 过滤掉那些可以被转换为数字的名称
  return Object.keys(enumObject).filter((name) =>
    Number.isNaN(+name),
  ) as (keyof T)[];
};

/**
 * 获取枚举对象中每个名称对应的值数组
 * @param enumObject 枚举对象
 * @returns 枚举值数组
 */
export const getEnumValues = <T>(enumObject: T): T[keyof T][] => {
  // 过滤掉那些不能被转换为数字的值
  return Object.values(enumObject).filter(
    (value) => !Number.isNaN(+value),
  ) as T[keyof T][];
};

interface EnumEntry<T> {
  value: T[keyof T];
  name: keyof T;
}

interface ElementUIEnumEntry<T> {
  value: T[keyof T];
  label: keyof T;
}

/**
 * 获取枚举对象中每个名称值对数组
 * @param enumObject 枚举对象
 * @returns 枚举名称值对数组
 */
export const getEnumEntries = <T>(enumObject: T): EnumEntry<T>[] => {
  return Object.entries(enumObject)
    .filter((entry) => Number.isNaN(+entry[0])) // 过滤掉那些名称可以被转换为数字的枚举项
    .map((entry) => {
      return {
        value: +entry[1],
        name: entry[0] as string,
      } as EnumEntry<T>;
    });
};

/**
 * 获取枚举对象中每个名称值对数组 (ElementUI特定使用)
 * @param enumObject 枚举对象
 * @returns 枚举名称值对数组
 */
export const getEnumEntriesForElementUI = <T>(
  enumObject: T,
): ElementUIEnumEntry<T>[] => {
  return getEnumEntries(enumObject).map((m) => {
    return {
      value: m.value,
      label: m.name,
    };
  });
};

interface DicCodeEntry<T> {
  code: T[keyof T];
  name: keyof T;
}

/**
 * 获取数据字典对象中每个名称和代码对数组
 * @param dicCodeObject 数据字典对象
 * @returns 数据字典名称和代码对数组
 */
export const getDicCodeEntries = <T>(dicCodeObject: T): DicCodeEntry<T>[] => {
  return Object.entries(dicCodeObject).map((entry) => {
    return {
      code: entry[1] as string,
      name: entry[0] as string,
    } as DicCodeEntry<T>;
  });
};
