<template>
  <div
    v-for="(item, index) in props.contentList"
    :key="index"
    class="list-with-image"
  >
    <div class="list-with-image-content">
      <div class="list-with-image-content-left">
        <div class="day">{{ new Date(item.publishTime).getDate() }}</div>
        <div class="dot">.</div>
        <div class="year-month">
          {{
            new Date(item.publishTime).getFullYear() +
            '.' +
            (new Date(item.publishTime).getMonth() + 1)
          }}
        </div>
      </div>
      <div class="list-with-image-content-right">
        <div class="list-with-image-content-right-title">
          <span class="column-name">[{{ item.columnName }}] </span>
          <a :href="item.url" target="_blank" class="title">{{ item.title }}</a>
        </div>
        <div class="list-with-image-content-right-digest">
          {{ item.digest }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// #region 引用

// #endregion

// #region props/emit
const props = defineProps({
  contentList: {
    type: Array,
    default: [],
  },
});
// #endregion

// #region 变量/常量

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

const handleClick = () => {
  alert('1222');
};

// #endregion
</script>

<style scoped>
@media screen and (device-width >= 1180px) {
  .list-with-image {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-bottom: 1px solid #ccc;
  }

  .list-with-image-content {
    display: flex;
    flex-direction: row;
    margin: 20px 0;
  }

  .list-with-image-content-left {
    display: flex;
    flex-direction: column;
    width: 120px;
    height: 85px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }

  .list-with-image-content-left .day {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    font-size: 30px;
    background-color: #194293;
  }
  .list-with-image-content-left .dot {
    display: none;
  }
  .list-with-image-content-left .year-month {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    font-size: 18px;
    background-color: #62cbdb;
  }

  .list-with-image-content-right {
    display: flex;
    flex: 1;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 20px;
  }

  .list-with-image-content-right-title {
    overflow: hidden;
    font-size: 1.25rem;
    font-weight: 500;
    color: #008bd5;
    text-overflow: ellipsis;
    transition: all 0.5s ease;
    -webkit-box-orient: vertical;
  }

  .list-with-image-content-right-digest {
    margin-top: 10px;
    overflow: hidden;
    font-size: 16px;
    line-height: 25px;
    color: #8c8c8c;
    text-overflow: ellipsis;

    /* white-space: nowrap; */
  }

  .list-with-image-content-right-title .column-name {
    color: #17b1c3;
  }
  .list-with-image-content-right-title .title {
    color: #333;
  }
  .list-with-image-content-right-title .title:hover {
    color: #0279b6;
  }

  .list-with-image-content-right-btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .list-with-image-content-right-btn span {
    display: inline-block;
    width: 110px;
    height: 30px;
    margin: 40px 10px 0 0;
    font-size: 0.875rem;
    line-height: 30px;
    color: #8c8c8c;
    text-align: center;
    background: #fff;
    box-shadow: 0 0 10px 0 #bbb9b9;
  }

  .list-with-image:hover .list-with-image-content-right-btn span {
    color: #fff;
    background: #008bd5;
  }
}
</style>

<style scoped>
@media screen and (device-width <= 1180px) {
  .list-with-image {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 2vw 3vw;
    overflow: hidden;
    border-bottom: 1px solid #ccc;
  }

  .list-with-image-content {
    display: flex;
    flex-direction: column;
  }

  .list-with-image-content-left {
    display: flex;
    flex-direction: row;
    justify-content: end;
    order: 2;
    width: 100%;
    padding: 2vw 0;
    color: #888;
    text-align: center;
  }

  .list-with-image-content-left .day {
    display: flex;
    align-items: center;
    justify-content: center;
    order: 3;
    height: 50%;
    font-size: 4vw;
  }
  .list-with-image-content-left .dot {
    display: flex;
    align-items: center;
    justify-content: center;
    order: 2;
    height: 50%;
    font-size: 4vw;
  }
  .list-with-image-content-left .year-month {
    display: flex;
    align-items: center;
    justify-content: center;
    order: 1;
    height: 50%;
    font-size: 4vw;
  }

  .list-with-image-content-right {
    display: flex;
    flex: 1;
    flex-grow: 1;
    flex-direction: column;
    order: 1;
  }

  .list-with-image-content-right-title {
    overflow: hidden;
    font-size: 4.2vw;
    font-weight: 500;
    line-height: 1.7em;
    color: #008bd5;
    text-overflow: ellipsis;
    transition: all 0.5s ease;
    -webkit-box-orient: vertical;
  }

  .list-with-image-content-right-digest {
    display: -webkit-box; /* 对于旧版WebKit浏览器（如旧版Safari和Android WebView） */
    margin-top: 1vw;
    overflow: hidden;
    font-size: 3.5vw;
    line-height: 1.7em;
    color: #8c8c8c;
    text-overflow: ellipsis; /* 用省略号代替超出部分 */
    -webkit-line-clamp: 3; /* 限制显示的行数 */
    word-wrap: break-word; /* 防止长单词或URL无法换行 */
    white-space: normal; /* 允许文本换行 */
    -webkit-box-orient: vertical; /* 设置盒子为垂直方向 */
  }

  .list-with-image-content-right-title .column-name {
    color: #17b1c3;
  }
  .list-with-image-content-right-title .title {
    color: #333;
  }
  .list-with-image-content-right-title .title:hover {
    color: #0279b6;
  }

  .list-with-image-content-right-btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .list-with-image-content-right-btn span {
    display: inline-block;
    width: 110px;
    height: 30px;
    margin: 40px 10px 0 0;
    font-size: 0.875rem;
    line-height: 30px;
    color: #8c8c8c;
    text-align: center;
    background: #fff;
    box-shadow: 0 0 10px 0 #bbb9b9;
  }

  .list-with-image:hover .list-with-image-content-right-btn span {
    color: #fff;
    background: #008bd5;
  }
}
</style>
