<template>
  <div>
    <div
      class="image-text-container"
      :style="{
        backgroundImage: `url(${configDataForm.backGroundImageFullUrl})`,
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        'background-position': 'center',
      }"
      @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
      @mouseleave="handleShowOperationBtn(false)"
    >
      <div class="title">
        <div class="title-icon">
          <img :src="iconFileFullUrl" fit="cover" />
        </div>
        <div
          class="title-text"
          :style="{
            color: configDataForm.fontColor,
          }"
        >
          {{ configDataForm.title }}
        </div>
      </div>
      <div class="content">
        <div class="content-left">
          <div class="content-left-top">
            <div class="content-image">
              <img :src="contentDataInfo.leftTopImageObj.url" fit="cover" />
            </div>
            <div
              class="content-text"
              :style="{
                'background-color': contentDataInfo.leftTopBackgroundColor,
              }"
            >
              <div class="text-title">
                {{ contentDataInfo.leftTopTitle }}
              </div>
              <div class="text-sub-title">
                {{ contentDataInfo.leftTopSubTitle }}
              </div>
            </div>
          </div>
          <div class="content-left-bottom">
            <div class="content-image">
              <img :src="contentDataInfo.leftBottomImageObj.url" fit="cover" />
            </div>
            <div
              class="content-text"
              :style="{
                'background-color': contentDataInfo.leftBottomBackgroundColor,
              }"
            >
              <div class="text-title">
                {{ contentDataInfo.leftBottomTitle }}
              </div>
              <div class="text-sub-title">
                {{ contentDataInfo.leftBottomSubTitle }}
              </div>
            </div>
          </div>
        </div>
        <div class="content-right">
          <div class="content-image">
            <img :src="contentDataInfo.rightImageObj.url" fit="cover" />
          </div>
          <div
            class="content-text"
            :style="{
              'background-color': contentDataInfo.rightBackgroundColor,
            }"
          >
            <div class="text-title">
              {{ contentDataInfo.rightTitle }}
            </div>
            <div class="text-sub-title">
              {{ contentDataInfo.rightSubTitle }}
            </div>
          </div>
        </div>
      </div>
      <client-only>
        <operation-container
          v-if="showOperationBtn && !showEditForm"
          :component-data="props.componentData"
          :show-edit-content-button="props.showEditContentButton"
          :show-edit-components-button="props.showEditComponentsButton"
          :show-delete-components-button="props.showDeleteComponentsButton"
          :show-insert-components-button="props.showInsertComponentsButton"
          :show-sort-components-button="props.showSortComponentsButton"
          @handle-show-edit-form="handleShowEditForm"
          @handle-change-components="handleChangeComponents"
          @handle-delete-components="handleDeleteComponents"
          @handle-insert-components="handleInsertComponents"
          @handle-sort-components="handleSortComponents"
        ></operation-container>
        <el-drawer
          v-model="showEditForm"
          size="60%"
          :title="props.componentData.componentName"
        >
          <image-text-edit-form
            v-if="showEditForm"
            v-model="configDataForm"
            :component-data="props.componentData"
            @handle-show-edit-form="handleShowEditForm"
            @handle-edit-components-content="handleEditComponentsContent"
          ></image-text-edit-form>
        </el-drawer>
      </client-only>
    </div>
  </div>
</template>
<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import OperationContainer from '../common/OperationContainer.vue';
import ImageTextEditForm from './ImageTextEditFormFifteen.vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

// console.log('props-configDataForm:',props);

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量

// 表单绑定对象
const configDataForm = ref({
  contentImageObj: null,
  imageName: '',
  imageFileKey: 'contentImage',
  backGroundImageObj: null,
  backgroundImageName: '',
  backgroundImageFileKey: 'backgroundImage',
  title: '示例标题',
  content: '示例内容',
  textColor: '#ffffff',
  backgroundColor: '#008bd5',
});

if (props.componentData.configData) {
  // 服务器渲染的直接调用
  configDataForm.value = JSON.parse(props.componentData.configData);
}

// 图标
const iconFileFullUrl = computed(() => {
  return configDataForm.value ? configDataForm.value.iconFullUrl : '';
});

// 图标
const contentDataInfo = computed(() => {
  const contentData = {
    backGroundImageObj: null,
    backgroundImageName: '',
    backgroundImageFileKey: 'backgroundImage',
    backgroundColor: '#008bd5',
    title: '示例标题',
    fontColor: '#ffffff',
    iconImageObj: null,
    iconName: '示例图标1.png',
    iconFileKey: 'iconImage',
    leftTopImageObj: null,
    leftTopImageName: '示例图标1.png',
    leftTopImageFileKey: 'itemFile1',
    leftTopTitle: '信息门户',
    leftTopSubTitle: '信息门户',
    leftTopBackgroundColor: '#DBC283',
    leftTopLinkUrl: 'https://www.baidu.com',
    leftBottomImageObj: null,
    leftBottomImageName: '示例图标1.png',
    leftBottomImageFileKey: 'itemFile2',
    leftBottomTitle: '信息门户',
    leftBottomSubTitle: '信息门户',
    leftBottomBackgroundColor: '#75A5CF',
    leftBottomLinkUrl: 'https://www.baidu.com',
    rightImageObj: null,
    rightImageName: '示例图标1.png',
    rightImageFileKey: 'itemFile3',
    rightTitle: '信息门户',
    rightSubTitle: '信息门户',
    rightBackgroundColor: '#4EACA2',
    rightLinkUrl: 'https://www.baidu.com',
  };

  if (configDataForm.value.items && configDataForm.value.items.length > 0) {
    // 左上
    const leftTopItem = configDataForm.value.items[0];
    contentData.leftTopImageObj = {
      name: leftTopItem.itemFileName,
      url: leftTopItem.itemFileFullUrl,
    };
    contentData.leftTopImageName = leftTopItem.itemFileName;
    contentData.leftTopTitle = leftTopItem.title;
    contentData.leftTopSubTitle = leftTopItem.subTitle;
    contentData.leftTopLinkUrl = leftTopItem.linkUrl;
    contentData.leftTopBackgroundColor =
      leftTopItem.backgroundColor || '#DBC283';
    // 左下
    const leftBottomItem = configDataForm.value.items[1];
    contentData.leftBottomImageObj = {
      name: leftBottomItem.itemFileName,
      url: leftBottomItem.itemFileFullUrl,
    };
    contentData.leftBottomImageName = leftBottomItem.itemFileName;
    contentData.leftBottomTitle = leftBottomItem.title;
    contentData.leftBottomSubTitle = leftBottomItem.subTitle;
    contentData.leftBottomLinkUrl = leftBottomItem.linkUrl;
    contentData.leftBottomBackgroundColor =
      leftBottomItem.backgroundColor || '#75A5CF';
    // 右边
    const rightItem = configDataForm.value.items[2];
    contentData.rightImageObj = {
      name: rightItem.itemFileName,
      url: rightItem.itemFileFullUrl,
    };
    contentData.rightImageName = rightItem.itemFileName;
    contentData.rightTitle = rightItem.title;
    contentData.rightSubTitle = rightItem.subTitle;
    contentData.rightLinkUrl = rightItem.linkUrl;
    contentData.rightBackgroundColor = rightItem.backgroundColor || '#4EACA2';
  }

  return contentData;
});

// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法
/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};
/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};
/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // console.log('configDataForm--1:', configDataForm);
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};

// #endregion
</script>
<style scoped lang="scss">
@media screen and (device-width >= 1180px) {
  .image-text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: center;
    width: 100%;
    padding: 0 100px;

    .title {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      height: 60px;
      padding-bottom: 20px;
      border-bottom: 1px solid #cfd0c5;
      .title-icon {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }

      .title-text {
        flex-grow: 1;
        height: 40px;
        margin-bottom: 20px;
        font-size: 36px;
        line-height: 40px;
      }
    }
    .content {
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .content-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 680px;
        padding-top: 20px;
        padding-right: 20px;

        .content-left-top {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .content-left-bottom {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          padding-top: 20px;
        }
        .content-image {
          width: 100%;
          height: 240px;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .content-text {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 100%;
          height: 100px;
          padding: 20px 40px;
          color: #fff;

          .text-title {
            font-size: 24px;
            font-weight: bold;
          }
          .text-sub-title {
            display: -webkit-box;
            overflow: hidden; /* 隐藏超出的内容 */
            font-size: 20px;
            text-overflow: ellipsis; /* 显示省略符号来表示被截断 */
            -webkit-line-clamp: 1; /* 限制在一个块元素显示的文本的行数 */
            -webkit-box-orient: vertical;
          }
        }
      }
      .content-right {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-evenly;
        padding-top: 20px;
        color: #fff;

        .content-image {
          width: 100%;
          height: 520px;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .content-text {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 100%;
          height: 180px;
          padding: 20px 40px;
          color: #fff;

          .text-title {
            font-size: 24px;
            font-weight: bold;
          }
          .text-sub-title {
            display: -webkit-box;
            overflow: hidden; /* 隐藏超出的内容 */
            font-size: 20px;
            line-height: 40px;
            text-overflow: ellipsis; /* 显示省略符号来表示被截断 */
            -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media screen and (device-width <= 1180px) {
  .image-text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 4vw;
    background-color: #fff !important;
    background-image: none !important;

    .title {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      height: 12.5vw;
      padding-bottom: 4vw;
      .title-icon {
        display: flex;
        align-content: center;
        align-items: center;
        font-size: 7.5vw;
        img {
          width: 7.5vw;
          height: 7.5vw;
          margin-right: 2vw;
          object-fit: cover;
        }
      }

      .title-text {
        flex-grow: 1;
        height: 7.5vw;
        font-size: 7.5vw;
        line-height: 7.5vw;
      }
    }
    .content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 92vw;

      .content-left {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 92vw;

        .content-left-top {
          display: flex;
          flex-direction: column;
          width: 45vw;
        }

        .content-left-bottom {
          display: flex;
          flex-direction: column;
          width: 45vw;
        }
        .content-image {
          width: 45vw;
          height: 35vw;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .content-text {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 45vw;
          height: 20vw;
          padding: 4vw;
          color: #fff;

          .text-title {
            font-size: 5vw;
            font-weight: bold;
          }
          .text-sub-title {
            display: -webkit-box;
            overflow: hidden; /* 隐藏超出的内容 */
            font-size: 4vw;
            text-overflow: ellipsis; /* 显示省略符号来表示被截断 */
            -webkit-line-clamp: 1; /* 限制在一个块元素显示的文本的行数 */
            -webkit-box-orient: vertical;
          }
        }
      }
      .content-right {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-evenly;
        padding-top: 4vw;
        color: #fff;

        .content-image {
          width: 92vw;
          height: 54vw;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .content-text {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 92vw;
          height: 37.5vw;
          padding: 4vw;
          color: #fff;

          .text-title {
            font-size: 5vw;
            font-weight: bold;
          }
          .text-sub-title {
            display: -webkit-box;
            overflow: hidden; /* 隐藏超出的内容 */
            font-size: 4vw;
            line-height: 8vw;
            text-overflow: ellipsis; /* 显示省略符号来表示被截断 */
            -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
</style>
