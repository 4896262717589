<template>
  <div class="column-content-form-container">
    <el-form
      ref="ruleFormRef"
      :model="configDataForm"
      :rules="rules"
      label-width="150px"
      class="account-form"
      :size="formSize"
    >
      <div class="card-frame other-card-frame">
        <div class="card-frame-top">基础信息</div>
        <div class="card-frame-container">
          <el-form-item label="背景图片：" prop="backGroundImageObj">
            <zc-upload
              v-model="configDataForm.backGroundImageObj"
              :limit-single-size="1048576"
              :image-thumbnail-width="240"
              type="singleImage"
              :show-no-image="true"
              :small-text="true"
              :enable-popup="false"
              tip="说明：上传的图片大小不得超过1M，建议尺寸560*430px"
              @change="handleBackgroundImageChange"
            >
              <template #singleImageError>
                <div class="el-image__error image-error-show">图片丢失</div>
              </template>
            </zc-upload>
          </el-form-item>
          <el-form-item label="背景色：" prop="backGroundColor">
            <el-color-picker v-model="configDataForm.backGroundColor" />
          </el-form-item>
        </div>
      </div>
      <div class="card-frame other-card-frame">
        <div class="card-frame-top">左侧栏目信息</div>
        <div class="card-frame-container">
          <el-form-item
            label="标题："
            prop="leftItem.title"
            :rules="rules.title"
          >
            <el-input v-model="configDataForm.leftItem.title" />
          </el-form-item>
          <el-form-item
            label="字体颜色："
            prop="leftItem.fontColor"
            :rules="rules.textColor"
          >
            <el-color-picker v-model="configDataForm.leftItem.fontColor" />
          </el-form-item>
          <el-form-item
            label="跳转链接："
            prop="leftItem.linkUrl"
            :rules="rules.linkUrl"
          >
            <el-input v-model="configDataForm.leftItem.linkUrl" />
          </el-form-item>
          <el-form-item
            label="图标："
            prop="leftItem.leftIconImageFileObj"
            :rules="rules.backGroundImageObj"
          >
            <zc-upload
              v-model="configDataForm.leftItem.leftIconImageFileObj"
              :limit-single-size="1048576"
              type="singleImage"
              :show-no-image="true"
              :small-text="true"
              :enable-popup="false"
              tip="说明：上传的图片大小不得超过100KB，建议尺寸40*40px"
              @change="handLeftIconImageChange"
            >
              <template #singleImageError>
                <div class="el-image__error image-error-show">图片丢失</div>
              </template>
            </zc-upload>
          </el-form-item>
          <el-form-item
            label="可选栏目："
            :prop="`leftItem.dataSourceId`"
            :rules="rules.dataSourceId"
          >
            <zc-select
              v-model="configDataForm.leftItem.dataSourceId"
              :data="columnList"
            ></zc-select>
          </el-form-item>
          <el-form-item
            label="展现方式："
            :prop="`leftItem.showStyle`"
            :rules="rules.showStyle"
          >
            <zc-select
              v-model="configDataForm.leftItem.showStyle"
              :data="showStyleList"
            ></zc-select>
          </el-form-item>
        </div>
      </div>
      <div class="card-frame other-card-frame">
        <div class="card-frame-top">右侧栏目信息</div>
        <div class="card-frame-container">
          <el-form-item
            label="标题："
            prop="rightItem.title"
            :rules="rules.title"
          >
            <el-input v-model="configDataForm.rightItem.title" />
          </el-form-item>
          <el-form-item
            label="字体颜色："
            prop="rightItem.fontColor"
            :rules="rules.textColor"
          >
            <el-color-picker v-model="configDataForm.rightItem.fontColor" />
          </el-form-item>
          <el-form-item
            label="跳转链接："
            prop="rightItem.linkUrl"
            :rules="rules.linkUrl"
          >
            <el-input v-model="configDataForm.rightItem.linkUrl" />
          </el-form-item>
          <el-form-item
            label="图标："
            prop="rightItem.rightIconImageFileObj"
            :rules="rules.backGroundImageObj"
          >
            <zc-upload
              v-model="configDataForm.rightItem.rightIconImageFileObj"
              :limit-single-size="1048576"
              type="singleImage"
              :show-no-image="true"
              :small-text="true"
              :enable-popup="false"
              tip="说明：上传的图片大小不得超过100KB，建议尺寸40*40px"
              @change="handRightIconImageChange"
            >
              <template #singleImageError>
                <div class="el-image__error image-error-show">图片丢失</div>
              </template>
            </zc-upload>
          </el-form-item>
          <el-form-item
            label="可选栏目："
            :prop="`rightItem.dataSourceId`"
            :rules="rules.dataSourceId"
          >
            <zc-select
              v-model="configDataForm.rightItem.dataSourceId"
              :data="columnList"
            ></zc-select>
          </el-form-item>
          <el-form-item
            label="展现方式："
            :prop="`rightItem.showStyle`"
            :rules="rules.showStyle"
          >
            <zc-select
              v-model="configDataForm.rightItem.showStyle"
              :data="showStyleList"
            ></zc-select>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="column-content-btn-container">
      <el-button @click="handleCancel()">取消</el-button>
      <el-button type="primary" @click="handleSave(ruleFormRef)"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script setup>
// #region 引用
import {
  verifyCharacterLength,
  verifyLinkUrl,
} from '@/utils/common/form-verify-util';
import { ColumnType, ShowStyle } from '@/configs/enums/website';
import { getEnumEntriesForElementUI } from '@/utils/common/enum-util';

// #endregion

// #region props/emit
/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'update:modelValue',
  'handle-edit-components-content',
]);
/**
 * 属性
 */
const props = defineProps({
  componentData: {
    type: Object,
    default() {},
  },
});
// #endregion

// #region 变量/常量

const showStyleList = computed(() => {
  return getEnumEntriesForElementUI(ShowStyle);
});

// 栏目数据列表
const columnList = ref([{ value: '', label: '请选择栏目' }]);
// 表单绑定对象
const configDataForm = ref({
  backGroundImageName: '展示图片.jpg',
  backGroundImageFileKey: 'backgroundImage',
  backGroundColor: '#fffffff',
  leftItem: {
    title: '热点资讯',
    iconName: '示例图标1.png',
    iconFileKey: 'iconImage1',
    fontColor: '#1e93ea',
    linkUrl: '/article-list/132',
    dataSourceId: null,
    dataSourceCount: 10,
    showStyle: 1,
  },
  rightItem: {
    title: '新闻动态',
    iconName: '示例图标2.png',
    iconFileKey: 'iconImage2',
    fontColor: '#1e93ea',
    linkUrl: '/article-list/132',
    dataSourceId: null,
    dataSourceCount: 10,
    showStyle: 2,
  },
});

// 表单size
const formSize = ref('default');
const ruleFormRef = ref();

// 表单规则
const rules = ref({
  backGroundImageObj: [{ required: true, message: '必填项', trigger: 'blur' }],
  dataSourceId: [{ required: true, message: '必填项', trigger: 'blur' }],
  showStyle: [{ required: true, message: '必填项', trigger: 'blur' }],
  backgroundColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  textColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  title: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 20, trigger: 'blur' },
  ],
  linkUrl: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyLinkUrl, clen: 50, trigger: 'blur' },
  ],
});

/**
 * 可选栏目列表
 */
// columnList.value = props.componentData.contentList;
columnList.value = props.componentData.contentList
  .filter((item) => item.item.columnType === ColumnType.文章列表栏目)
  .map((item) => {
    return item;
  });

if (props.componentData.configData) {
  // 待数据更新完成后才执行
  nextTick(() => {
    configDataForm.value = JSON.parse(props.componentData.configData);
    // 左侧图标
    configDataForm.value.leftItem.leftIconImageFileObj = {
      name: configDataForm.value.leftItem.iconName,
      url: configDataForm.value.leftItem.iconFullUrl,
    };
    // 右侧图标
    configDataForm.value.rightItem.rightIconImageFileObj = {
      name: configDataForm.value.rightItem.iconName,
      url: configDataForm.value.rightItem.iconFullUrl,
    };

    configDataForm.value.backGroundImageObj = {
      name: configDataForm.value.backGroundImageName,
      url: configDataForm.value.backGroundImageFullUrl,
    };
  });
}
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 背景图片改变后调用
 */
const handleBackgroundImageChange = (file) => {
  ruleFormRef.value.validateField('backGroundImageObj', (valid) => {});
};

/**
 * 处理取消
 */
const handleCancel = () => {
  emits('handle-show-edit-form', false);
};
/**
 * 图片改变后调用
 */
const handLeftIconImageChange = (index) => {
  // console.log('index:', index);
  ruleFormRef.value.validateField(
    `leftItem.leftIconImageFileObj`,
    (valid) => {},
  );
};

/**
 * 图片改变后调用
 */
const handRightIconImageChange = (index) => {
  // console.log('index:', index);
  ruleFormRef.value.validateField(
    `rightItem.rightIconImageFileObj`,
    (valid) => {},
  );
};
/**
 * 处理保存
 */
const handleSave = (form) => {
  if (!form) return;

  form.validate((valid, fields) => {
    if (valid) {
      // 待数据更新完成后才执行
      nextTick(() => {
        emits('handle-edit-components-content', false);
        emits('update:modelValue', configDataForm.value);
      });
      // TODO 待完成保存逻辑
    } else {
      console.log('fields:', fields);
    }
  });
};
// #endregion
</script>

<style scoped>
.column-content-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin: auto;
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

:deep(.el-form) {
  width: 100%;
  padding: 20px;
}

:deep(.el-color-picker__trigger) {
  width: 100px;
}

.column-content-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: var(--el-color-white);
}

.column-content-btn-container button {
  margin: 8px;
}
</style>
