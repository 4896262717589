<template>
  <div>
    <div
      class="friend-link-container"
      :style="{
        backgroundImage: `url(${configDataForm.backGroundImageFullUrl})`,
        background: configDataForm.backGroundColor,
        color: configDataForm.fontColor,
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        'background-position': 'center',
      }"
      @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
      @mouseleave="handleShowOperationBtn(false)"
    >
      <div class="link-container">
        <div class="title">
          <div class="title-icon">
            <img :src="iconFileFullUrl" fit="cover" />
          </div>
          <div
            class="title-text"
            :style="{
              color: configDataForm.fontColor,
            }"
          >
            {{ configDataForm.title }}
          </div>
        </div>
        <div class="content">
          <a
            v-for="item in configDataForm.items"
            :key="item.siteId"
            :href="item.siteUrl"
            :style="{
              color: configDataForm.fontColor,
            }"
            class="link"
            target="_blank"
            >{{ item.siteName }}</a
          >
        </div>
      </div>

      <client-only>
        <operation-container
          v-if="showOperationBtn && !showEditForm"
          :component-data="props.componentData"
          :show-edit-content-button="props.showEditContentButton"
          :show-edit-components-button="props.showEditComponentsButton"
          :show-delete-components-button="props.showDeleteComponentsButton"
          :show-insert-components-button="props.showInsertComponentsButton"
          :show-sort-components-button="props.showSortComponentsButton"
          @handle-show-edit-form="handleShowEditForm"
          @handle-change-components="handleChangeComponents"
          @handle-delete-components="handleDeleteComponents"
          @handle-insert-components="handleInsertComponents"
          @handle-sort-components="handleSortComponents"
        ></operation-container>
      </client-only>
    </div>
    <client-only>
      <el-drawer
        v-model="showEditForm"
        size="60%"
        :title="props.componentData.componentName"
      >
        <friend-link-edit-form
          v-if="showEditForm"
          v-model="configDataForm"
          :component-data="props.componentData"
          @handle-edit-components-content="handleEditComponentsContent"
          @handle-show-edit-form="handleShowEditForm"
        ></friend-link-edit-form>
      </el-drawer>
    </client-only>
  </div>
</template>
<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import OperationContainer from '../common/OperationContainer.vue';
import FriendLinkEditForm from './FriendLinkEditFormFour.vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});
// console.log('props-configDataForm-FriendLinkThree:', props);

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量
const configDataForm = ref();

// 表单绑定对象

if (props.componentData.configData) {
  configDataForm.value = JSON.parse(props.componentData.configData);
  configDataForm.value.items.sort((a, b) => {
    return a.sortNum - b.sortNum;
  });
}

// 图标
const iconFileFullUrl = computed(() => {
  return configDataForm.value ? configDataForm.value.iconFullUrl : '';
});
// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法
/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};
/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};
/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // console.log('configDataForm-FriendLinkThree--1:', configDataForm);
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};

// #endregion
</script>
<style scoped lang="scss">
@media screen and (device-width >= 1180px) {
  .friend-link-container {
    position: relative;
    height: 80px;
    padding: 20px 100px 0;
    .link-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      border-bottom: 1px solid #656b7b;
      .title {
        display: flex;
        flex-direction: row;
        width: 180px;
        height: 100%;
        font-size: 24px;
        font-weight: 700;
        .title-icon {
          width: 40px;
          height: 40px;
          margin-right: 20px;
        }

        .title-text {
          position: relative;
          flex-grow: 1;
        }
        .title-text::after {
          position: absolute;
          right: 0;
          height: 100%;
          font-size: 24px;
          content: ':';
        }
      }
      .content {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        font-size: 22px;
        .link {
          position: relative;
          margin: 0 40px;
        }
        .link:first-of-type {
          margin-left: 40px;
        }

        .link:not(:first-of-type)::before {
          position: absolute;
          top: -2px;
          left: -40px;
          height: 100%;
          font-size: 22px;
          content: '|';
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
@media screen and (device-width <= 1180px) {
  .friend-link-container {
    position: relative;
    width: 100%;
    height: auto;
    padding: 4vw;
    .link-container {
      display: flex;
      flex-direction: column;
      width: 92vw;
      border-bottom: 1px solid #656b7b;
      .title {
        display: flex;
        flex-direction: row;
        width: 180px;
        height: 100%;
        padding-left: 2vw;
        font-size: 5vw;
        font-weight: 700;
        .title-icon {
          width: 7.5vw;
          height: 7.5vw;
          margin-right: 2vw;
        }

        .title-text {
          position: relative;
          flex-grow: 1;
        }
        .title-text::after {
          position: absolute;
          right: 8vw;
          font-size: 5vw;
          content: ':';
        }
      }
      .content {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        font-size: 4.5vw;
        .link {
          position: relative;
          margin: 2vw 4vw;
        }
        .link:first-of-type {
          margin-left: 4vw;
        }
      }
    }
  }
}
</style>
