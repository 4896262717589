<template>
  <div class="main-wrap">
    <div v-if="isShowLeftNav" class="main-left-wrap">
      <LeftNav></LeftNav>
    </div>
    <div class="main-content-wrap">
      <location></location>
      <div class="article-list-container">
        <div v-if="list != null && list.length > 0">
          <component
            :is="dom[bundleComponent(styeCode)]"
            v-if="list != null && list.length > 0"
            :content-list="list"
          >
          </component>
        </div>
        <el-empty v-else description="暂无数据"></el-empty>
        <zc-web-pager-ssr
          v-if="total != null && total > pageSize"
          class="pager-container"
          :page-size="pageSize"
          :total-row-count="total"
        ></zc-web-pager-ssr>
      </div>
    </div>
  </div>
</template>

<script setup async>
// #region 引用
import { ColumnType, OwPageType } from '@/configs/enums/website';
import { getColumnPageUrl, getMaterialPageUrl } from '@/utils/business/website';
import {
  getMaterialArticlesList,
  getMaterialAttachmentsList,
  getMaterialImagesList,
  getMaterialVideosList,
} from '@/apis/modules/auth/website/website-ssr';
import useSSRInitialStateStore from '@/stores/ssr-state';
import { useSSRContext } from 'vue';
import articleListOne from '../components/article-list/ArticleListOne.vue';
import articleListTwo from '../components/article-list/ArticleListTwo.vue';
import articleListThree from '../components/article-list/ArticleListThree.vue';
import articleListFour from '../components/article-list/ArticleListFour.vue';
import articleListFive from '../components/article-list/ArticleListFive.vue';
import imageListOne from '../components/image-list/ImageListOne.vue';
import imageListTwo from '../components/image-list/ImageListTwo.vue';
import location from '../components/Location.vue';
import LeftNav from '../components/LeftNav.vue';

// #endregion

// #region props/emit

// #endregion

// #region 变量/常量

const configInfo = ref({}); // 配置信息
const configPageList = ref([]); // 配置页面列表
const isShowLeftNav = ref(false);

const route = useRoute();
/**
 * 数据列表
 */
const list = ref([]);
/**
 * 总的条数
 */
const total = ref(0);
/**
 * 当前操作页码
 */
const pageIndex = ref(1);
if (route.query && route.query.page) {
  pageIndex.value = route.query.page;
}
/**
 * 每页显示条数
 */
const pageSize = ref(10);

// 栏目信息
const columnInfo = ref();
const previewId = ref();

const styeCode = ref('ARTICLE_LIST_1');

/**
 * 动态渲染组件
 */
const dom = shallowReactive({
  articleListTwo,
  articleListOne,
  articleListThree,
  articleListFour,
  articleListFive,
  imageListOne,
  imageListTwo,
});

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 绑定组件
 * @param {*} stye 页面样式
 */
const bundleComponent = (stye) => {
  let component = '';
  switch (stye) {
    case 'ARTICLE_LIST_1':
      component = 'articleListOne';
      break;
    case 'ARTICLE_LIST_2':
      component = 'articleListTwo';
      break;
    case 'ARTICLE_LIST_3':
      component = 'articleListThree';
      break;
    case 'ARTICLE_LIST_4':
      component = 'articleListFour';
      break;
    case 'ARTICLE_LIST_5':
      component = 'articleListFive';
      break;
    case 'IMAGE_LIST_1':
      component = 'imageListOne';
      // TODO 待完善
      break;
    case 'IMAGE_LIST_2':
      component = 'imageListTwo';
      break;
    case 'ATTACHMENT_LIST_1':
      // TODO 待完善
      break;
    case 'ATTACHMENT_LIST_2':
      // TODO 待完善
      break;
    case 'VIDEO_LIST_1':
      // TODO 待完善
      break;
    case 'AUDIO_LIST_1':
      // TODO 待完善
      break;
    default:
      component = 'articleListOne';
      break;
  }
  return component;
};

onServerPrefetch(async () => {
  const ssrContext = useSSRContext();
  configInfo.value = ssrContext.initialState.data.current.configInfo;
  configPageList.value = ssrContext.initialState.data.current.configPageList;
  columnInfo.value = ssrContext.initialState.data.current.columnInfo;
  previewId.value = ssrContext.initialState.data.current.previewId;
  isShowLeftNav.value =
    ssrContext.initialState.data.current.topNavComponent.isShowLeftNav;
  if (columnInfo.value) {
    useHead({
      title: `${columnInfo.value.columnName} - ${configInfo.value.homeTitle}`,
      meta: [
        // 设置栏目名称
        {
          name: 'keywords',
          content: columnInfo.value.columnName,
        },
        // 设置栏目摘要
        {
          name: 'description',
          content: columnInfo.value.digest,
        },
      ],
    });
  }

  /**
   * 处理渲染哪一个页面
   */
  let apiUrl = '';
  let pageType = 0;
  switch (columnInfo.value.columnType) {
    case ColumnType.文章列表栏目:
      pageType = OwPageType.文章列表页;
      apiUrl = getMaterialArticlesList;
      break;
    case ColumnType.图片列表栏目:
      pageType = OwPageType.图片列表页;
      apiUrl = getMaterialImagesList;
      break;
    case ColumnType.附件列表栏目:
      pageType = OwPageType.附件列表页;
      apiUrl = getMaterialAttachmentsList;
      break;
    case ColumnType.视频列表栏目:
      pageType = OwPageType.视频列表页;
      apiUrl = getMaterialVideosList;
      break;
    default:
      break;
  }
  const currentConfigPageInfo = configPageList.value.find(
    (x) => x.pageType === pageType,
  );
  // 先从指定栏目获取呈现样式，如果没有，则获取通用呈现样式
  const styleCodeForThisColumn = currentConfigPageInfo.columnStyleList.find(
    (x) => x.columnId === columnInfo.value.id,
  )?.owPageStyleCode;
  styeCode.value =
    styleCodeForThisColumn ?? currentConfigPageInfo?.owPageStyleCode;
  // 不同的样式可能会设置不同的分页大小
  switch (styeCode.value) {
    case 'ARTICLE_LIST_3':
      pageSize.value = 9;
      break;
    case 'ARTICLE_LIST_4':
      pageSize.value = 12;
      break;
    case 'IMAGE_LIST_2':
      // 对于一些特殊栏目，不需要显示左侧导航
      isShowLeftNav.value = false;
      break;
    default:
      break;
  }
  ssrContext.initialState.data.current.pageSize = pageSize.value;
  ssrContext.initialState.data.current.styeCode = styeCode.value;
  ssrContext.initialState.data.current.isShowLeftNav = isShowLeftNav.value;

  /**
   * 获取栏目内容列表
   */
  const res = await apiUrl.use({
    columnId: route.params.columnId,
    pageIndex: pageIndex.value,
    pageSize: pageSize.value,
  });

  if (res.code === '00000' && res.data) {
    total.value = res.data.total;
    res.data.list.forEach((element) => {
      const newElement = {
        ...element,
        url: getMaterialPageUrl(
          route.params.columnId,
          element.materialId,
          previewId.value,
        ),
        coverImageUrl: element.coverImageUrl
          ? element.coverImageUrl.replaceAll(
              '/upload/',
              `${import.meta.env.VITE_API_URL}/upload/`,
            )
          : element.coverImageUrl,
        imageUrl: element.imageUrl
          ? element.imageUrl.replaceAll(
              '/upload/',
              `${import.meta.env.VITE_API_URL}/upload/`,
            )
          : element.imageUrl,
      };
      list.value.push(newElement);
    });
  }

  ssrContext.initialState.data.current.listData = list.value;
  ssrContext.initialState.data.current.total = total.value;

  // #endregion
});

if (!import.meta.env.SSR) {
  const ssrInitialState = useSSRInitialStateStore();
  list.value = ssrInitialState.data.current.listData;
  total.value = ssrInitialState.data.current.total;
  styeCode.value = ssrInitialState.data.current.styeCode;
  pageSize.value = ssrInitialState.data.current.pageSize;
  isShowLeftNav.value = ssrInitialState.data.current.isShowLeftNav;
}
</script>

<style scoped>
@media screen and (device-width >= 1180px) {
  .main-wrap {
    display: flex;
    justify-content: center;
    max-width: 1400px;
    padding-top: 15px;
    margin: 0 auto;
  }
  .main-left-wrap {
    flex: 0 0 auto;
    width: 200px;
    margin-right: 20px;
  }
  .main-content-wrap {
    flex: 1;
  }

  .article-list-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto 15px;

    /* height: 100%; */
  }
  :deep(.article-list-container .el-empty .el-empty__description p) {
    font-size: 14px;
  }

  .pager-container {
    align-self: flex-end;
    margin-top: 15px;
  }
}
</style>
<style scoped>
@media screen and (device-width <= 1180px) {
  .main-wrap {
    display: block;
    overflow: hidden;
  }
  .main-left-wrap {
    display: none;
  }
  .article-list-container {
    display: flex;
    flex-direction: column;
    margin: 0 3vw 3vw;
  }

  :deep(.pager-container .table-pager) {
    display: flex;
    justify-content: center;
    margin: 1vw 0;
  }
  :deep(.pager-container .num-item) {
    display: none;
  }
}
</style>
