<template>
  <div class="left-nav">
    <span
      class="column-title"
      :style="{
        backgroundColor: navPageLeftArea.titleBgColor,
        color: navPageLeftArea.titleTextColor,
      }"
      >{{ curSiblingColumnInfo.label }}</span
    >
    <a
      v-for="(item, index) in curSiblingColumnInfo.children"
      :key="index"
      :class="'column-item' + (item.id == columnId ? ' current' : '')"
      :style="
        item.id == columnId
          ? {
              backgroundColor: navPageLeftArea.activeBgColor,
              color: navPageLeftArea.activeTextColor,
            }
          : null
      "
      :href="item.data.hrefURL ? item.data.hrefURL : 'javascript:void(0);'"
    >
      <svg
        class="icon"
        width="8"
        height="8"
        viewBox="0 0 1024 1024"
        :fill="item.id == columnId ? navPageLeftArea.activeTextColor : null"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M950.857 512L73.143 1023.999V0z"></path>
      </svg>
      <span>{{ item.label }}</span>
    </a>
  </div>
</template>

<script setup>
// #region 引用
import useSSRInitialStateStore from '@/stores/ssr-state';
import { useSSRContext } from 'vue';
// #endregion

// #region props/emit

// #endregion

// #region 变量/常量
const ssrInitialState = useSSRInitialStateStore();
const curSiblingColumnInfo = ref(null); // 当前同级栏目列表
const columnId = ref(null); // 当前栏目id
const navPageLeftArea = ref(null); // 左侧导航区域数据

// #endregion

// #region 业务方法

onServerPrefetch(async () => {
  const ssrContext = useSSRContext();
  curSiblingColumnInfo.value =
    ssrContext.initialState.data.current.curSiblingColumnInfo;
  columnId.value = ssrContext.initialState.data.current.columnId;
  navPageLeftArea.value =
    ssrContext.initialState.data.current.topNavComponent.navPageLeftArea;
});

if (!import.meta.env.SSR) {
  // 客户端从initialState读取数据
  curSiblingColumnInfo.value =
    ssrInitialState.data.current.curSiblingColumnInfo;
  columnId.value = ssrInitialState.data.current.columnId;
  navPageLeftArea.value =
    ssrInitialState.data.current.topNavComponent.navPageLeftArea;
}

// #endregion
</script>

<style scoped>
@media screen and (device-width >= 1180px) {
  .left-nav {
    width: 100%;
  }
  .left-nav .column-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 16px;
    color: #fff;
    background-color: blue;
  }
  .left-nav .column-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-size: 16px;
    color: #333;
    background-color: fff;
    border-bottom: 1px solid #ddd;
  }
  .left-nav .column-item .icon {
    align-items: flex-start;
    justify-content: flex-start;
    width: 8px;
    height: 8px;
    margin: auto auto auto 10px;
  }
  .left-nav .column-item span {
    margin: 0 auto 0 -18px;
  }
  .left-nav .column-item.current {
    color: blue;
  }
}
</style>

<style scoped>
@media screen and (device-width <= 1180px) {
  .left-nav {
    display: none;
  }
}
</style>
