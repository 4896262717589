<template>
  <div class="main-wrap">
    <div v-if="isShowLeftNav" class="main-left-wrap">
      <LeftNav></LeftNav>
    </div>
    <div class="main-content-wrap">
      <location></location>
      <customerTemplate
        v-if="templatePage && pageTypeId"
        :key="new Date()"
        :from-type="3"
        :show-edit-content-button="false"
        :show-edit-components-button="false"
        :show-delete-components-button="false"
        :show-insert-components-button="false"
        :show-sort-components-button="false"
        :template-page="templatePage"
      >
      </customerTemplate>
      <div v-else class="system-maintenance">
        <el-empty description="您预览的栏目未维护任何内容，请先去维护。" />
      </div>
    </div>
  </div>
</template>

<script setup>
// #region 引用
import useSSRInitialStateStore from '@/stores/ssr-state';
import { useSSRContext } from 'vue';
import customerTemplate from '../components/Template.vue';
import location from '../components/Location.vue';
import LeftNav from '../components/LeftNav.vue';

// #endregion
// #region props/emit

// #endregion

// #region 变量/常量
const route = useRoute();
const configInfo = ref({}); // 配置信息
const columnInfo = ref({}); // 当前栏目信息
const configPageList = ref([]); // 配置页面列表
const isShowLeftNav = ref(false);
const pageTypeId = ref(Number(route.params.pageTypeId));

const templatePage = ref([]); // 当前页面信息
// 当前路由栏目id
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 获取栏目信息
 */

onServerPrefetch(async () => {
  const ssrContext = useSSRContext();
  configInfo.value = ssrContext.initialState.data.current.configInfo;
  configPageList.value = ssrContext.initialState.data.current.configPageList;
  columnInfo.value = ssrContext.initialState.data.current.columnInfo;
  isShowLeftNav.value =
    ssrContext.initialState.data.current.topNavComponent.isShowLeftNav;
  templatePage.value = configPageList.value.find(
    (item) => item.id === pageTypeId.value,
  );
  if (columnInfo.value) {
    useHead({
      title: `${columnInfo.value.columnName} - ${configInfo.value.homeTitle}`,
      meta: [
        // 设置栏目名称
        {
          name: 'keywords',
          content: columnInfo.value.columnName,
        },
        // 设置栏目摘要
        {
          name: 'description',
          content: columnInfo.value.digest,
        },
      ],
    });
  }
  ssrContext.initialState.data.current.templatePage = templatePage.value;
});

if (!import.meta.env.SSR) {
  const ssrInitialState = useSSRInitialStateStore();
  templatePage.value = ssrInitialState.data.current.templatePage;
  isShowLeftNav.value =
    ssrInitialState.data.current.topNavComponent.isShowLeftNav;
}

// #endregion
</script>

<style lang="scss" scoped>
@media screen and (device-width > 1180px) {
  .main-wrap {
    display: flex;
    justify-content: center;
    max-width: 1400px;
    padding-top: 15px;
    margin: 0 auto;
  }
  .main-left-wrap {
    flex: 0 0 auto;
    width: 200px;
    margin-right: 20px;
  }
  .main-content-wrap {
    flex: 1;
  }

  .system-maintenance {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

<style scoped>
@media screen and (device-width <= 1180px) {
  .main-wrap {
    display: flex;
    justify-content: center;
  }
  .main-left-wrap {
    display: none;
  }
  .main-content-wrap {
    flex: 1;
  }
}
</style>
