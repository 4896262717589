<template>
  <div class="main-wrap">
    <div class="main-content-wrap">
      <location :content-nav-list="contentNavList"></location>
      <div class="search-main-container">
        <div class="search-area">
          <el-input
            v-model="searchContent"
            class="search-input"
            placeholder="请输入关键词"
            clearable
            @keyup.enter="search"
          ></el-input>
          <el-button type="primary" class="search-btn" @click="search"
            >搜 索</el-button
          >
        </div>
        <div v-if="list != null && list.length > 0" class="article-list-item">
          <component :is="dom[bundleComponent(styeCode)]" :content-list="list">
          </component>
        </div>
        <div v-else><el-empty :description="msg" /></div>
        <zc-web-pager-ssr
          v-if="total != null && total > pageSize"
          class="pager-container"
          :page-size="pageSize"
          :total-row-count="total"
        ></zc-web-pager-ssr>
      </div>
    </div>
  </div>
</template>

<script setup async>
// #region 引用
import { ColumnType, OwPageType } from '@/configs/enums/website';
import { getColumnPageUrl, getMaterialPageUrl } from '@/utils/business/website';
import { getColumnMaterialArticlesListForSearch } from '@/apis/modules/auth/website/website-ssr';
import useSSRInitialStateStore from '@/stores/ssr-state';
import { useSSRContext } from 'vue';
import searchListOne from '../components/article-list/SearchListOne.vue';
import location from '../components/Location.vue';

// #endregion

// #region props/emit

// #endregion

// #region 变量/常量

const configInfo = ref({}); // 配置信息
const configPageList = ref([]); // 配置页面列表

const route = useRoute();

/**
 * 数据列表
 */
const list = ref([]);
/**
 * 总的条数
 */
const total = ref(0);
/**
 * 当前操作页码
 */
const pageIndex = ref(1);
if (route.query && route.query.page) {
  pageIndex.value = route.query.page;
}
/**
 * 每页显示条数
 */
const pageSize = ref(10);

// 栏目信息
const previewId = ref();

// 导航地址信息
const contentNavList = ref([
  {
    title: '搜索',
    url: '',
  },
]);

const searchContent = ref(route.query.s);

const styeCode = ref(null);
const msg = ref('请输入关键词搜索');

/**
 * 动态渲染组件
 */
const dom = shallowReactive({
  searchListOne,
});

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 搜索
 */
const search = () => {
  if (!searchContent.value) {
    return;
  }
  window.location.href = `${route.path}?s=${searchContent.value}`;
};

/**
 * 绑定组件
 * @param {*} stye 页面样式
 */
const bundleComponent = (stye) => {
  let component = '';
  switch (stye) {
    case 'SEARCH_1':
      component = 'searchListOne';
      break;
    default:
      component = 'searchListOne';
      break;
  }
  return component;
};

onServerPrefetch(async () => {
  const ssrContext = useSSRContext();
  configInfo.value = ssrContext.initialState.data.current.configInfo;
  configPageList.value = ssrContext.initialState.data.current.configPageList;
  previewId.value = ssrContext.initialState.data.current.previewId;

  useHead({
    title: `搜索 - ${configInfo.value.homeTitle}`,
    meta: [
      // 设置栏目名称
      {
        name: 'keywords',
        content: configInfo.value.homeKeyword,
      },
      // 设置栏目摘要
      {
        name: 'description',
        content: configInfo.value.homeDesc,
      },
    ],
  });

  if (searchContent.value) {
    /**
     * 处理渲染哪一个页面
     */
    styeCode.value = configPageList.value.find(
      (f) => f.pageType === OwPageType.搜索页,
    ).owPageStyleCode;

    /**
     * 获取栏目内容列表
     */
    const res = await getColumnMaterialArticlesListForSearch.use({
      searchContent: searchContent.value,
      pageIndex: pageIndex.value,
      pageSize: pageSize.value,
    });

    if (res.code === '00000' && res.data) {
      total.value = res.data.total;
      if (total.value > 0) {
        res.data.list.forEach((element) => {
          const newElement = {
            ...element,
            url: getMaterialPageUrl(
              element.columnId,
              element.materialId,
              previewId.value,
            ),
            coverImageUrl: element.coverImageUrl.replaceAll(
              '/upload/',
              `${import.meta.env.VITE_API_URL}/upload/`,
            ),
          };
          list.value.push(newElement);
        });
      } else {
        msg.value = '未搜索到相关内容';
      }
    } else {
      msg.value = '搜索出错，请稍后再试';
    }

    ssrContext.initialState.data.current.listData = list.value;
    ssrContext.initialState.data.current.total = total.value;
  }
  ssrContext.initialState.data.current.msg = msg.value;

  // #endregion
});

if (!import.meta.env.SSR) {
  const ssrInitialState = useSSRInitialStateStore();
  list.value = ssrInitialState.data.current.listData;
  total.value = ssrInitialState.data.current.total;
  msg.value = ssrInitialState.data.current.msg;
}
</script>

<style scoped>
@media screen and (device-width >= 1180px) {
  .main-wrap {
    display: flex;
    justify-content: center;
    max-width: 1400px;
    padding-top: 15px;
    margin: 0 auto;
  }
  .main-content-wrap {
    flex: 1;
  }

  .search-main-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    /* height: 100%; */
  }

  .search-area {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }

  .search-area .search-input {
    width: 70%;
    height: 40px;
    font-size: 14px;
  }
  :deep(.search-area .search-input .el-input__wrapper) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .search-area .search-btn {
    width: 100px;
    height: 40px;
    font-size: 14px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  :deep(.el-empty__description p) {
    font-size: 14px;
  }

  .article-list-item {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
}
</style>
<style scoped>
@media screen and (device-width <= 1180px) {
  .main-wrap {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .main-content-wrap {
    flex: 1;
  }

  .search-main-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 2vw;
  }

  .search-area {
    display: flex;
    justify-content: center;
    padding: 1vw 3vw;
  }

  .search-area .search-input {
    width: calc(100% - 25vw);
    height: 12vw;
    font-size: 4vw;
  }
  :deep(.search-area .search-input .el-input__wrapper) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .search-area .search-btn {
    width: 25vw;
    height: 12vw;
    font-size: 4vw;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  :deep(.el-empty__description p) {
    font-size: 3.5vw;
  }

  .article-list-item {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  :deep(.pager-container .table-pager) {
    display: flex;
    justify-content: center;
    margin: 1vw 0;
  }
  :deep(.pager-container .num-item) {
    display: none;
  }
}
</style>
