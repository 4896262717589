<template>
  <div
    class="carousel-two"
    :style="{
      height: `${configDataForm.imageHeight}px`,
    }"
  >
    <el-carousel
      :interval="5000"
      :height="'100%'"
      arrow="always"
      @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
      @mouseleave="handleShowOperationBtn(false)"
    >
      <client-only>
        <el-carousel-item
          v-for="item in configDataForm.items"
          :key="item"
          :name="item.backgroundFileName"
        >
          <a :href="item.linkUrl" target="_blank">
            <el-image
              style="width: 100%; height: 100%"
              :src="item.backgroundFileFullUrl"
              fit="cover"
            ></el-image>
          </a>
        </el-carousel-item>
        <operation-container
          v-if="showOperationBtn && !showEditForm"
          :component-data="props.componentData"
          :show-edit-content-button="props.showEditContentButton"
          :show-edit-components-button="props.showEditComponentsButton"
          :show-delete-components-button="props.showDeleteComponentsButton"
          :show-insert-components-button="props.showInsertComponentsButton"
          :show-sort-components-button="props.showSortComponentsButton"
          @handle-show-edit-form="handleShowEditForm"
          @handle-change-components="handleChangeComponents"
          @handle-delete-components="handleDeleteComponents"
          @handle-insert-components="handleInsertComponents"
          @handle-sort-components="handleSortComponents"
        ></operation-container>
      </client-only>
    </el-carousel>
    <client-only>
      <el-drawer
        v-model="showEditForm"
        size="50%"
        :title="props.componentData.componentName"
      >
        <carousel-edit-form
          v-if="showEditForm"
          v-model="configDataForm"
          :component-data="props.componentData"
          @handle-show-edit-form="handleShowEditForm"
          @handle-edit-components-content="handleEditComponentsContent"
        ></carousel-edit-form>
      </el-drawer>
    </client-only>
  </div>
</template>
<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import OperationContainer from '../common/OperationContainer.vue';
import CarouselEditForm from './CarouselEditForm.vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量
const configDataForm = ref({
  items: [
    {
      sortNum: 1,
      backgroundType: 1,
      backgroundFileName: '示例图片1.jpg',
      backgroundFileKey: 'background1',
      linkType: 2,
      linkUrl: 'https://www.baidu.com',
    },
    {
      sortNum: 2,
      backgroundType: 1,
      backgroundFileName: '示例图片2.jpg',
      backgroundFileKey: 'background2',
      linkType: 1,
      linkUrl: '/article-list/132',
    },
  ],
});

if (props.componentData.configData) {
  configDataForm.value = JSON.parse(props.componentData.configData);
  configDataForm.value.items.sort((a, b) => {
    return a.sortNum - b.sortNum;
  });
}
// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};
/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};

// #endregion
</script>
<style scoped lang="scss">
/* 在屏幕宽度大于等于 1180px 时应用以下样式 */
@media screen and (device-width >= 1180px) {
  .carousel-two {
    width: 100%;
    min-height: 400px;
  }

  .el-carousel {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}

/* 在屏幕宽度小于等于 1180px 时应用以下样式 */
@media screen and (device-width <= 1180px) {
  .carousel-two {
    width: 100%;
    height: 35vh !important;
  }

  .el-carousel {
    position: relative;
    width: 100%;
    height: 35vh;
    margin: auto;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  :deep(.el-image__inner) {
    object-fit: cover !important;
  }
}
</style>
