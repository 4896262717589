<template>
  <div>
    <client-only>
      <div
        class="column-content-container"
        :style="{
          backgroundImage: `url(${configDataForm.backGroundImageFullUrl})`,
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
          'background-position': 'center',
        }"
        @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
        @mouseleave="handleShowOperationBtn(false)"
      >
        <div class="left-content">
          <div class="title">
            <div class="title-icon">
              <img :src="leftIconImageUrl" fit="cover" />
              <span class="title-text" :style="{ color: leftTitleColor }">{{
                leftTitle
              }}</span>
            </div>
            <div class="more">查看更多</div>
          </div>
          <div class="content">
            <div class="content-carousel">
              <el-carousel
                :interval="5000"
                :height="'100%'"
                arrow="always"
                @change="changeCarousel"
              >
                <client-only>
                  <el-carousel-item
                    v-for="item in leftColumnContentList"
                    :key="item"
                    :name="item.coverImageName"
                  >
                    <a :href="item.url" target="_blank">
                      <el-image
                        style="width: 100%; height: 100%"
                        :src="item.coverImageUrl"
                        fit="cover"
                      ></el-image>
                    </a>
                  </el-carousel-item>
                </client-only>
              </el-carousel>
            </div>
            <div
              v-if="carouselColumnContent"
              class="content-des"
              :style="{ background: configDataForm.backGroundColor }"
            >
              <div class="title-content">{{ carouselColumnContent.title }}</div>
              <div class="des-content">{{ carouselColumnContent.digest }}</div>
            </div>
          </div>
        </div>
        <div class="right-content">
          <div class="title">
            <div class="title-icon">
              <img :src="rightIconImageUrl" fit="cover" />
              <span class="title-text" :style="{ color: rightTitleColor }">{{
                rightTitle
              }}</span>
            </div>
            <div class="more">查看更多</div>
          </div>
          <div class="content">
            <ul>
              <li v-for="item in rightColumnContentList" :key="item.id">
                <a :href="item.url ? item.url : '#'" class="list-item">
                  <div
                    class="item-title"
                    :style="{
                      color: item.fontColor,
                    }"
                  >
                    {{ item.title }}
                  </div>
                  <div class="publish-time">
                    {{
                      item.publishTime
                        ? formatDate(item.publishTime)
                        : item.publishTime
                    }}
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <operation-container
          v-if="showOperationBtn && !showEditForm"
          :component-data="props.componentData"
          :show-edit-content-button="props.showEditContentButton"
          :show-edit-components-button="props.showEditComponentsButton"
          :show-delete-components-button="props.showDeleteComponentsButton"
          :show-insert-components-button="props.showInsertComponentsButton"
          :show-sort-components-button="props.showSortComponentsButton"
          @handle-show-edit-form="handleShowEditForm"
          @handle-change-components="handleChangeComponents"
          @handle-delete-components="handleDeleteComponents"
          @handle-insert-components="handleInsertComponents"
          @handle-sort-components="handleSortComponents"
        ></operation-container>
      </div>
      <el-drawer
        v-model="showEditForm"
        size="70%"
        :title="props.componentData.componentName"
      >
        <column-content-edit-form
          v-if="showEditForm"
          v-model="configDataForm"
          :component-data="props.componentData"
          @handle-show-edit-form="handleShowEditForm"
          @handle-edit-components-content="handleEditComponentsContent"
        ></column-content-edit-form>
      </el-drawer>
    </client-only>
  </div>
</template>

<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import { formatDate } from '@/utils/common/date-util';
import OperationContainer from '../common/OperationContainer.vue';
import ColumnContentEditForm from './ColumnContentEditFormSix.vue';

// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量

// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);
// 创建变量设默认值
const configDataForm = ref('');
if (props.componentData.configData) {
  // 直接这样写SSR服务器渲可以得到数据
  configDataForm.value = JSON.parse(props.componentData.configData);
  // /**
  //  * 后台编辑，需要等数据更新了都
  //  */
  // nextTick(() => {
  //   configDataForm.value = JSON.parse(props.componentData.configData);
  // });
}
// 左侧图标
const leftIconImageUrl = computed(() => {
  return configDataForm.value.leftItem
    ? configDataForm.value.leftItem.iconFullUrl
    : '';
});

const leftTitle = computed(() => {
  return configDataForm.value.leftItem
    ? configDataForm.value.leftItem.title
    : '';
});

const leftTitleColor = computed(() => {
  return configDataForm.value.leftItem
    ? configDataForm.value.leftItem.fontColor
    : '';
});
// 左栏目内容
const leftColumnContentList = computed(() => {
  return configDataForm.value.leftItem
    ? configDataForm.value.leftItem.materialInfo
    : [];
});
const carouselColumn = ref(0);
const carouselColumnContent = computed(() => {
  return leftColumnContentList.value[carouselColumn.value];
});

// 右边侧图标
const rightIconImageUrl = computed(() => {
  return configDataForm.value.rightItem
    ? configDataForm.value.rightItem.iconFullUrl
    : '';
});

// 右边侧图标
const rightTitle = computed(() => {
  return configDataForm.value.rightItem
    ? configDataForm.value.rightItem.title
    : '';
});

const rightTitleColor = computed(() => {
  return configDataForm.value.rightItem
    ? configDataForm.value.rightItem.fontColor
    : '';
});
// 右边栏目内容
const rightColumnContentList = computed(() => {
  return configDataForm.value.rightItem
    ? configDataForm.value.rightItem.materialInfo
    : [];
});

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

const changeCarousel = (index) => {
  carouselColumn.value = index;
};

/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};

/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};
/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

// #endregion
</script>
<style scoped lang="scss">
/* 在屏幕宽度大于等于 1180px 时应用以下样式 */
@media screen and (device-width >= 1180px) {
  .column-content-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 510px;
    padding: 0 100px;
    margin: 50px 0;

    .left-content {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%;
      padding-right: 30px;
      .title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid #cfd0c5;
        .title-icon {
          display: flex;
          align-content: center;
          align-items: center;
          font-size: 36px;
          img {
            width: 36px;
            height: 36px;
            margin-right: 10px;
            object-fit: cover;
          }
        }
        .more {
          display: flex;
          align-items: center;
          font-size: 20px;
          color: #7c7c7c;
        }
      }
      .content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;

        .content-carousel {
          flex-grow: 1;
          width: 100%;

          .el-carousel {
            position: relative;
            width: 100%;
            height: 100%;
            margin: auto;
          }

          .el-carousel__item:nth-child(2n) {
            background-color: #99a9bf;
          }

          .el-carousel__item:nth-child(2n + 1) {
            background-color: #d3dce6;
          }
        }
        .content-des {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 100%;
          height: 200px;
          padding: 20px 40px;
          color: #fff;

          .title-content {
            font-size: 24px;
            font-weight: bold;
          }
          .des-content {
            display: -webkit-box;
            overflow: hidden; /* 隐藏超出的内容 */
            font-size: 18px;
            color: #b9c5de;
            text-overflow: ellipsis; /* 显示省略符号来表示被截断 */
            -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .right-content {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%;
      padding-left: 30px;

      .title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid #cfd0c5;
        .title-icon {
          display: flex;
          align-content: center;
          align-items: center;
          font-size: 36px;
          img {
            width: 36px;
            height: 36px;
            margin-right: 10px;
            object-fit: cover;
          }
        }
        .more {
          display: flex;
          align-items: center;
          font-size: 20px;
          color: #7c7c7c;
        }
      }
      .content {
        flex-grow: 1;
        padding-top: 20px;

        ul {
          width: 100%;
          height: 60px;
          li::before {
            position: absolute;
            top: -50px;
            left: 0;
            width: 20px;
            height: 10px;
            font-size: 80px;
            color: #194193;
            content: '.';
          }
          li {
            position: relative;
            height: inherit;
            padding-left: 40px;
            border-bottom: #e7e8ea 1px solid;

            .list-item {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: inherit;

              .item-title {
                flex-grow: 1;
                overflow: hidden;
                font-size: 18px;
                color: #373737;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .publish-time {
                width: 120px;
                margin-left: auto;
                overflow: hidden;
                font-size: 18px;
                color: #838485;
                text-align: right;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
/* 在屏幕宽度小于等于 1180px 时应用以下样式 */
@media screen and (device-width <= 1180px) {
  .column-content-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 4vw;
    background-color: #fff !important;
    background-image: none !important;
    .left-content {
      display: flex;
      flex-direction: column;
      align-content: center;
      .title {
        display: flex;
        justify-content: space-between;
        width: 92vw;
        padding-bottom: 4vw;
        .title-icon {
          display: flex;
          align-content: center;
          align-items: center;
          font-size: 7.5vw;
          img {
            width: 7.5vw;
            height: 7.5vw;
            margin-right: 2vw;
            object-fit: cover;
          }
        }
        .more {
          display: flex;
          align-items: center;
          font-size: 4vw;
          color: #7c7c7c;
        }
      }
      .content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        padding-top: 4vw;

        .content-carousel {
          flex-grow: 1;
          width: 92vw;
          height: 51.75vw;
          .el-carousel {
            position: relative;
            width: 92vw;
            height: 51.75vw;
            margin: auto;
          }

          .el-carousel__item:nth-child(2n) {
            background-color: #99a9bf;
          }

          .el-carousel__item:nth-child(2n + 1) {
            background-color: #d3dce6;
          }
        }
        .content-des {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 92vw;
          height: 36vw;
          padding: 4vw;
          color: #fff;

          .title-content {
            font-size: 5vw;
            font-weight: bold;
          }
          .des-content {
            display: -webkit-box;
            overflow: hidden; /* 隐藏超出的内容 */
            font-size: 4vw;
            color: #b9c5de;
            text-overflow: ellipsis; /* 显示省略符号来表示被截断 */
            -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .right-content {
      display: flex;
      flex-direction: column;
      height: auto;
      padding-top: 4vw;

      .title {
        display: flex;
        justify-content: space-between;
        width: 92vw;
        padding-bottom: 4vw;
        .title-icon {
          display: flex;
          align-content: center;
          align-items: center;
          font-size: 7.5vw;
          img {
            width: 7.5vw;
            height: 7.5vw;
            margin-right: 2vw;
            object-fit: cover;
          }
        }
        .more {
          display: flex;
          align-items: center;
          font-size: 4vw;
          color: #7c7c7c;
        }
      }
      .content {
        flex-grow: 1;
        background-color: #f2f5f9;
        ul {
          margin: 0 3vw;
          li::before {
            position: absolute;
            top: -8vw;
            left: 0;
            width: 4vw;
            height: 2vw;
            font-size: 16vw;
            color: #194193;
            content: '.';
          }
          li {
            position: relative;
            height: 12.5vw;
            padding-left: 4vw;
            line-height: 16vw;
            border-bottom: #e7e8ea 0.2vw solid;

            .list-item {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              height: inherit;

              .item-title {
                width: 58vw;
                overflow: hidden;
                font-size: 4vw;
                color: #373737;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .publish-time {
                width: 21vw;
                overflow: hidden;
                font-size: 3.75vw;
                color: #838485;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}
</style>
