/**
 * ssr的state对象处理
 */
const useSSRInitialStateStore = defineStore('ssr-state', () => {
  // state
  const data = ref({}); // 获取数据对象
  const backTopFlag = ref(false); // 返回顶部按钮是否显示

  /**
   * 设置data对象值
   * @param {*} val
   */
  function setData(val) {
    data.value = val;
  }

  /**
   * 设置是否显示返回顶部按钮的标志位
   *
   * @param {boolean} val - 是否显示返回顶部按钮，true 表示显示，false 表示不显示
   */
  function setDataBackTop(val) {
    backTopFlag.value = val;
  }

  return {
    data,
    setData,
    backTopFlag,
    setDataBackTop,
  };
});
export default useSSRInitialStateStore;
