<template>
  <div
    v-if="topNavComponent && configInfo.isOpenWebsite"
    :class="
      configInfo && configInfo.isMournMode
        ? ' home-index-gray layout-website'
        : 'layout-website'
    "
  >
    <frame-top :top-nav-component="topNavComponent"></frame-top>
    <div class="layout-website-content">
      <router-view></router-view>
    </div>
    <frame-bottom
      :copyright-nav-component="copyrightNavComponent"
    ></frame-bottom>
  </div>
  <div v-else class="system-maintenance">
    <el-empty description="您预览的模板未维护任何内容，请先去维护。" />
  </div>
  <button
    id="btn_smoothToTop"
    type="button"
    class="style-goto-top"
    :class="{ 'style-show': styleShowLaMby }"
    @click="handleSmoothToTopScroll"
  >
    <svg
      t="1693361300158"
      class="top-icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="9670"
    >
      <path
        d="M542.293333 268.373333a42.666667 42.666667 0 0 0-14.08-8.96 42.666667 42.666667 0 0 0-32.426666 0 42.666667 42.666667 0 0 0-14.08 8.96l-170.666667 170.666667a42.666667 42.666667 0 1 0 60.586667 60.586667L469.333333 401.493333V896a42.666667 42.666667 0 0 0 85.333334 0V401.493333l97.706666 98.133334a42.666667 42.666667 0 0 0 60.586667 0 42.666667 42.666667 0 0 0 0-60.586667zM810.666667 85.333333H213.333333a42.666667 42.666667 0 0 0 0 85.333334h597.333334a42.666667 42.666667 0 0 0 0-85.333334z"
        p-id="9671"
        fill="#707070"
      ></path>
    </svg>
  </button>
</template>
<script setup>
// #region 引用
import {
  OwTerminal,
  OwPageType,
  OwComponentType,
  ColumnType,
} from '@/configs/enums/website';
import {
  getConfigFullInfo,
  getTemplateFullInfo,
  getColumnDetailById,
} from '@/apis/modules/auth/website/website-ssr';
import treeConverter from '@/utils/converter/tree-converter';
import { getColumnPageUrlNew } from '@/utils/business/website';
import useSSRInitialStateStore from '@/stores/ssr-state';
import { useSSRContext } from 'vue';
import FrameTop from './FrameTop.vue';
import FrameBottom from './FrameBottom.vue';

// #endregion

// #region props/emit

// #endregion

// #region 变量/常量

const ssrInitialState = useSSRInitialStateStore();
const route = useRoute();
const configInfo = ref({}); // 配置信息
const configPageList = ref([]); // 配置页面列表
const originalColumnList = ref([]); // 原始栏目列表
const columnList = ref([]); // 树形结构的栏目列表
const curMapColumnList = ref([]); // 当前位置的栏目列表
const curSiblingColumnInfo = ref([]); // 当前同级栏目信息
const columnId = ref(Number(route.params.columnId)); // 当前栏目id
const columnInfo = ref({}); // 当前栏目信息
const styleShowLaMby = ref(false); // 显示返回顶部按钮
const copyrightNavComponent = ref(null); // 底部导航组件
const topNavComponent = ref(null); // 顶部导航组件

// #endregion

// #region 业务方法

/**
 * 获取自定义页面URL
 *
 * @param pageColumnId 页面适用的栏目ID
 * @param previewId 预览ID
 * @returns /content/customer/{pageColumnId}/{pageId}/{previewId}
 */
const getCustomPageUrl = (pageColumnId, previewId) => {
  const pageId = configPageList.value.find(
    (x) => x.columnId === pageColumnId,
  )?.id;
  return `/content/customer/${pageColumnId}/${pageId ?? '0'}${
    previewId ? `/${previewId}` : ''
  }`;
};

/**
 * 处理栏目跳转链接
 *
 * @param originalColumnUrl 栏目URL
 * @param originalColumnId 栏目ID
 * @param originalColumnType 栏目类型
 * @param previewId 预览ID
 */
const handleColumnHrefUrl = (
  originalColumnUrl,
  originalColumnId,
  originalColumnType,
  previewId,
) => {
  let newUrl = originalColumnUrl;
  if (originalColumnType === ColumnType.导航栏目) {
    newUrl = null;
  } else if (originalColumnType === ColumnType.自定义模板页栏目) {
    newUrl = getCustomPageUrl(originalColumnId, previewId);
  } else if (previewId) {
    if (originalColumnUrl === '/') {
      // 预览调用需要单独处理
      newUrl = `${originalColumnUrl}preview/${previewId}`;
    } else if (originalColumnUrl && originalColumnUrl.indexOf('http') > -1) {
      // 外部链接不用绑定预览参数
      newUrl += '';
    } else {
      newUrl = originalColumnUrl
        ? `${originalColumnUrl}/${previewId}`
        : getColumnPageUrlNew(originalColumnType, originalColumnId, previewId);
    }
  } else {
    newUrl =
      originalColumnUrl ||
      getColumnPageUrlNew(originalColumnType, originalColumnId);
  }
  return newUrl;
};

/**
 * 处理当前位置栏目信息
 * @param curColumnId 当前栏目ID
 * @param previewId 预览ID
 */
const handleCurMapColumnList = (curColumnId, previewId) => {
  const curInfo = originalColumnList.value.find((x) => x.id === curColumnId);
  if (curInfo && curInfo.parentId) {
    handleCurMapColumnList(curInfo.parentId);
  }
  curMapColumnList.value.push({
    id: curInfo.id,
    title: curInfo.columnName,
    url: handleColumnHrefUrl(
      curInfo.hrefURL,
      curInfo.id,
      curInfo.columnType,
      previewId,
    ),
    parentId: curInfo.parentId,
  });
};

/**
 * 数据处理函数
 *
 * @param topNavComponent 顶部导航组件对象
 * @param previewId 预览ID
 * @returns 无返回值
 */
const dataProcessing = (topNav, id) => {
  if (topNav.configData) {
    const configData = JSON.parse(topNav.configData);
    let list = []; // 定义新栏目列表
    // 处理栏目跳转链接
    configData.columnList.forEach((element) => {
      const newElement = { ...element }; // 创建新的对象
      newElement.hrefURL = handleColumnHrefUrl(
        newElement.hrefURL,
        newElement.id,
        newElement.columnType,
        id,
      );
      list.push(newElement); // 将新对象推入列表
    });
    // 记录原始栏目列表
    originalColumnList.value = configData.columnList;
    // 树形结构处理
    list = treeConverter(list, {
      id: 'id',
      pid: 'parentId',
      label: 'columnName',
      sorter: ['sortNum'],
      disabled: (item) => item.columnName.indexOf('columnName') > -1,
    });
    // 存储树形结构的栏目列表
    configData.columnList = list;

    // 记录当前栏目id
    configData.activeIndex = route.params.columnId;

    // 处理左侧导航栏显示标记
    if (configData.navPageLeftArea && configData.navPageLeftArea.showNav) {
      topNav.isShowLeftNav = configData.navPageLeftArea.showNav;
      topNav.navPageLeftArea = configData.navPageLeftArea;
    }
    // 处理当前位置栏目信息
    if (columnId.value) {
      handleCurMapColumnList(columnId.value, id);
      // 当前一级栏目id
      const curFirstColumnInfo = curMapColumnList.value.find(
        (x) => !x.parentId,
      );
      if (curFirstColumnInfo) {
        curSiblingColumnInfo.value = configData.columnList.find(
          (x) => x.id === curFirstColumnInfo.id,
        );
      }
    }

    topNav.configData = JSON.stringify(configData);
  }
};

/**
 * 异步获取官网配置信息的完整信息
 *
 * @returns {Promise<void>} 无返回值
 */
async function fetchConfigFullInfo() {
  // 这里应该是一个实际的 API 请求
  // 重复刷新页面会重复请求
  if (route.path === '/preview/undefined/' && route.path === '/undefined/') {
    return;
  }
  // 预览ID
  const previewId = route.params.id;

  let apiUrl = getConfigFullInfo;
  let apiData = { terminal: OwTerminal.Web端 };
  // 预览获取组件模板数据
  if (previewId && previewId !== 'undefined') {
    apiUrl = getTemplateFullInfo;
    apiData = { id: previewId, terminal: OwTerminal.Web端 };
  }
  // 在服务器端渲染期间预取数据
  // 获取组件模板数据
  const res = await apiUrl.use(apiData);

  if (res.code === '00000' && res.data) {
    // 文件地址手动替换一下
    // 预览走的逻辑
    if (previewId) {
      res.data.templatePageList.forEach((element) => {
        if (element.componentList.length > 0) {
          element.componentList.forEach((item) => {
            const curItem = item;
            if (curItem.configData) {
              curItem.configData = curItem.configData.replaceAll(
                '/upload/',
                `${import.meta.env.VITE_API_URL}/upload/`,
              );
            }

            curItem.effectCoverImg = `${
              import.meta.env.VITE_API_URL + curItem.effectCoverImg
            }?v=${curItem.effectCoverImg}`;
            item = curItem;
          });
        }
      });
      // 预览调用需要单独处理
      const { templateBasicInfo } = res.data;
      const { templatePageList } = res.data;

      // 成功
      configInfo.value = {
        homeTitle: templateBasicInfo.templateName,
        homeKeyword: templateBasicInfo.templateName,
        homeDesc: templateBasicInfo.templateName,
        isOpenWebsite: true,
      };
      configPageList.value = templatePageList;
    } else {
      // 成功
      res.data.configPageList.forEach((element) => {
        if (element.componentList.length > 0) {
          element.componentList.forEach((item) => {
            const curItem = item;
            if (curItem.configData) {
              curItem.configData = curItem.configData.replaceAll(
                '/upload/',
                `${import.meta.env.VITE_API_URL}/upload/`,
              );
            }

            curItem.effectCoverImg = `${
              import.meta.env.VITE_API_URL + curItem.effectCoverImg
            }?v=${curItem.effectCoverImg}`;
            item = curItem;
          });
        }
      });
      configInfo.value = res.data.configBasicInfo;
      configPageList.value = res.data.configPageList;
    }

    /**
     * 版权区域
     */
    copyrightNavComponent.value = configPageList.value
      .find((f) => f.pageType === OwPageType.首页)
      .componentList.find(
        (f) => f.componentType === OwComponentType.版权区组件,
      );

    /**
     * 导航栏区域
     */

    topNavComponent.value = configPageList.value
      .find((f) => f.pageType === OwPageType.首页)
      .componentList.find(
        (f) => f.componentType === OwComponentType.顶部导航栏组件,
      );
    if (topNavComponent.value) {
      topNavComponent.value.fromType = 3;
      dataProcessing(topNavComponent.value, previewId);
    }
  }
}

/**
 * 异步函数，用于获取栏目信息详情
 *
 * @returns 无返回值
 */
async function fetchColumn() {
  // 这里应该是一个实际的 API 请求
  if (route.params.columnId) {
    const content = await getColumnDetailById.use({ columnId: columnId.value });
    if (content.code === '00000' && content.data) {
      columnInfo.value = content.data;
    }
  }
}

/**
 * 在服务端获取数据
 */
onServerPrefetch(async () => {
  const ssrContext = useSSRContext();
  // 获取配置信息
  await fetchConfigFullInfo();
  // 路由参数中有栏目信息就获取栏目数据
  await fetchColumn();

  // 将数据设置到initialState（后续的内容页都从这里获取数据）
  ssrContext.initialState.data.current = {
    configInfo: configInfo.value,
    configPageList: configPageList.value,
    topNavComponent: topNavComponent.value,
    columnList: columnList.value,
    curMapColumnList: curMapColumnList.value,
    curSiblingColumnInfo: curSiblingColumnInfo.value,
    copyrightNavComponent: copyrightNavComponent.value,
    previewId: route.params.id, // 模板预览时的id，预览官网是的模板
    columnId: route.params.columnId, // 栏目id，用于判断选中栏目
    columnInfo: columnInfo.value,
  };
});

/** *
 * 定义一个防抖函数
 */
const debounce = (fn, delay = 500) => {
  let timer = null;
  return function (...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};
/**
 * 滑动方法
 */
const scrollToTop = () => {
  let height = document.documentElement.scrollTop || document.body.scrollTop;
  const t = setInterval(() => {
    height -= 50;
    if (height > 0) {
      window.scrollTo(0, height);
    } else {
      window.scrollTo(0, 0);
      clearInterval(t);
    }
  }, 10);
};

/**
 * 判断是否显示回到顶部按钮
 */
const isElemVisible = (el) => {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top - 400; // 200 = buffer
  const elemBottom = rect.bottom;
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop;
  return elemTop < scrollTop && elemBottom >= 0;
};

/**
 * 平滑滚动到页面顶部
 */
const handleSmoothToTopScroll = () => {
  scrollToTop();
  // smoothToTop.classList.remove('style-show');
  styleShowLaMby.value = false;
};

/**
 * 处理滚动效果
 */
const handleScroll = (evt) => {
  const smoothToTop = document.getElementById('btn_smoothToTop');

  if (isElemVisible(smoothToTop)) {
    styleShowLaMby.value = true;
    ssrInitialState.setDataBackTop(true);
  } else {
    styleShowLaMby.value = false;
    ssrInitialState.setDataBackTop(false);
  }
};

if (!import.meta.env.SSR) {
  // 客户端从initialState读取数据
  configInfo.value = ssrInitialState.data.current.configInfo;
  configPageList.value = ssrInitialState.data.current.configPageList;
  topNavComponent.value = ssrInitialState.data.current.topNavComponent;
  copyrightNavComponent.value =
    ssrInitialState.data.current.copyrightNavComponent;

  /**
   * PC端添加滚动条监听事件
   */
  if (document) {
    document.addEventListener('scroll', debounce(handleScroll, 100));
  }
  /**
   * 移动端添加滚动监听事件
   */
  if (document) {
    document.addEventListener('touchmove', debounce(handleScroll, 100));
  }
}

// #endregion
</script>

<style scoped lang="scss">
@media screen and (device-width >= 1180px) {
  .layout-website {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .layout-website-content {
      flex-grow: 1;
    }
  }

  .home-index-gray {
    filter: progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
    filter: grayscale(100%);
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .style-goto-top {
    position: fixed;
    right: 2%;
    bottom: 300px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    width: 56px;
    min-width: 56px !important;
    height: 56px;
    min-height: 56px !important;
    font-size: 26px;
    color: #b1b3b8;
    text-align: center;
    cursor: pointer;
    visibility: hidden;
    background-color: #fff;
    border: none;
    border-radius: 100%;
    box-shadow: 0 2px 16px rgb(0 0 0 / 60%);
    opacity: 0;
    transition: all 0.3s;

    .top-icon {
      justify-content: center;
      width: 32px;
      height: 32px;
    }
  }

  .style-goto-top.style-show {
    visibility: visible;
    opacity: 1;
  }

  .system-maintenance {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}
</style>
<style scoped lang="scss">
@media screen and (device-width <= 1180px) {
  .layout-website {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-x: hidden;
    .layout-website-content {
      flex-grow: 1;
    }
  }

  .home-index-gray {
    filter: progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
    filter: grayscale(100%);
  }

  .style-goto-top {
    position: fixed;
    right: 2%;
    bottom: 300px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    width: 14vw;
    min-width: 14vw !important;
    height: 14vw;
    min-height: 14vw !important;
    font-size: 8vw;
    color: #b1b3b8;
    text-align: center;
    cursor: pointer;
    visibility: hidden;
    background-color: #fff;
    border: none;
    border-radius: 100%;
    box-shadow: 0 1vw 8vw rgb(0 0 0 / 80%);
    opacity: 0;
    transition: all 0.3s;

    .top-icon {
      justify-content: center;
      width: 8vw;
      height: 8vw;
    }
  }

  .style-goto-top.style-show {
    visibility: visible;
    opacity: 1;
  }

  .system-maintenance {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}
</style>
