<template>
  <div>
    <div
      class="image-text-container"
      @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
      @mouseleave="handleShowOperationBtn(false)"
    >
      <div class="show-img">
        <!-- <img :src="configDataForm.showImageFullUrl" /> -->
        <el-image :src="configDataForm.showImageFullUrl" fit="cover"></el-image>
      </div>

      <div class="show-items">
        <div
          v-for="(item, index) in configDataForm.items"
          :key="index"
          class="item-wrap"
        >
          <div class="title" :style="`color:${item.titleFontColor}`">
            {{ item.title }}
          </div>
          <div
            class="content"
            :style="`color:${item.contentFontColor}`"
            v-html="formatHtmlContent(item.content)"
          ></div>
        </div>
        <a
          v-if="configDataForm.buttonText"
          :href="configDataForm.buttonUrl"
          :style="`color:${configDataForm.buttonFontColor}`"
          target="_blank"
          >{{ configDataForm.buttonText }}</a
        >
      </div>

      <client-only>
        <operation-container
          v-if="showOperationBtn && !showEditForm"
          :component-data="props.componentData"
          :show-edit-content-button="props.showEditContentButton"
          :show-edit-components-button="props.showEditComponentsButton"
          :show-delete-components-button="props.showDeleteComponentsButton"
          :show-insert-components-button="props.showInsertComponentsButton"
          :show-sort-components-button="props.showSortComponentsButton"
          @handle-show-edit-form="handleShowEditForm"
          @handle-change-components="handleChangeComponents"
          @handle-delete-components="handleDeleteComponents"
          @handle-insert-components="handleInsertComponents"
          @handle-sort-components="handleSortComponents"
        ></operation-container>
        <el-drawer
          v-model="showEditForm"
          size="60%"
          :title="props.componentData.componentName"
        >
          <image-text-edit-form
            v-if="showEditForm"
            v-model="configDataForm"
            :component-data="props.componentData"
            @handle-show-edit-form="handleShowEditForm"
            @handle-edit-components-content="handleEditComponentsContent"
          ></image-text-edit-form>
        </el-drawer>
      </client-only>
    </div>
  </div>
</template>

<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import OperationContainer from '../common/OperationContainer.vue';
import ImageTextEditForm from './ImageTextEditFormTwentyTwo.vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量

// 表单绑定对象
const configDataForm = ref({
  showImageName: '',
  showImageFileKey: 'showImage',
  imageOrientation: 'left',
  buttonText: '示例链接跳转>>',
  buttonUrl: '',
  buttonFontColor: '#2a87f1',
  items: [
    {
      title: '示例标题1',
      content: '示例内容1',
      titleFontColor: '#333333',
      contentFontColor: '#333333',
    },
    {
      title: '示例标题2',
      content: '示例内容2',
      titleFontColor: '#333333',
      contentFontColor: '#333333',
    },
  ],
});

if (props.componentData.configData) {
  // 服务器渲染的直接调用
  configDataForm.value = JSON.parse(props.componentData.configData);
}

// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 格式化Html显示内容
 */
const formatHtmlContent = (content) => {
  return content.replace(/\n/g, '<br/>');
};

/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};

/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};

/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};

/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};

// #endregion
</script>

<style scoped lang="scss">
@media screen and (device-width >= 1180px) {
  .image-text-container {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: start;
    width: 100%;
    padding: 15px;

    .show-img {
      display: block;
      width: 40%;
      .el-image {
        width: 100%;
      }
    }

    .show-items {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 20px;

      .item-wrap {
        .title {
          display: block;
          font-size: 18px;
          font-weight: 700;
        }
        .content {
          display: block;
          margin-top: 15px;
          font-size: 14px;
          line-height: 1.5em;
        }
      }

      > a {
        display: block;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media screen and (device-width <= 1180px) {
  .image-text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4vw;
    align-items: start;
    width: 100%;
    padding: 3vw 3vw 6vw;

    .show-img {
      display: block;
      width: 100%;
      .el-image {
        width: 100%;
        height: 50vw;
      }
    }

    .show-items {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 4vw;

      .item-wrap {
        .title {
          display: block;
          font-size: 5vw;
          font-weight: 700;
        }
        .content {
          display: block;
          margin-top: 4vw;
          font-size: 3.8vw;
          line-height: 1.5em;
        }
      }

      > a {
        display: block;
        margin-top: 2vw;
        font-size: 4.5vw;
        font-weight: 700;
      }
    }
  }
}
</style>
