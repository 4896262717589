<template>
  <div>
    <div
      class="image-text-container"
      :style="`
        --item-title-bg-color:${configDataForm.itemStyles.titleBgColor};
        --item-title-font-color:${configDataForm.itemStyles.titleFontColor};
        --item-content-bg-color:${configDataForm.itemStyles.contentBgColor};
        --item-content-font-color:${configDataForm.itemStyles.contentFontColor};
        --active-title-bg-color:${configDataForm.activeStyles.titleBgColor};
        --active-title-font-color:${configDataForm.activeStyles.titleFontColor};
        --active-content-bg-color:${configDataForm.activeStyles.contentBgColor};
        --active-content-font-color:${configDataForm.activeStyles.contentFontColor};`"
      @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
      @mouseleave="handleShowOperationBtn(false)"
    >
      <div
        v-for="(item, index) in configDataForm.items"
        :key="index"
        :class="`parent-item` + (item.expanded ? ' expand' : '')"
      >
        <div class="title" @click="handleClickItemTitle(index)">
          <svg
            class="icon-left"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            :fill="configDataForm.itemStyles.titleFontColor"
          >
            <path d="M896 0l128 0 0 128-128 0L896 0 896 0z" />
            <path d="M0 0l704 0 0 128L0 128 0 0 0 0z" />
            <path d="M896 448l128 0 0 128-128 0L896 448 896 448z" />
            <path d="M0 448l704 0 0 128L0 576 0 448 0 448z" />
            <path d="M896 896l128 0 0 128-128 0L896 896 896 896z" />
            <path d="M0 896l704 0 0 128L0 1024 0 896 0 896z" />
          </svg>
          <span class="title-text">{{ item.title }}</span>
          <svg
            class="icon-right"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 35.69 44.54"
          >
            <polygon class="cls-1" points="0 1.16 20.92 22.69 0 44.19 0 1.16" />
            <polyline
              class="cls-2"
              fill="none"
              stroke-miterlimit="10"
              points="14.41 0.34 35 22.67 14.41 44.19"
            />
          </svg>
        </div>
        <div class="content" v-html="formatHtmlContent(item.content)"></div>
      </div>

      <client-only>
        <operation-container
          v-if="showOperationBtn && !showEditForm"
          :component-data="props.componentData"
          :show-edit-content-button="props.showEditContentButton"
          :show-edit-components-button="props.showEditComponentsButton"
          :show-delete-components-button="props.showDeleteComponentsButton"
          :show-insert-components-button="props.showInsertComponentsButton"
          :show-sort-components-button="props.showSortComponentsButton"
          @handle-show-edit-form="handleShowEditForm"
          @handle-change-components="handleChangeComponents"
          @handle-delete-components="handleDeleteComponents"
          @handle-insert-components="handleInsertComponents"
          @handle-sort-components="handleSortComponents"
        ></operation-container>
        <el-drawer
          v-model="showEditForm"
          size="60%"
          :title="props.componentData.componentName"
        >
          <image-text-edit-form
            v-if="showEditForm"
            v-model="configDataForm"
            :component-data="props.componentData"
            @handle-show-edit-form="handleShowEditForm"
            @handle-edit-components-content="handleEditComponentsContent"
          ></image-text-edit-form>
        </el-drawer>
      </client-only>
    </div>
  </div>
</template>

<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import OperationContainer from '../common/OperationContainer.vue';
import ImageTextEditForm from './ImageTextEditFormTwenty.vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量

// 表单绑定对象
const configDataForm = ref({
  itemStyles: {
    titleBgColor: '#ffffff',
    titleFontColor: '#333333',
    contentBgColor: '#ffffff',
    contentFontColor: '#333333',
  },
  activeStyles: {
    titleBgColor: '#194193',
    titleFontColor: '#ffffff',
    contentBgColor: '#e2e9f1',
    contentFontColor: '#333333',
  },
  items: [
    {
      title: '示例标题1',
      content: '示例内容1',
    },
    {
      title: '示例标题2',
      content: '示例内容2',
    },
  ],
});

if (props.componentData.configData) {
  // 服务器渲染的直接调用
  configDataForm.value = JSON.parse(props.componentData.configData);
}

// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 格式化Html显示内容
 */
const formatHtmlContent = (content) => {
  return content.replace(/\n/g, '<br/>');
};

/**
 * 每项标题点击事项
 */
const handleClickItemTitle = (index) => {
  const currentItem = configDataForm.value.items[index];
  if (!currentItem) {
    return;
  }
  currentItem.expanded = !currentItem.expanded;
};

/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};
/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};
/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // console.log('configDataForm--1:', configDataForm);
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};

// #endregion
</script>

<style scoped lang="scss">
@media screen and (device-width >= 1180px) {
  .image-text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: self-start;
    justify-content: center;
    width: 100%;
    padding: 15px;

    .parent-item {
      display: block;
      width: 100%;
      border: 2px solid #ccc;

      .title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        padding-left: 20px;
        font-size: 16px;
        font-weight: 700;
        color: var(--item-title-font-color);
        cursor: pointer;
        background-color: var(--item-title-bg-color);

        .title-text {
          margin-left: 10px;
        }

        .icon-left {
          height: 100%;
          padding: 12px 0;
          fill: var(--item-title-font-color);
        }

        .icon-right {
          height: 100%;
          padding: 12px;
          margin-right: 10px;
          margin-left: auto;
          transform: rotate(90deg);
          .cls-1 {
            fill: var(--item-title-font-color);
          }
          .cls-2 {
            stroke: var(--item-title-font-color);
          }
        }
      }
      .content {
        display: none;
        padding: 15px 20px;
        font-size: 14px;
        color: var(--item-content-font-color);
        background-color: var(--item-content-bg-color);
        border-top: 2px solid #ccc;
      }

      &:hover {
        .title {
          color: var(--active-title-font-color);
          background-color: var(--active-title-bg-color);

          .icon-left {
            fill: var(--active-title-font-color);
          }
          .icon-right {
            .cls-1 {
              fill: var(--active-title-font-color);
            }
            .cls-2 {
              stroke: var(--active-title-font-color);
            }
          }
        }
        .content {
          color: var(--active-content-font-color);
          background-color: var(--active-content-bg-color);
        }
      }

      &.expand {
        .title {
          .icon-right {
            transform: rotate(270deg);
          }
        }
        .content {
          display: block;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media screen and (device-width <= 1180px) {
  .image-text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 3vw;
    align-items: self-start;
    justify-content: center;
    width: 100%;
    padding: 3vw;

    .parent-item {
      display: block;
      width: 100%;
      border: 0.6vw solid #ccc;

      .title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 10vw;
        padding-left: 3vw;
        font-size: 4.2vw;
        font-weight: 700;
        color: var(--item-title-font-color);
        cursor: pointer;
        background-color: var(--item-title-bg-color);

        .title-text {
          margin-left: 2vw;
        }

        .icon-left {
          height: 100%;
          padding: 3vw 0;
          fill: var(--item-title-font-color);
        }

        .icon-right {
          height: 100%;
          padding: 3vw;
          margin-right: 2vw;
          margin-left: auto;
          transform: rotate(90deg);
          .cls-1 {
            fill: var(--item-title-font-color);
          }
          .cls-2 {
            stroke: var(--item-title-font-color);
          }
        }
      }
      .content {
        display: none;
        padding: 3vw;
        font-size: 14px;
        color: var(--item-content-font-color);
        background-color: var(--item-content-bg-color);
        border-top: 2px solid #ccc;
      }

      &:hover {
        .title {
          color: var(--active-title-font-color);
          background-color: var(--active-title-bg-color);

          .icon-left {
            fill: var(--active-title-font-color);
          }
          .icon-right {
            .cls-1 {
              fill: var(--active-title-font-color);
            }
            .cls-2 {
              stroke: var(--active-title-font-color);
            }
          }
        }
        .content {
          color: var(--active-content-font-color);
          background-color: var(--active-content-bg-color);
        }
      }

      &.expand {
        .title {
          .icon-right {
            transform: rotate(270deg);
          }
        }
        .content {
          display: block;
        }
      }
    }
  }
}
</style>
