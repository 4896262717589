<template>
  <div class="list-main">
    <div
      v-for="(item, index) in props.contentList"
      :key="index"
      class="list-with-image"
    >
      <a :href="item.url">
        <div class="list-with-image-content">
          <div class="list-with-image-content-left">
            <div class="list-with-image-content-left-image">
              <img :src="item.coverImageUrl" />
            </div>
            <div class="list-with-image-content-left-tag">
              <span class="list-with-image-content-left-tag-time">
                <span class="list-with-image-content-left-tag-time-dy">
                  {{ new Date(item.publishTime).getDate() }}
                </span>
                <span class="list-with-image-content-left-tag-time-ny">
                  {{
                    new Date(item.publishTime).getFullYear() +
                    '.' +
                    (new Date(item.publishTime).getMonth() + 1)
                  }}
                </span>
              </span>
              <span class="list-with-image-content-left-tag-read">
                <span class="list-with-image-content-left-tag-read-eye"></span>
                <span class="list-with-image-content-left-tag-read-count">
                  {{ item.readCount }}
                </span>
              </span>
            </div>
          </div>
          <div class="list-with-image-content-right">
            <div class="list-with-image-content-right-title">
              {{ item.title }}
            </div>
            <div class="list-with-image-content-right-digest">
              {{ item.digest }}
            </div>
            <div class="list-with-image-content-right-btn">
              <span> 详细+ </span>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>
<script setup>
// #region 引用

// #endregion

// #region props/emit
const props = defineProps({
  contentList: {
    type: Array,
    default: () => [],
  },
});
// #endregion

// #region 变量/常量

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

// #endregion
</script>
<style scoped>
@media screen and (device-width >= 1180px) {
  .list-with-image {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-bottom: 1px solid #ccc;
  }

  .list-with-image-title {
    display: block;
    margin: 20px 0;
    overflow: hidden;
    font-size: 1.25rem;
    color: #008bd5;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.5s ease;
  }

  .list-with-image-content {
    display: flex;
    margin: 10px 0;

    /* flex-direction: column; */
  }

  .list-with-image-content-left {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 300px;
  }

  .list-with-image-content-left-image {
    width: 288px;
    height: 192px;
    transition: all 0.5s ease;
  }

  .list-with-image-content-left-image img {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }
  .list-with-image:hover {
    border-bottom: 1px solid #ccc;
  }

  .list-with-image:hover .list-with-image-content-left-image img,
  .list-with-image:hover .list-with-image-content-right-title {
    transform: translateX(5px);
  }

  .list-with-image-content-left-tag {
    position: absolute;
    top: 0;
    left: 5%;
    width: 70px;
    color: white;
    text-align: center;
    background: #008bd5;
    opacity: 0.9;
  }

  .list-with-image-content-left-tag span {
    display: inline-block;
  }
  .list-with-image-content-left-tag-time {
    display: flex;

    /* flex-direction: column; */
  }

  .list-with-image-content-left-tag-time-dy {
    font-size: 1.4375rem;
    color: #fff;
  }

  .list-with-image-content-left-tag-time-ny {
    width: 100%;
  }

  .list-with-image-content-left-tag-read {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 3px 0;
    font-size: 0.75rem;
    color: #888;
    background: #fff;
  }

  .list-with-image-content-left-tag-read-eye {
    display: inline-block;
    width: 17px;
    height: 11px;
    margin-right: 3px;
    background: url('./image/eye.png') no-repeat;
    background-size: cover;
  }

  .list-with-image-content-right {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 20px;

    /* justify-content: space-between; */
  }

  .list-with-image-content-right-digest {
    height: 66px;
    overflow: hidden;
    font-size: 0.875rem;
    line-height: 22px;
    color: #8c8c8c;
  }

  .list-with-image-content-right-title {
    display: -webkit-box;
    overflow: hidden;
    font-size: 1.25rem;
    line-height: 40px;
    color: #333;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    transition: all 0.5s ease;
    -webkit-box-orient: vertical;
  }

  .list-with-image-content-right-btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .list-with-image-content-right-btn span {
    display: inline-block;
    width: 110px;
    height: 30px;
    margin: 40px 10px 0 0;
    font-size: 0.875rem;
    line-height: 30px;
    color: #8c8c8c;
    text-align: center;
    background: #fff;
    box-shadow: 0 0 10px 0 #bbb9b9;
  }

  .list-with-image:hover .list-with-image-content-right-btn span {
    color: #fff;
    background: #008bd5;
  }
}
</style>

<style scoped>
@media screen and (device-width <= 1180px) {
  .list-with-image {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
  }

  .list-with-image-content {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc;
  }

  .list-with-image-content-left {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vw;
  }

  .list-with-image-content-left-image {
    width: 100%;
    height: 60vw;
    transition: all 0.5s ease;
  }

  .list-with-image-content-left-image img {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }
  .list-with-image:hover {
    border-bottom: 1px solid #ccc;
  }

  .list-with-image:hover .list-with-image-content-left-image img,
  .list-with-image:hover .list-with-image-content-right-title {
    transform: translateX(5px);
  }

  .list-with-image-content-left-tag {
    position: absolute;
    top: 0;
    left: 5%;
    width: 17.5vw;
    color: white;
    text-align: center;
    background: #008bd5;
    opacity: 0.9;
  }

  .list-with-image-content-left-tag span {
    display: inline-block;
  }
  .list-with-image-content-left-tag-time {
    display: flex;

    /* flex-direction: column; */
  }

  .list-with-image-content-left-tag-time-dy {
    font-size: 10vw;
    color: #fff;
  }

  .list-with-image-content-left-tag-time-ny {
    width: 100%;
    font-size: 4vw;
  }

  .list-with-image-content-left-tag-read {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 1vw 0;
    font-size: 4vw;
    color: #888;
    background: #fff;
  }

  .list-with-image-content-left-tag-read-eye {
    display: inline-block;
    width: 4vw;
    height: 2.5vw;
    margin-right: 1vw;
    background: url('./image/eye.png') no-repeat;
    background-size: cover;
  }

  .list-with-image-content-right {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 1vw;

    /* justify-content: space-between; */
  }

  .list-with-image-content-right-digest {
    display: -webkit-box; /* 对于旧版WebKit浏览器（如旧版Safari和Android WebView） */
    overflow: hidden; /* 隐藏超出部分 */
    font-size: 4vw;
    line-height: 1.5em;
    color: #8c8c8c;
    text-overflow: ellipsis; /* 用省略号代替超出部分 */
    -webkit-line-clamp: 2; /* 限制显示的行数 */
    word-wrap: break-word; /* 防止长单词或URL无法换行 */
    white-space: normal; /* 允许文本换行 */
    -webkit-box-orient: vertical; /* 设置盒子为垂直方向 */
  }

  .list-with-image-content-right-title {
    margin: 3vw 0;
    overflow: hidden;
    font-size: 4.5vw;
    font-weight: 700;
    line-height: 1.5em;
    color: #333;
    transition: all 0.5s ease;
    -webkit-box-orient: vertical;
  }

  .list-with-image-content-right-btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 2vw;
  }

  .list-with-image-content-right-btn span {
    display: inline-block;
    width: 25vw;
    height: 10vw;
    margin: 3vw 2.5vw 3vw 0;
    font-size: 5vw;
    line-height: 2;
    color: #8c8c8c;
    text-align: center;
    background: #fff;
    box-shadow: 0 0 2.5vw 0 #bbb9b9;
  }

  .list-with-image:hover .list-with-image-content-right-btn span {
    color: #fff;
    background: #008bd5;
  }
}
</style>
