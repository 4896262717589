<template>
  <div class="top-nav-container">
    <div
      :class="{
        'top-nav   top-nav-fixed': props.componentData.fromType === 3,
        'top-nav   top-nav-absolute': props.componentData.fromType !== 3,
      }"
    >
      <div class="top-nav-web">
        <div
          class="top-nav-login-search"
          :style="{
            'background-color': configDataForm.topBackgroundColor,
          }"
        >
          <img :src="configDataForm.topImageFullUrl" fit="cover" />
          <div class="search-link">
            <a
              target="_blank"
              :href="configDataForm.loginLinkUrl"
              class="login"
              :style="{
                color: configDataForm.loginTextColor + ' !important',
              }"
              v-text="configDataForm.loginTextTitle"
            >
            </a>
            <a
              class="search"
              :href="configDataForm.searchLinkUrl"
              :style="{
                color: configDataForm.searchTextColor + ' !important',
              }"
              v-text="configDataForm.searchTextTitle"
            >
            </a>
          </div>
        </div>

        <div
          :class="{
            'top-nav-menu-container': !isDarkLogoStyle && !isBackTopFlag,
            'top-nav-menu-container-deep': isDarkLogoStyle,
            'top-nav-menu-container-deep-home': isBackTopFlag,
          }"
          @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
          @mouseleave="handleShowOperationBtn(false)"
        >
          <div class="top-nav-menu-logo">
            <a :href="indexHrefUrl"
              ><img :src="leftLogoImageUrl" fit="cover"
            /></a>
          </div>
          <div class="top-nav-menu-list">
            <span class="menu-close" @click="handleShowMoreMenu"> </span>
            <span
              v-for="item in columnList"
              :key="item.id"
              :class="{
                active: item.id == activeIndex,
                'top-nav-menu-list-item': true,
              }"
            >
              <a
                v-if="item.children && item.children.length > 0"
                :href="item.children[0].data.hrefURL"
              >
                {{ item.label }}
              </a>
              <a v-else-if="item.data" :href="item.data.hrefURL">
                {{ item.label }}
              </a>
              <div
                v-if="item.children && item.children.length > 0"
                class="sub-menu"
              >
                <ul class="sub-nav">
                  <li></li>
                  <li
                    v-for="subitem in item.children"
                    :key="'su-el' + item.id + '-' + subitem.id"
                  >
                    <a v-if="subitem.data" :href="subitem.data.hrefURL">
                      {{ subitem.label }}
                    </a>
                  </li>
                </ul>
              </div>
            </span>
          </div>

          <div class="top-nav-more-menu" @click="handleShowMoreMenu"></div>
          <client-only>
            <operation-container
              v-if="showOperationBtn && !showEditForm"
              :component-data="props.componentData"
              :show-edit-content-button="props.showEditContentButton"
              :show-edit-components-button="props.showEditComponentsButton"
              :show-delete-components-button="props.showDeleteComponentsButton"
              :show-insert-components-button="props.showInsertComponentsButton"
              :show-sort-components-button="props.showSortComponentsButton"
              @handle-show-edit-form="handleShowEditForm"
              @handle-change-components="handleChangeComponents"
              @handle-delete-components="handleDeleteComponents"
              @handle-insert-components="handleInsertComponents"
              @handle-sort-components="handleSortComponents"
            ></operation-container>

            <el-drawer
              v-model="showEditForm"
              size="60%"
              :title="props.componentData.componentName"
            >
              <top-nav-edit-form
                v-if="showEditForm"
                v-model="configDataFormModel"
                :component-data="props.componentData"
                @handle-show-edit-form="handleShowEditForm"
                @handle-edit-components-content="handleEditComponentsContent"
              ></top-nav-edit-form>
            </el-drawer>
          </client-only>
        </div>
      </div>
      <div class="top-nav-mobile">
        <div
          :class="{
            'top-nav-menu-container': !isDarkLogoStyle && !isBackTopFlag,
            'top-nav-menu-container-deep': isDarkLogoStyle,
            'top-nav-menu-container-deep-home': isBackTopFlag,
          }"
          @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
          @mouseleave="handleShowOperationBtn(false)"
        >
          <div class="top-nav-menu-logo">
            <a :href="indexHrefUrl"
              ><img :src="leftMobileLogoImageUrl" fit="cover"
            /></a>
          </div>
          <div
            class="top-nav-menu-list-mask-layer"
            :class="{ show: showMobileMenuList }"
            @click="handleShowMoreMenu"
          ></div>
          <div class="top-nav-menu-list" :class="{ show: showMobileMenuList }">
            <span
              v-for="item in mobileColumnList"
              :key="item.id"
              :class="{
                active: item.id == activeIndex && item.showSubMenu,
                'top-nav-menu-list-item': true,
              }"
            >
              <a
                v-if="item.children && item.children.length > 0"
                class="menu-item-text"
                @click="handleToggleShowSubMenu(item)"
              >
                {{ item.label }}
              </a>
              <a
                v-else-if="item.data"
                class="menu-item-text-single"
                :href="item.data.hrefURL"
              >
                {{ item.label }}
              </a>
              <div
                v-if="item.children && item.children.length > 0"
                class="sub-menu"
              >
                <ul class="sub-nav">
                  <li
                    v-for="subitem in item.children"
                    :key="'su-el' + item.id + '-' + subitem.id"
                  >
                    <a v-if="subitem.data" :href="subitem.data.hrefURL">
                      {{ subitem.label }}
                    </a>
                  </li>
                </ul>
              </div>
            </span>
          </div>
          <div class="top-nav-more-menu" @click="handleShowMoreMenu"></div>
          <div class="mobile-search">
            <div class="search-link">
              <a
                target="_blank"
                :href="configDataForm.loginLinkUrl"
                class="login"
                v-text="configDataForm.loginTextTitle"
              >
              </a>
              <a
                target="_blank"
                class="search"
                :href="configDataForm.searchLinkUrl"
              >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="top-nav"
      :class="{
        'top-nav-deep': isDarkLogoStyle,
      }"
    ></div>
  </div>
</template>

<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import useSSRInitialStateStore from '@/stores/ssr-state';
import TopNavEditForm from './TopNavEditFormTwo.vue';
import OperationContainer from '../common/OperationContainer.vue';

// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量

// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);

// 创建变量设默认值
const configDataForm = computed(() => {
  return JSON.parse(props.componentData.configData);
});
// 表单数据
const configDataFormModel = ref({});
// 是否移动菜单列表样式
const showMobileMenuList = ref(false);

const route = useRoute();
const ssrInitialState = useSSRInitialStateStore();
// 是否显示深色logo样式的菜单
const isDarkLogoStyle = computed(() => {
  // 默认显示浅色场景
  if (!route) {
    return false;
  }

  const routeName = ['website-home-preview', 'website-home']; // 首页默认显示深色场景
  return !routeName.includes(route.name);
});
// 返回顶部按钮显示
const isBackTopFlag = computed(() => {
  return ssrInitialState.backTopFlag;
});

// 左侧logo资源地址
const leftLogoImageUrl = computed(() => {
  return isDarkLogoStyle.value || ssrInitialState.backTopFlag
    ? configDataForm.value.leftDeepLogoImageFullUrl
    : configDataForm.value.leftLogoImageFullUrl;
});

const leftMobileLogoImageUrl = computed(() => {
  return configDataForm.value.leftLogoImageFullUrl;
});
// web栏目列表
const columnList = computed(() => {
  return configDataForm.value.columnList;
});

const curActiveIndex = ref(null);

// 默认选中key
const activeIndex = computed(() => {
  if (curActiveIndex.value) {
    return curActiveIndex.value;
  }

  if (configDataForm.value.activeIndex) {
    let id = '';
    columnList.value.forEach((element) => {
      element.children.forEach((item) => {
        if (item.id === Number(configDataForm.value.activeIndex) && item.data) {
          id = item.data.parentId;
        }
      });
    });
    return `${id.toString()}`;
  }
  return columnList.value[0].id.toString();
});

// 首页链接
const indexHrefUrl = computed(() => {
  return columnList.value[0].data
    ? columnList.value[0].data.hrefURL
    : columnList.value[0].hrefURL;
});

const mobileColumnList = computed(() => {
  return configDataForm.value.columnList.map((item) => {
    return {
      ...item,
      showSubMenu: item.id === Number(activeIndex.value),
    };
  });
});

// 判断是否是移动端
const isMobile = computed(() => {
  return false;

  // if (import.meta.env.SSR) {
  //   console.log('🚀 ~ isMobile ~ import.meta.env.SSR:', import.meta.env.SSR);
  //   return false;
  // }
  // const userAgent = navigator.userAgent.toLowerCase();
  // return /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(
  //   userAgent,
  // );
});
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 切换子菜单显示状态
 *
 * @param item 子菜单项对象
 */
const handleToggleShowSubMenu = (item) => {
  item.showSubMenu = !item.showSubMenu;
  curActiveIndex.value = item.id.toString();

  if (!item.showSubMenu) {
    curActiveIndex.value = null;
  }
};

/**
 * 显示或隐藏更多菜单。
 *
 * @returns 无返回值。
 */
const handleShowMoreMenu = () => {
  showMobileMenuList.value = !showMobileMenuList.value;
};

// 操作显不显示编辑内容按钮
/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};
/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};
/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataFormModel.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};

// #endregion
</script>
<style lang="scss">
.top-nav-container {
  position: relative;
  width: 100%;

  .top-nav.top-nav-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .top-nav.top-nav-absolute {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }
}
</style>

<style scoped lang="scss">
/* 在屏幕宽度大于等于 1180px 时应用以下样式 */
@media screen and (device-width >= 1180px) {
  // 隐藏移动端菜单
  .top-nav-mobile {
    display: none;
  }
  //显示桌面端菜单
  .top-nav-web {
    display: block;
  }
  .top-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: all 0.3s;

    .top-nav-login-search {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      font-family: SourceHanSansSC, sans-serif;
      font-size: 18px;
      img {
        height: 100%;
      }

      .search-link {
        position: absolute;
        top: 50%; /* 定位到容器顶部的50%位置 */
        right: 80px; /* 定位到容器右侧边缘 */
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transform: translateY(-50%); /* 向上移动自身高度的50%来居中对齐 */

        .login::after {
          margin-right: 30px;
          margin-left: 30px;
          content: '|';
        }
        .search {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 102px;
          height: 40px;
          padding-left: 20px;
          line-height: 32px;
          border: 1px solid #d2d5db;
          border-radius: 20px;
        }

        .search::after {
          width: 20px;
          height: 20px;
          margin-left: 10px;
          color: #fff;
          content: '';
          background: url('image/search-icon.svg') no-repeat center center;
          background-size: cover;
        }
      }
    }
    .top-nav-menu-container-deep,
    .top-nav-menu-container-deep-home,
    .top-nav-menu-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 108px;
      background: url('image/head_bg.png') no-repeat center center;
      background-size: cover;
      transition: all 0.5s ease-in; /* 动画效果，持续0.5秒，缓动函数为ease */

      .top-nav-menu-logo {
        width: 460px;
        height: 80px;
        margin-right: 20px;

        img {
          height: 100%;
          object-fit: cover;
        }
      }
      //导航菜单
      .top-nav-menu-list {
        display: flex;
        flex-wrap: nowrap;
        .top-nav-menu-list-item {
          position: relative;
          display: flex;
          // max-width: 140px;
          flex: auto;
          align-content: center;
          align-items: center;
          padding-bottom: 10px;
          margin: 18px 8px;
          font-family: '微软雅黑 Regular', '微软雅黑', sans-serif;
          font-size: 22px;
          font-style: normal;
          letter-spacing: 1px;
          cursor: pointer;

          a {
            color: #fff;
          }

          // 下级菜单样式
          .sub-menu {
            position: absolute;
            top: 40px;
            left: -9px;
            z-index: 2;
            display: none;
            min-width: 120px;
            background-color: #fff;
            box-shadow: 0 5px 8px rgb(0 0 0 / 10%);
            opacity: 0;
            transition: opacity 0.2s ease-in;

            ul {
              z-index: 10;
              width: 100%;
              li:first-of-type {
                height: 8px;
                background: #87d7e5;
              }

              li:not(:last-of-type) {
                border-bottom: 1px solid #eff1f3;
              }

              li {
                z-index: 1;
                height: 36px;
              }

              li a {
                display: block;
                padding: 0 18px 0 21px;
                overflow: hidden;
                font-size: 18px;
                line-height: 36px;
                color: #000;
                text-align: left;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              li:not(:first-of-type):hover {
                color: #004399;
                background-color: #f6f6f6;
                a {
                  color: #004399;
                }
              }
            }
          }
        }

        .top-nav-menu-list-item.active {
          border-bottom: 4px solid #87d7e5;
        }

        .top-nav-menu-list-item:hover {
          .sub-menu {
            display: block;
            opacity: 1;
          }
        }
      }
    }

    .top-nav-menu-container-deep {
      height: 88px;
      background: #fff;
      box-shadow: 0 8px 18px 0 #e7e6e6;

      .top-nav-menu-logo {
        width: 400px;
        height: 60px;
        margin-right: 0;

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .top-nav-menu-list {
        .top-nav-menu-list-item {
          a {
            color: #373737;
          }
        }
      }
    }

    .top-nav-menu-container-deep-home {
      height: 108px;
      background: #fff;

      .top-nav-menu-logo {
        width: 460px;
        height: 80px;

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .top-nav-menu-list {
        .top-nav-menu-list-item {
          a {
            color: #373737;
          }
        }
      }
    }

    .top-nav-more-menu {
      display: none;
    }
  }

  .top-nav-deep {
    height: 152px;
  }
}

/* 在屏幕宽度小于等于 1180px 时应用以下样式 */
@media screen and (device-width <= 1180px) {
  // 显示移动端菜单
  .top-nav-mobile {
    display: block;
  }
  //隐藏桌面端菜单
  .top-nav-web {
    display: none;
  }
  .top-nav {
    width: 100% !important;
    min-width: auto !important;
    transition: all 0.3s;

    .top-nav-login-search {
      display: none;
    }

    .top-nav-menu-container-deep,
    .top-nav-menu-container-deep-home,
    .top-nav-menu-container {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      height: 16vw;
      margin: 0;
      background: url('image/head_bg.png') no-repeat center center;
      background-size: cover;
      transition: all 0.5s ease-in; /* 动画效果，持续0.5秒，缓动函数为ease */

      .top-nav-menu-logo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 2vw;
        img {
          height: 8vw;
          margin-left: 2vw;
          object-fit: cover;
        }
      }

      .top-nav-menu-list-mask-layer {
        display: none;
        width: 100%;
        height: 100vh;
        background-color: #666;
        opacity: 0.6;
      }

      .top-nav-menu-list-mask-layer.show {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9998;
        display: block;
      }

      .top-nav-menu-list {
        display: none;
      }

      .top-nav-menu-list.show {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        width: 50vw;
        height: 100vh;
        overflow-y: auto;
        background-color: #194294;
        animation-name: fadeInTop;
        animation-duration: 0.2s;

        .menu-close {
          display: flex;
          width: 10vw;
          height: 10vw;
          margin-right: 2vw;
          margin-left: auto;
          background: url('image/nav-close.png') no-repeat center center;
          background-size: 100%;
        }

        .top-nav-menu-list-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 4.5vw;
          border-bottom: 0.25vw solid #0c327e;

          //

          // .menu-item-icon {
          //   width: 6vw;
          //   height: 6vw;
          //   margin: 2vw;
          //   img {
          //     width: 100%;
          //     height: 100%;
          //   }
          // }

          .menu-item-text-single {
            position: relative;
            display: flex;
            align-items: center;
            height: 18vw;
            font-weight: 700;
            color: #fff;
          }

          .menu-item-text {
            position: relative;
            display: flex;
            align-items: center;
            height: 18vw;
            font-weight: 700;
            color: #fff;
          }

          .menu-item-text::after {
            position: relative;
            left: 20px;
            display: inline-block;
            width: 8px;
            height: 8px;
            content: '';
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transform: rotate(45deg);
          }

          // 下级菜单样式
          .sub-menu {
            display: none;
            align-items: center;
            justify-content: center;
            width: 50vw;
            color: #fff;
            background-color: #0c327e;

            ul {
              width: 50vw;
              li:not(:last-of-type) {
                border-bottom: 1px solid #2e4f90;
              }

              li {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 18vw;
                margin: 0 8vw;
              }
            }
          }
        }

        .top-nav-menu-list-item.active {
          .sub-menu {
            display: flex;
          }

          border-bottom: 0.25vw solid #22cbf2;
          .menu-item-text::after {
            position: relative;
            left: 20px;
            display: inline-block;
            width: 8px;
            height: 8px;
            content: '';
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transform: rotate(-135deg);
          }
        }
      }

      .top-nav-more-menu {
        display: flex;
        width: 10vw;
        height: 10vw;
        margin-right: 2vw;
        margin-left: auto;
        background: url('image/top-nav-menu-white.png') no-repeat center center;
        background-size: 100%;
      }

      .mobile-search {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .search-link {
          position: absolute;
          top: 50%; /* 定位到容器顶部的50%位置 */
          right: 16vw; /* 定位到容器右侧边缘 */
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transform: translateY(-50%); /* 向上移动自身高度的50%来居中对齐 */
          .login {
            width: 14vw;
            color: #fff;
          }

          .login::after {
            margin-right: 2vw;
            margin-left: 2vw;
            content: '|';
          }
          .search {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 10vw;
            height: 7vw;
            border: 0.2vw solid #d2d5db;
            border-radius: 4vw;
          }

          .search::after {
            width: 4vw;
            height: 4vw;
            margin-left: 3vw;
            color: #fff;
            content: '';
            background: url('image/search-icon.svg') no-repeat center center;
            background-size: cover;
          }
        }
      }
    }

    .top-nav-menu-container-deep {
      background: #194294;
    }

    .top-nav-menu-container-deep-home {
      background: #194294;
    }
  }

  .top-nav-deep {
    height: 16vw;
  }

  /* 从左到右进入 */
  /* stylelint-disable-next-line keyframes-name-pattern */
  @keyframes fadeInTop {
    from {
      opacity: 0;
      /* stylelint-disable-next-line function-no-unknown */
      transform: stranslate(0, -200vw);
    }

    to {
      opacity: 1;
      /* stylelint-disable-next-line function-no-unknown */
      transform: stranslate(0, 0);
    }
  }
}
</style>
