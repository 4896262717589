<template>
  <div>
    <div
      class="image-text-container"
      @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
      @mouseleave="handleShowOperationBtn(false)"
    >
      <div
        v-for="(item, index) in configDataForm"
        :key="index"
        class="parent-item"
      >
        <span class="title">{{ item.title }}</span>
        <div class="sub-items-container">
          <div
            v-for="(subItem, subIndex) in item.items"
            :key="subIndex"
            class="sub-item"
          >
            <a :href="subItem.linkUrl">{{ subItem.subTitle }}</a>
          </div>
        </div>
      </div>

      <client-only>
        <operation-container
          v-if="showOperationBtn && !showEditForm"
          :component-data="props.componentData"
          :show-edit-content-button="props.showEditContentButton"
          :show-edit-components-button="props.showEditComponentsButton"
          :show-delete-components-button="props.showDeleteComponentsButton"
          :show-insert-components-button="props.showInsertComponentsButton"
          :show-sort-components-button="props.showSortComponentsButton"
          @handle-show-edit-form="handleShowEditForm"
          @handle-change-components="handleChangeComponents"
          @handle-delete-components="handleDeleteComponents"
          @handle-insert-components="handleInsertComponents"
          @handle-sort-components="handleSortComponents"
        ></operation-container>
        <el-drawer
          v-model="showEditForm"
          size="40%"
          :title="props.componentData.componentName"
        >
          <image-text-edit-form
            v-if="showEditForm"
            v-model="configDataForm"
            :component-data="props.componentData"
            @handle-show-edit-form="handleShowEditForm"
            @handle-edit-components-content="handleEditComponentsContent"
          ></image-text-edit-form>
        </el-drawer>
      </client-only>
    </div>
  </div>
</template>

<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import OperationContainer from '../common/OperationContainer.vue';
import ImageTextEditForm from './ImageTextEditFormNineteen.vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量

// 表单绑定对象
const configDataForm = ref([
  {
    title: '示例标题1',
    fontColor: '#333333',
    items: [
      { subTitle: '示例子项标题1', linkUrl: '' },
      { subTitle: '示例子项标题2', linkUrl: '' },
    ],
  },
  {
    title: '示例标题2',
    fontColor: '#333333',
    items: [
      { subTitle: '示例子项标题1', linkUrl: '' },
      { subTitle: '示例子项标题2', linkUrl: '' },
    ],
  },
]);

if (props.componentData.configData) {
  // 服务器渲染的直接调用
  configDataForm.value = JSON.parse(props.componentData.configData);
}

// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法
/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};
/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};
/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // console.log('configDataForm--1:', configDataForm);
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};

// #endregion
</script>

<style scoped lang="scss">
@media screen and (device-width >= 1180px) {
  .image-text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: center;
    width: 100%;
    padding: 15px;

    .parent-item {
      display: block;
      width: 100%;

      .title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        padding-left: 20px;
        font-size: 16px;
        font-weight: 700;
        background-color: #f2f2f2;
        border-left: 4px solid var(--el-color-primary);
      }

      .sub-items-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 15px 0;

        .sub-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25%;
          height: 45px;

          a {
            font-size: 14px;
            color: #333;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media screen and (device-width <= 1180px) {
  .image-text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: center;
    width: 100%;
    padding: 3vw;

    .parent-item {
      display: block;
      width: 100%;

      .title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 10vw;
        padding-left: 3vw;
        font-size: 4vw;
        font-weight: 700;
        background-color: #f2f2f2;
        border-left: 1vw solid var(--el-color-primary);
      }

      .sub-items-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 3vw 0;

        .sub-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 33.33%;
          height: 12vw;

          a {
            font-size: 3.5vw;
            color: #333;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
