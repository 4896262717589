<template>
  <ul class="article-list">
    <li
      v-for="(item, index) in props.contentList"
      :key="index"
      class="list-item"
    >
      <a :href="item.url">
        <span class="title">{{ item.title }}</span>
        <span class="time">{{
          item.publishTime ? formatDate(item.publishTime) : item.publishTime
        }}</span>
      </a>
    </li>
  </ul>
</template>
<script setup>
// #region 引用
import { formatDate } from '@/utils/common/date-util';
// #endregion

// #region props/emit
const props = defineProps({
  contentList: {
    type: Array,
    default: () => [],
  },
});
// #endregion

// #region 变量/常量

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

// #endregion
</script>

<style scoped>
@media screen and (device-width >= 1180px) {
  .article-list {
    flex-grow: 1;
  }

  .article-list .list-item {
    font-size: 16px;
    line-height: 42px;
    text-align: left;
    background: url('./image/spot.png') no-repeat left center;
    border-bottom: 1px dotted #ccc;
  }

  .article-list .list-item a {
    display: block;
    width: 100%;
    overflow: hidden;
  }

  .article-list .list-item a .title {
    float: left;
    margin-left: 10px;
    overflow: hidden;
    font-size: 1em;
    font-weight: 700;
    line-height: 42px;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  .article-list .list-item a .title:hover {
    text-decoration: underline;
  }

  .article-list .list-item a .time {
    float: right;
    color: #666;
    cursor: auto;
  }

  .article-list .list-item::after {
    clear: both;
  }
}
</style>

<style scoped>
@media screen and (device-width <= 1180px) {
  .article-list {
    /* width: calc(100% - 13vw); */

    /* margin: 0 1vw; */
  }

  .article-list .list-item {
    line-height: 7vh;
    text-align: left;
    background: url('./image/spot.png') no-repeat left center;
    border-bottom: 1px dotted #ccc;
  }

  .article-list .list-item a {
    display: flex;
    font-size: 4vw;
  }

  .article-list .list-item a .title {
    flex: 1;
    margin-right: 1em;
    margin-left: 1em;
    overflow: hidden;
    font-weight: 700;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  .article-list .list-item a .time {
    margin-left: auto;
    color: #666;
  }

  /* .article-list .list-item::after {
    clear: both;
  } */
}
</style>
