<template>
  <div>
    <div
      class="image-text-container"
      :style="{
        backgroundImage: `url(${configDataForm.backGroundImageFullUrl})`,
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        'background-position': 'center',
      }"
      @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
      @mouseleave="handleShowOperationBtn(false)"
    >
      <div class="title">
        <div class="title-icon">
          <img :src="iconFileFullUrl" fit="cover" />
        </div>
        <div
          class="title-text"
          :style="{
            color: configDataForm.fontColor,
          }"
        >
          {{ configDataForm.title }}
        </div>
      </div>
      <div class="content">
        <div
          v-for="(item, index) in configDataForm.items"
          :key="index"
          class="service-item"
        >
          <a target="_blank" :href="item.linkUrl">
            <div
              class="service-item-icon"
              :style="{
                background: item.backGroundColor,
                'border-color': item.backGroundColor,
              }"
            >
              <img :src="item.itemFileFullUrl" fit="cover" />
            </div>
            <div
              class="service-item-title"
              :style="{
                color: item.fontColor,
              }"
            >
              {{ item.title }}
            </div>
          </a>
        </div>
      </div>
      <client-only>
        <operation-container
          v-if="showOperationBtn && !showEditForm"
          :component-data="props.componentData"
          :show-edit-content-button="props.showEditContentButton"
          :show-edit-components-button="props.showEditComponentsButton"
          :show-delete-components-button="props.showDeleteComponentsButton"
          :show-insert-components-button="props.showInsertComponentsButton"
          :show-sort-components-button="props.showSortComponentsButton"
          @handle-show-edit-form="handleShowEditForm"
          @handle-change-components="handleChangeComponents"
          @handle-delete-components="handleDeleteComponents"
          @handle-insert-components="handleInsertComponents"
          @handle-sort-components="handleSortComponents"
        ></operation-container>
        <el-drawer
          v-model="showEditForm"
          size="40%"
          :title="props.componentData.componentName"
        >
          <image-text-edit-form
            v-if="showEditForm"
            v-model="configDataForm"
            :component-data="props.componentData"
            @handle-show-edit-form="handleShowEditForm"
            @handle-edit-components-content="handleEditComponentsContent"
          ></image-text-edit-form>
        </el-drawer>
      </client-only>
    </div>
  </div>
</template>
<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import OperationContainer from '../common/OperationContainer.vue';
import ImageTextEditForm from './ImageTextEditFormSixteen.vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

// console.log('props-configDataForm:',props);

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量

// 表单绑定对象
const configDataForm = ref({
  contentImageObj: null,
  imageName: '',
  imageFileKey: 'contentImage',
  backGroundImageObj: null,
  backgroundImageName: '',
  backgroundImageFileKey: 'backgroundImage',
  title: '示例标题',
  content: '示例内容',
  textColor: '#ffffff',
  backgroundColor: '#008bd5',
});

if (props.componentData.configData) {
  // 服务器渲染的直接调用
  configDataForm.value = JSON.parse(props.componentData.configData);
  configDataForm.value.items.sort((a, b) => {
    return a.sortNum - b.sortNum;
  });
}

// 图标
const iconFileFullUrl = computed(() => {
  return configDataForm.value ? configDataForm.value.iconFullUrl : '';
});

// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法
/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};
/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};
/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // console.log('configDataForm--1:', configDataForm);
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};

// #endregion
</script>
<style scoped lang="scss">
@media screen and (device-width >= 1180px) {
  .image-text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: center;
    width: 100%;
    padding: 40px 100px;

    .title {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      height: 60px;
      padding-bottom: 20px;
      border-bottom: 1px solid #cfd0c5;
      .title-icon {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }

      .title-text {
        flex-grow: 1;
        height: 40px;
        margin-bottom: 20px;
        font-size: 36px;
        line-height: 40px;
      }
    }
    .content {
      display: flex;
      flex-grow: 1;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 20px 0 0;
      .service-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 220px;
        height: 280px;
        margin: 10px 60px;
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          height: 280px;
        }
        .service-item-icon {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 160px;
          height: 160px;
          cursor: pointer;
          border-radius: 50%;

          img {
            width: 66px;
            height: 66px;
            object-fit: cover;
          }
        }

        .service-item-icon::before {
          position: absolute;
          inset: -20px;
          content: '';
          border: 8px dashed #0d47a1; /* 外边框虚线的颜色 */
          border-radius: 50%;
        }

        .service-item-icon::after {
          position: absolute;
          inset: 5px;
          content: '';
          border-radius: 50%;
        }

        .service-item-title {
          margin-top: 10px;
          font-size: 18px;
          font-weight: 700;
          text-align: center;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media screen and (device-width <= 1180px) {
  .image-text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 4vw;
    background-color: #fff !important;
    background-image: none !important;

    .title {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      height: 12.5vw;
      padding-bottom: 4vw;
      .title-icon {
        display: flex;
        align-content: center;
        align-items: center;
        font-size: 7.5vw;
        img {
          width: 7.5vw;
          height: 7.5vw;
          margin-right: 2vw;
          object-fit: cover;
        }
      }

      .title-text {
        flex-grow: 1;
        height: 7.5vw;
        font-size: 7.5vw;
        line-height: 7.5vw;
      }
    }
    .content {
      display: flex;
      flex-grow: 1;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 92vw;
      .service-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 22vw;
        height: 30vw;
        margin-right: 1vw;
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          height: 28vw;
        }
        .service-item-icon {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 13vw;
          height: 13vw;
          cursor: pointer;
          border-radius: 50%;

          img {
            width: 7.5vw;
            height: 7.5vw;
            object-fit: cover;
          }
        }

        .service-item-icon::before {
          position: absolute;
          inset: -4vw;
          content: '';
          border: 1vw dashed #0d47a1; /* 外边框虚线的颜色 */
          border-radius: 50%;
        }

        .service-item-icon::after {
          position: absolute;
          inset: 1vw;
          content: '';
          border-radius: 50%;
        }

        .service-item-title {
          margin-top: 2vw;
          font-size: 3.75vw;
          font-weight: 700;
          text-align: center;
        }
      }
    }
  }
}
</style>
