<!--
 * @description: 文件上传组件（基于el-upload、el-tooltip、el-image-viewer、van-popup二次封装，用于单图、图片集、附件集上传）
 * @Author: HZH
 * @Date: 2022-11-07
 * @remark：--
 -->
<template>
  <el-upload
    ref="refElUpload"
    v-bind="$attrs"
    v-model:file-list="fileList"
    action="#"
    list-type="picture-card"
    :class="'zc-upload ' + mainClass + handleUploadBtnClass"
    :accept="curAccept"
    :limit="curLimit"
    :multiple="curMultiple"
    :auto-upload="false"
    @change="handleChange"
    @exceed="handleExceed"
  >
    <el-icon v-if="type == 'singleImage'">
      <Plus />
    </el-icon>
    <div v-if="type == 'images'" class="input-container">
      <i class="input-ico"></i>
      <span v-if="!isMobile">选择图片</span>
    </div>
    <div v-if="type == 'attachments'" class="input-container">
      <i class="input-ico"></i>
      <span v-if="!isMobile">选择文件</span>
    </div>
    <template #tip>
      <div v-if="slots.tip" class="upload-tip-container">
        <slot name="tip"></slot>
      </div>
      <div v-else-if="tip" class="el-upload__tip">{{ tip }}</div>
    </template>
    <template #file="{ file }">
      <el-tooltip
        :placement="placement"
        effect="light"
        popper-class="file-view-tooltip bottom-start"
        :hide-after="100"
        :disabled="isMobile || !enablePopup"
      >
        <div
          v-if="type == 'attachments' && isMobile"
          class="attachment-item-container"
        >
          <i
            :class="'ico ' + file.attachmentItemClass"
            title="点击查看"
            @click="handlePreview(file)"
          ></i>
          <span
            :class="'file-name ' + mobileAttItemNoDel"
            @click="handlePreview(file)"
            >{{ file.name }}</span
          >
          <i v-if="operateDeleteFlag" class="del" @click="handleRemove(file)"
            >删除</i
          >
        </div>
        <i
          v-else-if="type == 'attachments' && !isMobile"
          :class="file.attachmentItemClass"
          title="点击查看"
          @click="handlePreview(file)"
          @mouseenter="handleMouseEnter"
        />
        <div
          v-else-if="type == 'images' && isMobile"
          class="image-item-container"
        >
          <el-image
            fit="cover"
            :src="file.url"
            alt=""
            title="点击预览"
            @click="handlePreview(file)"
          />
          <i v-if="operateDeleteFlag" class="del" @click="handleRemove(file)"
            >×</i
          >
        </div>
        <el-image v-else-if="type == 'singleImage' && showNoImage && noImage">
          <template #error>
            <div :class="imageSlotClass">未上传</div>
          </template>
        </el-image>
        <el-image
          v-else
          fit="cover"
          :src="file.url"
          alt=""
          title="点击预览"
          @click="handlePreview(file)"
          @mouseenter="handleMouseEnter"
        >
          <template #placeholder>
            <div :class="imageSlotClass">
              Loading<span class="dot">...</span>
            </div>
          </template>
          <template #error>
            <div :class="imageSlotClass">
              <slot
                v-if="slots.singleImageError"
                name="singleImageError"
              ></slot>
              <span v-else>图片丢失</span>
            </div>
          </template>
        </el-image>
        <template #content>
          <div class="tooltip-row-file">
            <span class="col-file-name">{{ file.name }}</span>
            <span v-if="showFileSize" class="col-file-size">{{
              formatSizeDisplay(file.size)
            }}</span>
            <span
              v-if="showUploadStatus && !file.operateInfo"
              class="col-file-status"
              >待上传</span
            >
            <span
              v-else-if="showUploadStatus && !file.fileExists"
              class="col-file-status no-exists"
              >文件未上传成功</span
            >
          </div>
          <div v-if="file.operateInfo" class="tooltip-row-user">
            <span class="col-user-name">{{ file.operateInfo.userName }}</span>
            <span class="col-user-handle">上传于</span>
            <span class="col-user-time">{{ file.operateInfo.time }}</span>
          </div>
          <div class="tooltip-row-operate">
            <el-space>
              <el-link
                v-if="operatePreviewFlag && !file.hidPreview"
                type="primary"
                @click="handlePreview(file)"
                >预览</el-link
              >
              <el-link
                v-if="operateDownloadFlag && !file.hidDownload"
                type="primary"
                @click="handleDownload(file)"
                >下载
              </el-link>
              <el-link
                v-if="operatePrintFlag && !file.hidPrint"
                type="primary"
                @click="handlePrint(file)"
                >打印
              </el-link>
              <el-link
                v-if="operateUpdateFlag && !file.hidUpdate"
                type="primary"
                @click="handleUpdate(file)"
                >更新上传</el-link
              >
              <el-link
                v-if="operateDeleteFlag"
                type="danger"
                @click="handleRemove(file)"
                >删除</el-link
              >
            </el-space>
          </div>
        </template>
      </el-tooltip>
    </template>
  </el-upload>

  <el-image-viewer
    v-if="previewImageFlag"
    :teleported="true"
    :z-index="9999"
    :url-list="previewImageList"
    :initial-index="imageViewerInitIndex"
    @switch="imageViewerSwitch"
    @close="imageViewerClose"
  />
</template>

<script setup>
// #region 引用
import { genFileId } from 'element-plus';
import { Plus } from '@element-plus/icons-vue';
import guid from '@/utils/common/guid';
import * as conversions from 'image-conversion';
import CryptoJS from 'crypto-js';
// #endregion

// #region props/emit
const props = defineProps({
  // 类型：singleImage（单图）、images（图片集）、attachments（附件集）
  type: {
    type: String,
    default: 'singleImage',
  },
  // 建议提示（字符串格式，或通过插槽#tip传入自定义内容）
  tip: {
    type: String,
    default: '',
  },
  // 限制数量（单图时强制为1，限制数量为1时，则multiple强制为false，且重新选择文件后会直接覆盖上一个已选择的文件）
  limit: {
    type: Number,
    default: 0,
  },
  // 限制文件格式（单图/图片集默认：image/*；附件集默认：.txt,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.zip,.rar,image/*）
  accept: {
    type: String,
    default: '',
  },
  // 限制单个文件大小（字节），对于单图或图片集，一般建议是限制单个图片大小，以对图片进行压缩处理
  limitSingleSize: {
    type: Number,
    default: 0,
  },
  // 限制所有文件总大小（字节），对于单图或图片集，一般建议是限制单个图片大小，以对图片进行压缩处理
  limitAllSize: {
    type: Number,
    default: 0,
  },
  // 单张图片压缩大小（字节）
  compressImageSize: {
    type: Number,
    default: 0,
  },
  // 单图或图片集的缩略图尺寸，默认单图px80*80px、图片集80px*60px（仅适用于极少特殊业务，不建议轻易调整）
  imageThumbnailWidth: {
    type: Number,
    default: 80,
  },
  imageThumbnailHeight: {
    type: Number,
    default: 80,
  },
  // 是否显示上传按钮（常用于列表显示文件集、详情页展示文件集等只读场景）
  showUploadButton: {
    type: Boolean,
    default: true,
  },
  // 是否在列表中作为详情显示（主要用在列表中显示图片集/附件集，控制间距）
  inListDetail: {
    type: Boolean,
    default: false,
  },
  // 是否显示删除按钮（移动端）
  mobileShowDeleteButton: {
    type: Boolean,
    default: true,
  },
  // 是否允许弹出层效果
  enablePopup: {
    type: Boolean,
    default: true,
  },
  // 是否支持多选（单图或limit为1时强制为否）
  multiple: {
    type: Boolean,
    default: true,
  },
  // 自定义显示操作项：preview（查看）,download（下载）,print（打印）,update（更新）,delete（删除）
  operateItems: {
    type: String,
    default: 'preview,download,delete',
  },
  // 是否显示文件大小
  showFileSize: {
    type: Boolean,
    default: true,
  },
  // 是否显示文件上传状态
  showUploadStatus: {
    type: Boolean,
    default: true,
  },
  // 是否自动控制删除（如果自动控制，则删除时不会直接移除文件，而是由自定义delete事件触发）
  customControlDelete: {
    type: Boolean,
    default: false,
  },
  // 是否自动控制打印（如果自动控制，则打印时不会直接跳转打印页面，而是由自定义print事件触发）
  customControlPrint: {
    type: Boolean,
    default: false,
  },
  // 是否移动端
  isMobile: {
    type: Boolean,
    default: false,
  },
  // 预览附件地址
  previewAttachmentUrl: {
    type: String,
    default: '/file-view/attachment',
  },
  // 打印附件地址
  printAttachmentUrl: {
    type: String,
    default: '/xxx',
  },
  // 下载附件地址
  downloadAttachmentUrl: {
    type: String,
    default: `${import.meta.env.VITE_API_URL}/file/resources/download`,
  },
  // 是否显示无图的效果
  showNoImage: {
    type: Boolean,
    default: false,
  },
  // 是否使用小的文本
  smallText: {
    type: Boolean,
    default: false,
  },
  // v-model 绑定值
  modelValue: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits([
  'change',
  'delete',
  'update',
  'print',
  'update:modelValue',
]);
// #endregion

// #region 变量/常量
const popupShow = ref(false);
const previewAttachmentMobileSrc = ref('');
const previewAttachmentFileName = ref('');

let handlingFileQty = 0;
let imageConversion = null;

// 定义代理对象

// elUpload引用
const refElUpload = ref();
// 获取插槽
const slots = useSlots();
// 重定义响应式数据
const fileList = ref(props.modelValue);
// 无图
const noImage = ref(false);
// 图片插槽样式
const imageSlotClass = computed(() =>
  props.smallText ? 'el-image__error small-text' : 'el-image__error',
);

// el-tooltip的呈现方向
const placement = ref('bottom');
// 是否预览图片
const previewImageFlag = ref(false);
// 预览图片数据列表
const previewImageList = ref([]);
// 预览图片初始化页码
const imageViewerInitIndex = ref(0);
// 文件类型字符
const fileTypeWord = props.type === 'attachments' ? '文件' : '图片';
// 附加类名
const mainClass = ref(
  props.type === 'singleImage' ? 'single-image' : `${props.type} collection`,
);
if (props.isMobile) {
  mainClass.value += ' mobile';
}
/**
 * 操作项相关类名（用于控制操作项的显示与隐藏）
 */
const handleUploadBtnClass = computed(() => {
  return props.showUploadButton ? '' : ' hide-upload-btn';
});
// 操作项相关
const operateItemsArr = props.operateItems.split(',');
const operatePreviewFlag = ref(operateItemsArr.some((x) => x === 'preview'));
const operateDownloadFlag = ref(operateItemsArr.some((x) => x === 'download'));
const operatePrintFlag = ref(operateItemsArr.some((x) => x === 'print'));
const operateDeleteFlag = ref(operateItemsArr.some((x) => x === 'delete'));
// 移动端附件集项没有删除按钮时的样式
const mobileAttItemNoDel = ref('');
// 移动端的删除按钮手动控制
if (!props.mobileShowDeleteButton) {
  operateDeleteFlag.value = false;
  mobileAttItemNoDel.value = 'no-del';
}
// 是否修改标识
const operateUpdateFlag = ref(false);
if (props.operateItems && operateItemsArr.some((x) => x === 'update')) {
  operateUpdateFlag.value = true;
}
// 限制数量、是否多选、接收类型控制
const curLimit = ref(props.type === 'singleImage' ? 1 : props.limit);
if (curLimit.value === 0) {
  curLimit.value = 999;
}
const curMultiple = ref(
  props.type === 'singleImage' || curLimit.value === 1 ? false : props.multiple,
);
const curAccept = ref();
if (props.accept) {
  curAccept.value = props.accept.toString();
} else if (props.type === 'singleImage' || props.type === 'images') {
  curAccept.value = 'image/*'; // 常见：.png,.jpg,.webp,.bmp,.jpeg,.mpeg
} else if (props.type === 'attachments') {
  curAccept.value =
    '.txt,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.zip,.rar,image/*'; // 所有：*
}
// 缩略图宽高控制
const thisimagethumbnailwidth = ref(`${props.imageThumbnailWidth}px`);
const thisimagethumbnailheight = ref(`${props.imageThumbnailHeight}px`);
if (props.type !== 'attachments') {
  if (!thisimagethumbnailwidth.value) {
    thisimagethumbnailwidth.value = `${80}px`;
  }
  if (!thisimagethumbnailheight.value) {
    if (props.type === 'images') {
      thisimagethumbnailheight.value = `${60}px`;
    } else {
      thisimagethumbnailheight.value = `${80}px`;
    }
  }
}
// 缩略图间距控制
const itemsmarginbottom = ref(`${8}px`);
const uploadMainMarginTop = ref(`${0}px`);
if (!props.tip && !props.isMobile) {
  itemsmarginbottom.value = `${0}px`;
}
if (props.inListDetail) {
  itemsmarginbottom.value = `${8}px`;
  uploadMainMarginTop.value = `${8}px`;
}

const collectionbtncontainerwidth = ref(`${110}px`); // 附件集项的宽度
if (props.isMobile) {
  collectionbtncontainerwidth.value = `${60}px`;
}
const attachmentitemheight = ref(`${60}px`); // 附件集项的高度
if (props.isMobile) {
  attachmentitemheight.value = `${40}px`;
}

// #endregion

// #region 业务方法

/**
 * 获取文件名后缀
 * @param {string} fileName 文件名
 */
function getFileExtension(fileName) {
  return fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
}

/**
 * 根据文件名得到缩略图项的类名
 * @param {string} fileName 文件名
 */
function getAttachmentItemClass(fileName) {
  const fileExtension = getFileExtension(fileName);
  let result = 'file-other';
  switch (fileExtension) {
    case '.doc':
    case '.docx':
      result = 'file-word';
      break;
    case '.xls':
    case '.xlsx':
      result = 'file-excel';
      break;
    case '.ppt':
    case '.pptx':
      result = 'file-ppt';
      break;
    case '.txt':
      result = 'file-txt';
      break;
    case '.pdf':
      result = 'file-pdf';
      break;
    case '.rar':
      result = 'file-rar';
      break;
    case '.zip':
      result = 'file-zip';
      break;
    case '.jpg':
    case '.jpeg':
    case '.tif':
    case '.tiff':
    case '.png':
    case '.gif':
    case '.bmp':
    case '.webp':
    case '.wmf':
    case '.raw':
    case '.dif':
    case '.cdr':
    case '.psd':
    case '.eps':
    case '.tga':
    case '.pcd':
    case '.pcp':
    case '.mpt':
    case '.iff':
      result = 'file-img';
      break;
    case '.avi':
    case '.rm':
    case '.rmvb':
    case '.wmv':
    case '.mov':
    case '.flv':
    case '.mpg':
    case '.mpe':
    case '.mpeg':
    case '.vob':
    case '.asf':
    case '.divx':
    case '.dat':
    case '.ahd':
    case '.3gp':
    case '.mkv':
    case '.mp4':
      result = 'file-video';
      break;
    default:
      result = 'file-other';
      break;
  }
  return result;
}

/**
 * 根据文件名后缀获取自定义类型：0不支持预览、1图片预览、2 office/pdf/txt/rar/zip/7z预览
 * @param {string} fileExtension 文件后缀
 */
function checkFileExtensionViewType(fileExtension) {
  if (!fileExtension) {
    return 0;
  }

  const extensionMap = {
    // 图像文件
    jpg: 1,
    jpeg: 1,
    tif: 1,
    tiff: 1,
    png: 1,
    gif: 1,
    bmp: 1,
    webp: 1,
    wmf: 1,
    raw: 1,
    dif: 1,
    cdr: 1,
    psd: 1,
    eps: 1,
    tga: 1,
    pcd: 1,
    pcp: 1,
    mpt: 1,
    iff: 1,
    // 文档和压缩文件
    doc: 2,
    docx: 2,
    xls: 2,
    xlsx: 2,
    ppt: 2,
    pptx: 2,
    pdf: 2,
    txt: 2,
    rar: 2,
    zip: 2,
    '7z': 2,
    // 视频文件
    mp4: 3,
  };

  const lowerCaseExtension = fileExtension.toLowerCase().replace('.', '');
  const viewType = extensionMap[lowerCaseExtension] || 0; // 如果映射中不存在，返回0

  return viewType;
}

/**
 * 触发父组件回调
 * @param {array} files
 * @param {object} file
 */
const handleEmit = (files, file) => {
  let result = files;
  if (props.type === 'singleImage') {
    const [firstItem] = result;
    result = firstItem;
  }
  emit('update:modelValue', result);
  emit('change', file);
};

/**
 * 获取文件未带有后缀的名称
 * @param {string} fileName 文件名
 */
function getFileBaseName(fileName) {
  return fileName.substring(0, fileName.lastIndexOf('.'));
}

/**
 * 处理同名文件、后缀等
 * @param {object} file 当前文件
 * @param {array} files 已存在的文件组
 * @param {boolean} complementTimeSuffixFlag 是否补全文件名的时间后缀，默认false
 */
function handleFileName(file, files, complementTimeSuffixFlag) {
  let fileBaseName = getFileBaseName(file.name);
  const fileExtension = getFileExtension(file.name);
  // 将文件名中的指定特殊符号进行替换
  fileBaseName = fileBaseName
    .replace(/&|\\|\/|\*|<|>|\|%|:/g, '-')
    .replace(/:/g, '：')
    .replace(/\?/g, '？');

  // 限定Unicode字符集范围，以保证文件名可以正常存入数据库
  let newName = '';
  for (let i = 0; i < fileBaseName.length; i += 1) {
    // 限定Ascii码127以下的常用符号
    const asciiNum = fileBaseName[i].charCodeAt(0);
    // 限定unicode字符集范围
    let reg;
    reg +=
      '[\u2000-\u206F]|[\u20A0-\u20CF]|[\u2200-\u22FF]|[\u2150-\u218F]|[\u2500-\u257F]|[\u2580-\u259F]'; // 基础字符
    reg += '|';
    reg += '[\uFF00-\uFF5E]|[\uFFE0-\uFFE5]'; // 全角ASCII、全角中英文标点
    reg += '|';
    reg += '[\u3000-\u3003]|[\u3005-\u3017]|[\u301D-\u301E]|[\u3021-\u3029]'; // CJK标点符号
    reg += '|';
    reg += '[\u3100-\u3129]'; // 注音符号(Based on GB 2312)
    reg += '|';
    reg += '[\u3040-\u3093]|[\u309D-\u309E]'; // 日文平假名
    reg += '|';
    reg += '[\u30A1-\u30F5]|[\u30FC-\u30FE]'; // 日文片假名
    reg += '|';
    reg += '[\u31F0-\u31FF]'; // 日文片假名拼音扩展 - 不支持
    reg += '|';
    reg += '[\uFE10-\uFE1F]'; // 中文竖排标点
    reg += '|';
    reg += '[\uFE30-\uFE31]|[\uFE33-\uFE44]|[\uFE49-\uFE4F]'; // CJK兼容符号（竖排变体、下划线）

    const regExp = new RegExp(reg, 'g');

    if (
      fileBaseName[i].match(
        /[x80-xFF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g,
      ) ||
      fileBaseName[i].match(regExp) ||
      asciiNum <= 127
    ) {
      newName += fileBaseName[i];
    } else {
      newName += '？';
    }
  }
  // 处理时间后缀
  if (complementTimeSuffixFlag) {
    newName = `${newName}-${new Date()
      .format('yyyy-MM-dd HH:mm:ss')
      .replace(/-/g, '')
      .replace(/ /g, '')
      .replace(/:/g, '')}`;
  }
  // 处理唯一文件名
  let retName = newName + fileExtension;
  let j = 1;
  files.forEach((item) => {
    if (file.uid !== item.uid && item.name === retName) {
      j += 1;
      retName = `${newName}(${j})${fileExtension}`;
    }
  });
  return retName;
}

/**
 * 通过accept限制验证文件类型
 * @param {string} accept 接收类型
 * @param {File} file 文件对象
 */
function validateFileTypeByAccept(accept, file) {
  // 获取文件后缀、raw.type
  const fileExtension = getFileExtension(file.name);
  const fileRawType = file.raw.type.toLowerCase();
  const fileRawTypePrefix = fileRawType.split('/')[0];
  // 遍历accept对比
  const acceptArr = accept.toLowerCase().split(',');
  for (let i = 0; i < acceptArr.length; i += 1) {
    // 匹配xxx/*通配文件类型
    if (/^\w+\/\*$/.test(acceptArr[i])) {
      const [firstAccept] = acceptArr[i].split('/');
      const acceptItemPrefix = firstAccept;
      if (fileRawTypePrefix === acceptItemPrefix) {
        return true;
      }
    }
    // 匹配文件后缀名
    else if (acceptArr[i] === fileExtension) {
      return true;
    }
  }
  return false;
}

/**
 * message提示
 * @param {string} message
 * @param {boolean} multiFlag 是否显示多个提示
 */
function showWarningMessage(message, multiFlag) {
  if (!multiFlag) {
    const showingMsg = document.getElementsByClassName('el-message--warning');
    if (!showingMsg || !showingMsg.length) {
      ElMessage({
        showClose: true,
        message,
        type: 'warning',
      });
    }
  } else {
    ElMessage({
      showClose: true,
      message,
      type: 'warning',
    });
  }
}

/**
 * 移除文件
 * @param {object} file 当前文件
 */
const handleRemove = (file) => {
  if (!props.customControlDelete) {
    fileList.value.forEach((item, i) => {
      if (file.uid === item.uid) {
        fileList.value.splice(i, 1);
      }
    });
  }
  // 触发父组件事件
  let result = fileList.value;
  if (props.type === 'singleImage') {
    const [firstItem] = result;
    result = firstItem;
  }
  emit('update:modelValue', result);
  emit('change', file);
  emit('delete', file);
};

/**
 * 格式化文件大小显示
 * @param {number} fileSize 文件大小
 */
function formatSizeDisplay(fileSize) {
  if (fileSize < 1024) {
    return `${fileSize}B`;
  }
  if (fileSize >= 1024 && fileSize < 1024 * 1024) {
    return `${Math.round((fileSize / 1024) * 100) / 100}KB`;
  }
  if (fileSize >= 1024 * 1024 && fileSize < 1024 * 1024 * 1024) {
    return `${Math.round((fileSize / 1024 / 1024) * 100) / 100}MB`;
  }
  if (fileSize >= 1024 * 1024 * 1024 * 1024) {
    return `${Math.round((fileSize / 1024 / 1024 / 1024) * 100) / 100}GB`;
  }
  return '0B';
}

/**
 * AES加密
 * @param {String} str 原字符
 */
function toAesEncrypt(data) {
  const key = import.meta.env.VITE_AES_KEY;
  // 确保密钥和IV是16字节
  const keyBytes = CryptoJS.enc.Utf8.parse(key);
  const ivBytes = CryptoJS.enc.Utf8.parse(key);

  const dataString = String(data);

  // 将数据补齐到16的倍数（NoPadding需要手动处理）
  const paddedData = CryptoJS.enc.Utf8.parse(
    dataString.padEnd(Math.ceil(dataString.length / 16) * 16, '\0'),
  );

  // 执行加密
  const encrypted = CryptoJS.AES.encrypt(paddedData, keyBytes, {
    iv: ivBytes,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.NoPadding,
  });
  // 将加密结果转换为Base64
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
}

/**
 * Blob转File
 * @param {Blob} blob
 * @param {string} fileName
 * @param {string} type
 */
function blobToFile(blob, fileName, type) {
  const files = new window.File([blob], fileName, { type });
  return files;
}

/**
 * 图片压缩
 * @param {File} file 当前文件对象
 * @param {number} compressImageSize 压缩大小
 */
const imageCompress = (file, compressImageSize) => {
  imageConversion = imageConversion ?? conversions;
  return imageConversion
    .compressAccurately(file.raw, (compressImageSize / 1024).toFixed(2))
    .then((res) => {
      const curFile = file;
      const compressedFile = blobToFile(res, curFile.name, curFile.raw.type);
      compressedFile.uid = file.uid;
      curFile.url = URL.createObjectURL(res);
      curFile.raw = compressedFile;
      curFile.size = compressedFile.size;
    })
    .catch((error) => {
      console.error('imageConversion.compressAccurately.error', error);
    });
};

/**
 * 文件选择
 * @param {object} file 当前文件
 * @param {array} files 已选择的文件组
 */
const handleChange = (praFile, files) => {
  const file = praFile;
  // 处理同名文件逻辑
  file.name = handleFileName(file, files, false);
  // 处理附件文件标签
  if (props.type === 'attachments') {
    file.attachmentItemClass = getAttachmentItemClass(file.name);
  }
  // 处理预览方式
  const fileExtension = getFileExtension(file.name);
  file.viewType = checkFileExtensionViewType(fileExtension);
  // 处理操作项显示
  if (props.type === 'attachments' && file.viewType !== 1) {
    // 附件上传时不支持查看
    file.hidPreview = true;
  }
  // 待上传文件的下载都不显示
  file.hidDownload = true;
  // 待上传文件的更新都不显示
  file.hidUpdate = true;
  // 待上传文件的更新都不显示
  file.hidPrint = true;

  // 限制文件类型
  if (!validateFileTypeByAccept(curAccept.value, file)) {
    showWarningMessage(
      `选择的文件格式有误，不支持文件“${file.name}”的文件格式！`,
      true,
    );
    handleRemove(file);
    return;
  }

  // 限制单文件大小
  if (props.limitSingleSize && props.limitSingleSize > 0) {
    if (file.size > props.limitSingleSize) {
      showWarningMessage(
        `单个${fileTypeWord}大小不能超过${formatSizeDisplay(
          props.limitSingleSize,
        )}！`,
      );
      handleRemove(file);
      return;
    }
  }
  // 限制总大小
  if (props.limitAllSize && props.limitAllSize > 0) {
    let allSize = 0;
    files.forEach((item) => {
      allSize += item.size;
    });
    if (allSize > props.limitAllSize) {
      showWarningMessage(
        `${fileTypeWord}总大小不能超过${formatSizeDisplay(
          props.limitAllSize,
        )}！`,
      );
      handleRemove(file);
      return;
    }
  }

  // 标记当前文件处理标识
  noImage.value = false; // 将无图标记为false
  handlingFileQty += 1;
  if (
    props.compressImageSize &&
    props.compressImageSize < file.size &&
    (props.type === 'singleImage' || props.type === 'images')
  ) {
    // 图片压缩后回调
    imageCompress(file, props.compressImageSize).finally(() => {
      handleEmit(files, file);
      new Promise((resolve) => {
        resolve();
      }).then(() => {
        handlingFileQty -= 1;
      });
    });
  } else {
    // 直接回调
    handleEmit(files, file);
    new Promise((resolve) => {
      resolve();
    }).then(() => {
      handlingFileQty -= 1;
    });
  }
};

/**
 * 下载文件
 * @param {object} file 当前文件
 */
const handleDownload = (file) => {
  if (file.attDid) {
    const fileTypeName = props.type === 'attachments' ? 'attachment' : 'image';
    const microMessengerFlag = navigator.userAgent
      .toLowerCase()
      .includes('micromessenger');
    if (microMessengerFlag) {
      window.open('/common/download/MicroMessengerTip', '_blank');
    } else {
      window.open(
        `${props.downloadAttachmentUrl}/${fileTypeName}?id=${toAesEncrypt(file.attDid)}`,
        '_blank',
      );
    }
  }
};

/**
 * 打印文件
 * @param {object} file 当前文件
 */
const handlePrint = (file) => {
  if (props.customControlPrint) {
    emit('print', file);
  } else if (file.attDid) {
    window.open(`${props.printAttachmentUrl}?id=${file.attDid}`, '_blank');
  }
};

/**
 * 更新文件
 * @param {object} file 当前文件
 */
const handleUpdate = (file) => {
  emit('update', file);
};

/**
 * 预览文件
 * @param {object} file 当前文件
 */
const handlePreview = (file) => {
  if (props.type === 'images' || props.type === 'singleImage') {
    // 转换预览图片url，初始化预览位置
    previewImageList.value = fileList.value.map((item, i) => {
      if (file.uid === item.uid) {
        imageViewerInitIndex.value = i;
      }
      return item.url;
    });
    if (!props.isMobile) {
      previewImageFlag.value = true;
    }
  } else if (file.viewType === 1) {
    // 转换预览图片url，初始化预览位置
    previewImageList.value = fileList.value
      .filter((x) => x.viewType === 1)
      .map((item, i) => {
        if (file.uid === item.uid) {
          imageViewerInitIndex.value = i;
        }
        return item.url;
      });
    if (!props.isMobile) {
      previewImageFlag.value = true;
    }
  } else if ((file.viewType === 2 || file.viewType === 3) && file.attDid) {
    if (!props.isMobile) {
      window.open(
        `${props.previewAttachmentUrl}/${toAesEncrypt(file.attDid)}`,
        '_blank',
      );
    } else {
      const fileExtension = getFileExtension(file.name);
      const versionStr = ['.rar', '.zip', '.7z'].some(
        (x) => x === fileExtension,
      )
        ? `&v=${guid()}`
        : ''; // 对于压缩包，移动端防止缓存
      previewAttachmentFileName.value = file.name;
      previewAttachmentMobileSrc.value = `${props.previewAttachmentUrl}/${toAesEncrypt(file.attDid)}${versionStr}`;
      popupShow.value = true;
    }
  } else {
    console.log('无法预览.');
  }
};

/**
 * 选择的文件超出限制时触发
 * @param {*} files 当前选择的文件组
 */
const handleExceed = (files) => {
  if (curLimit.value === 1) {
    // 单文件时直接覆盖
    refElUpload.value.clearFiles();
    const file = files[0];
    file.uid = genFileId();
    refElUpload.value.handleStart(file);
  } else {
    ElMessage({
      showClose: true,
      message: `最多选择${curLimit.value}个文件！`,
      type: 'warning',
    });
  }
};

/**
 * 图片预览切换
 * @param {number} index
 */
const imageViewerSwitch = () => {
  // console.log('imageViewerSwitch.index', index);
};

/**
 * 图片预览关闭
 */
const imageViewerClose = () => {
  previewImageFlag.value = false;
};

/**
 * 预览前置控制：鼠标移除时（主要控制tooltip超出显示区域时控制上下显示）
 * @param {event} e
 */
const handleMouseEnter = (e) => {
  let offsetTop = 0;
  if (e.target && e.target.y && e.target.height) {
    offsetTop = e.target.height + e.target.y;
  } else if (e.target && e.target.getBoundingClientRect) {
    const clientRect = e.target.getBoundingClientRect();
    offsetTop = clientRect.height + clientRect.y;
  }
  if (offsetTop + 100 > window.document.body.clientHeight) {
    placement.value = 'top';
  } else {
    placement.value = 'bottom';
  }
};

/**
 * 初始化数据
 */
function handleInitData() {
  if (fileList.value) {
    fileList.value.forEach((paramItem, i) => {
      const item = fileList.value[i];
      if (props.type === 'attachments') {
        // 处理附件集的项类名
        item.attachmentItemClass = getAttachmentItemClass(item.name);
      }
      if (item.operateInfo && !item.formatSizeFlag) {
        item.formatSizeFlag = true; // 标记该数据已格式化FileSize
      }
      if (item?.name) {
        const fileExtension = getFileExtension(item.name);
        item.viewType = checkFileExtensionViewType(fileExtension);
      }
    });
  }
}

/**
 * 处理单图传入的数据格式
 */
function handleSingleImageInitModel() {
  if (props.type === 'singleImage') {
    if (!fileList.value) {
      fileList.value = props.showNoImage ? [{}] : [];
      noImage.value = true;
    } else if (!fileList.value.length) {
      fileList.value = [fileList.value]; // 单数据转为数组
      noImage.value = false;
    }
  }
}

// #endregion

// #region 生命周期
handleSingleImageInitModel();
// 首次初始化值
handleInitData();
// 监听modelValue变化，处理初始化逻辑
watch(
  () => props.modelValue,
  (to) => {
    // 仅当非手动选择时触发
    if (handlingFileQty === 0) {
      fileList.value = to;
      handleSingleImageInitModel();
      handleInitData();
    }
  },
);

// #endregion
</script>

<style scoped lang="scss">
/* ------------ 公共 ------------ */

.zc-upload {
  margin-top: v-bind(uploadmainmargintop);
  line-height: normal !important;
  color: var(--el-color-primary);

  :deep(.el-image__error) {
    padding: 4px;
    font-size: 12px;
  }
}

.zc-upload.mobile {
  width: 100%;
}

.zc-upload :deep(.upload-tip-container) {
  margin-top: 10px;
  color: var(--el-color-grey-light);
}

.zc-upload :deep(.el-upload__tip) {
  margin-top: 0 !important;
  line-height: 20px !important;
  color: var(--el-color-grey-light);
}

.zc-upload.hide-upload-btn :deep(.el-upload) {
  display: none;
}

.zc-upload :deep(.el-upload--picture-card:hover) {
  color: var(--el-color-primary);
  border-color: var(--el-color-primary);
}

/* ------------ 单图 ------------ */

.zc-upload.single-image :deep(.el-upload-list > .el-upload-list__item) {
  width: v-bind(thisimagethumbnailwidth) !important;
  height: v-bind(thisimagethumbnailheight) !important;
  margin-bottom: v-bind(itemsmarginbottom);
}

.zc-upload.single-image :deep(.el-upload-list > .el-upload--picture-card) {
  order: -1;
  width: 80px !important;
  height: 80px !important;
  margin-right: 0.5rem;
  margin-bottom: v-bind(itemsmarginbottom) !important;
}

/* ------------ 图片集/附件集公共 ------------ */

.zc-upload :deep(.el-upload-list .el-tooltip__trigger) {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.zc-upload :deep(.el-upload-list .el-tooltip__trigger:has(.el-image__error)) {
  cursor: default;
}

.zc-upload.collection :deep(.el-upload-list > .el-upload--picture-card) {
  order: -1;
  width: v-bind(collectionbtncontainerwidth) !important;
  height: 60px !important;
  margin-right: 0.5rem;
  margin-bottom: v-bind(itemsmarginbottom);
}

.zc-upload.collection .input-container {
  position: relative;
  width: 100%;
  font-size: var(--el-font-size-medium);
  line-height: 100%;
  color: var(--el-text-color-placeholder);
  text-align: center;
}

.zc-upload.collection .input-container span {
  display: inline-block;
  vertical-align: middle;
}

/* ------------ 图片集 ------------ */

.zc-upload.images .input-container .input-ico {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 3px;
  vertical-align: middle;
  cursor: pointer;
  background: var(--el-icon-add-img);
  background-position: 0 0;
  background-size: 24px 24px;
}

.zc-upload.images :deep(.el-upload-list > .el-upload-list__item) {
  width: v-bind(thisimagethumbnailwidth) !important;
  height: v-bind(thisimagethumbnailheight) !important;
  margin-bottom: v-bind(itemsmarginbottom);
}

/* -- 移动端 begin -- */
.zc-upload.images.mobile :deep(.image-item-container) {
  position: relative;
}

.zc-upload.images.mobile :deep(.el-image) {
  width: 100%;
  height: 100%;
}

.zc-upload.images.mobile :deep(.image-item-container > .del) {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  background: url('@/assets/images/web/common/base/close-white.png') no-repeat;
  background-color: var(--el-color-grey-dark);
  background-position: 4px 20px;
  background-size: 16px 16px;
  border-radius: 40px;
}

/* -- 移动端 end -- */

/* ------------ 附件集 ------------ */

.zc-upload.attachments .input-container .input-ico {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 3px;
  vertical-align: middle;
  cursor: pointer;
  background: url('@/assets/images/web/common/base/ico-add-file.png');
  background-position: 0 0;
  background-size: 24px 24px;
}

.zc-upload.attachments :deep(.el-upload-list > .el-upload-list__item) {
  width: 50px !important;
  height: v-bind(attachmentitemheight) !important;
  margin-bottom: v-bind(itemsmarginbottom);
}

/* -- 移动端 begin -- */
.zc-upload.images.mobile .input-container .input-ico {
  width: 28px;
  height: 28px;
  margin-right: 0;
  background: url('@/assets/images/web/common/base/ico-add-img-large.png')
    no-repeat;
  background-position: 0 0;
  background-size: 28px 28px;
}

.zc-upload.attachments.mobile .input-container .input-ico {
  width: 32px;
  height: 32px;
  margin-right: 0;
  background: url('@/assets/images/web/common/base/ico-add-file-large.png')
    no-repeat;
  background-position: 0 0;
  background-size: 32px 32px;
}

.zc-upload.attachments.mobile :deep(.el-upload-list) {
  display: block !important;
}

.zc-upload.attachments.mobile :deep(.el-upload-list > .el-upload-list__item) {
  display: block !important;
  width: calc(100% - 4px) !important;
}

.zc-upload.attachments.mobile :deep(.attachment-item-container > .ico) {
  float: left;
  width: 40px;
  height: 100%;
}

.zc-upload.attachments.mobile :deep(.attachment-item-container > .file-name) {
  float: left;
  width: calc(100% - 100px);
  overflow: hidden;
  line-height: v-bind(attachmentitemheight);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.zc-upload.attachments.mobile
  :deep(.attachment-item-container > .file-name.no-del) {
  width: calc(100% - 50px);
}

.zc-upload.attachments.mobile :deep(.attachment-item-container > .del) {
  float: right;
  width: 50px;
  height: 100%;
  line-height: v-bind(attachmentitemheight);
  color: var(--el-color-red);
  text-align: center;
}

/* -- 移动端 end -- */

.zc-upload.attachments .file-word {
  background: url('./images/attachment-icons/web/ico-word.png') no-repeat center
    center;
}

.zc-upload.attachments .file-excel {
  background: url('./images/attachment-icons/web/ico-excel.png') no-repeat
    center center;
}

.zc-upload.attachments .file-ppt {
  background: url('./images/attachment-icons/web/ico-ppt.png') no-repeat center
    center;
}

.zc-upload.attachments .file-pdf {
  background: url('./images/attachment-icons/web/ico-pdf.png') no-repeat center
    center;
}

.zc-upload.attachments .file-txt {
  background: url('./images/attachment-icons/web/ico-txt.png') no-repeat center
    center;
}

.zc-upload.attachments .file-rar {
  background: url('./images/attachment-icons/web/ico-rar.png') no-repeat center
    center;
}

.zc-upload.attachments .file-zip {
  background: url('./images/attachment-icons/web/ico-zip.png') no-repeat center
    center;
}

.zc-upload.attachments .file-img {
  background: url('./images/attachment-icons/web/ico-img.png') no-repeat center
    center;
}

.zc-upload.attachments .file-video {
  background: url('./images/attachment-icons/web/ico-video.png') no-repeat
    center center;
}

.zc-upload.attachments .file-other {
  background: url('./images/attachment-icons/web/ico-other.png') no-repeat
    center center;
}
</style>

<style>
.file-view-tooltip {
  background: #e9e9e9 !important;
}

.file-view-tooltip .el-popper__arrow::before {
  background: #e9e9e9 !important;
}

/* ------------ 悬浮框内部样式 ------------ */

.file-view-tooltip .tooltip-row-file {
  font-size: var(--el-font-size-base);
  line-height: 25px;
  text-align: center;
}

.file-view-tooltip .tooltip-row-user {
  font-size: var(--el-font-size-base);
  line-height: 25px;
  text-align: center;
}

.file-view-tooltip .tooltip-row-operate {
  margin-top: 2px;
  margin-bottom: 3px;
  text-align: center;
}

.file-view-tooltip .tooltip-row-file .col-file-name {
  font-size: var(--el-font-size-large);
  font-weight: 700 !important;
  color: #333;
}

.file-view-tooltip .tooltip-row-file .col-file-size {
  margin-left: 5px;
  color: #333;
}

.file-view-tooltip .tooltip-row-file .col-file-status {
  margin-left: 5px;
  color: #f90;
}

.file-view-tooltip .tooltip-row-file .col-file-status.no-exists {
  margin-left: 5px;
  color: #999;
}

.file-view-tooltip .tooltip-row-user .col-user-name {
  color: #333;
}

.file-view-tooltip .tooltip-row-user .col-user-handle {
  margin-left: 5px;
  color: #aaa;
}

.file-view-tooltip .tooltip-row-user .col-user-time {
  margin-left: 5px;
  color: #333;
}

/* ------------ 移动端附件预览 ------------ */

.preview-attachment-wrap {
  width: 100%;
  height: 100%;
}

.preview-attachment-wrap .van-nav-bar__title {
  max-width: 80%;
  margin: 0 0 0 1rem;
  font-size: 1.2rem;
}

.preview-attachment-iframe {
  width: 100%;
  height: calc(100% - 50px);
  overflow: hidden;
  border: 0;
}

.van-icon.preview-file-name {
  display: inline-block;
  width: calc(100% - 60px);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.small-text {
  font-size: var(--el-font-size-base);
}

.dot {
  overflow: hidden;
  animation: dot 2s infinite steps(3, start);
}
</style>
