<template>
  <div class="list-main">
    <div
      v-for="(item, index) in props.contentList"
      :key="index"
      class="list-with-image"
    >
      <a :href="item.url">
        <div class="list-with-image-content-left">
          <div class="list-with-image-content-left-image">
            <!-- <img :src="item.coverImageUrl" /> -->
            <el-image :src="item.coverImageUrl" fit="cover"></el-image>
          </div>
          <div class="list-with-image-content-left-tag">
            <span class="list-with-image-content-left-tag-time">
              <span class="list-with-image-content-left-tag-time-dy">
                {{ new Date(item.publishTime).getDate() }}
              </span>
              <span class="list-with-image-content-left-tag-time-ny">
                {{
                  new Date(item.publishTime).getFullYear() +
                  '.' +
                  (new Date(item.publishTime).getMonth() + 1)
                }}
              </span>
            </span>
            <span class="list-with-image-content-left-tag-read">
              <span class="list-with-image-content-left-tag-read-eye"></span>
              <span class="list-with-image-content-left-tag-read-count">
                {{ item.readCount }}
              </span>
            </span>
          </div>
        </div>
        <div class="list-with-image-content-right">
          {{ item.title }}
        </div>
      </a>
    </div>
  </div>
</template>

<script setup>
// #region 引用

// #endregion

// #region props/emit
const props = defineProps({
  contentList: {
    type: Array,
    default: () => [],
  },
});
// #endregion

// #region 变量/常量

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

// #endregion
</script>
<style scoped>
@media screen and (device-width >= 1180px) {
  .list-main {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
  }

  .list-with-image {
    display: flex;
    flex-flow: column wrap;
    width: calc((100% - 40px) / 3);
    overflow: hidden;
    background-color: #e7edf4;
  }

  .list-with-image a {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .list-with-image-content-left {
    position: relative;
    display: flex;
    flex: 1;
  }

  .list-with-image-content-left-image {
    flex: 1;
    height: 220px;
    transition: all 0.5s ease;
  }

  .list-with-image-content-left-image .el-image {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }

  .list-with-image:hover .list-with-image-content-left-image .el-image {
    transform: translateX(5px);
  }

  .list-with-image-content-left-tag {
    position: absolute;
    top: 0;
    left: 20px;
    width: 70px;
    color: white;
    text-align: center;
    background: #008bd5;
    opacity: 0.9;
  }

  .list-with-image-content-left-tag span {
    display: inline-block;
  }
  .list-with-image-content-left-tag-time {
    display: flex;
    padding: 3px 0;

    /* flex-direction: column; */
  }

  .list-with-image-content-left-tag-time-dy {
    font-size: 18px;
    color: #fff;
  }

  .list-with-image-content-left-tag-time-ny {
    width: 100%;
    font-size: 16px;
  }

  .list-with-image-content-left-tag-read {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 3px 0;
    font-size: 14px;
    color: #888;
    background: #fff;
  }

  .list-with-image-content-left-tag-read-eye {
    display: inline-block;
    width: 15px;
    height: 12px;
    margin-right: 3px;
    background: url('./image/eye.png') no-repeat;
    background-size: cover;
  }

  .list-with-image-content-right {
    display: block;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 10px;
    overflow: hidden;
    font-size: 15px;
    font-weight: 600;
    line-height: 50px;
    color: #444;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.5s ease;
    -webkit-box-orient: vertical;
  }
}
</style>

<style scoped>
@media screen and (device-width <= 1180px) {
  .list-main {
    display: flex;
    flex-flow: row wrap;
    gap: 4vw;
  }
  .list-with-image {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid #ccc;
  }

  .list-with-image-content {
    display: flex;
    flex-direction: column;
    margin: 2.5vw;
  }

  .list-with-image-content-left {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vw;
  }

  .list-with-image-content-left-image {
    width: 100%;
    height: 60vw;
    transition: all 0.5s ease;
  }

  .list-with-image-content-left-image .el-image {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }
  .list-with-image:hover {
    border-bottom: 1px solid #ccc;
  }

  .list-with-image:hover .list-with-image-content-left-image img,
  .list-with-image:hover .list-with-image-content-right-title {
    transform: translateX(5px);
  }

  .list-with-image-content-left-tag {
    position: absolute;
    top: 0;
    left: 5%;
    width: 17.5vw;
    color: white;
    text-align: center;
    background: #008bd5;
    opacity: 0.9;
  }

  .list-with-image-content-left-tag span {
    display: inline-block;
  }
  .list-with-image-content-left-tag-time {
    display: flex;

    /* flex-direction: column; */
  }

  .list-with-image-content-left-tag-time-dy {
    font-size: 10vw;
    color: #fff;
  }

  .list-with-image-content-left-tag-time-ny {
    width: 100%;
    font-size: 4vw;
  }

  .list-with-image-content-left-tag-read {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 1vw 0;
    font-size: 4vw;
    color: #888;
    background: #fff;
  }

  .list-with-image-content-left-tag-read-eye {
    display: inline-block;
    width: 4vw;
    height: 2.5vw;
    margin-right: 1vw;
    background: url('./image/eye.png') no-repeat;
    background-size: cover;
  }

  .list-with-image-content-right {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 2vw 0 2vw 1vw;
    font-size: 4vw;

    /* justify-content: space-between; */
  }

  .list-with-image-content-right-digest {
    height: 16vw;
    overflow: hidden;
    font-size: 4vw;
    line-height: 2;
    color: #8c8c8c;
  }

  .list-with-image-content-right-title {
    display: -webkit-box;
    overflow: hidden;
    font-size: 5vw;
    line-height: 2;
    color: #008bd5;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    transition: all 0.5s ease;
    -webkit-box-orient: vertical;
  }

  .list-with-image-content-right-btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 2vw;
  }

  .list-with-image-content-right-btn span {
    display: inline-block;
    width: 25vw;
    height: 10vw;
    margin: 10vw 2.5vw 0 0;
    font-size: 5vw;
    line-height: 2;
    color: #8c8c8c;
    text-align: center;
    background: #fff;
    box-shadow: 0 0 2.5vw 0 #bbb9b9;
  }

  .list-with-image:hover .list-with-image-content-right-btn span {
    color: #fff;
    background: #008bd5;
  }
}
</style>
