<template>
  <div class="footer-form-container">
    <el-form
      ref="ruleFormRef"
      :model="configDataForm"
      :rules="rules"
      label-width="150px"
      class="account-form"
      :size="formSize"
    >
      <el-form-item label="显示图片：" prop="backGroundImageObj">
        <zc-upload
          v-model="configDataForm.backGroundImageObj"
          :limit-single-size="1048576"
          :image-thumbnail-width="240"
          type="singleImage"
          :enable-popup="false"
          :show-no-image="true"
          :small-text="true"
          tip="说明：上传的图片大小不得超过1M，建议尺寸1920*1080px"
          @change="handleInputChange('backGroundImageObj')"
        >
          <template #singleImageError>
            <div class="el-image__error image-error-show">图片丢失</div>
          </template>
        </zc-upload>
      </el-form-item>
      <!-- <el-form-item
            label="标题图标："
            prop="iconImageObj"
            :rules="rules.backGroundImageObj"
          >
            <zc-upload
              v-model="configDataForm.iconImageObj"
              :limit-single-size="1048576"
              type="singleImage"
              :enable-popup="false"
              :show-no-image="true"
              :small-text="true"
              tip="说明：上传的图片大小不得超过1M，建议尺寸40*40px"
              @change="handleInputChange('iconImageObj')"
            >
              <template #singleImageError>
                <div class="el-image__error image-error-show">图片丢失</div>
              </template>
            </zc-upload>
          </el-form-item>
           -->
      <el-form-item label="图片点击跳转地址：" prop="linkUrl">
        <el-input v-model="configDataForm.linkUrl" />
      </el-form-item>
      <el-form-item label="图片显示高度：" prop="imageHeight">
        <el-input v-model="configDataForm.imageHeight" />
      </el-form-item>
    </el-form>
    <div class="footer-btn-container">
      <el-button @click="handleCancel()">取消</el-button>
      <el-button type="primary" @click="handleSave(ruleFormRef)"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script setup>
// #region 引用
import {
  verifyCharacterLength,
  verifyLinkUrl,
} from '@/utils/common/form-verify-util';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  componentData: {
    type: Object,
    default() {},
  },
});
/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'update:modelValue',
  'handle-edit-components-content',
]);

// #endregion

// #region 变量/常量

// 栏目数据列表

// 表单size
const formSize = ref('default');
const ruleFormRef = ref();

// 表单绑定对象
const configDataForm = ref({
  backGroundImageObj: null,
  backGroundImageName: '',
  backGroundImageFileKey: 'backgroundImage',
  backGroundColor: '#008bd5',
  linkUrl: 'https://www.baidu.com',
  imageHeight: 1080,
  title: '示例标题',
  fontColor: '#ffffff',
  iconImageObj: null,
  iconName: '示例图标1.png',
  iconFileKey: 'iconImage',
});

if (props.componentData.configData) {
  configDataForm.value = JSON.parse(props.componentData.configData);
  console.log('props:', props);

  // 图标
  configDataForm.value.iconImageObj = {
    name: configDataForm.value.iconName,
    url: configDataForm.value.iconFullUrl,
  };
  // 背景图片
  configDataForm.value.backGroundImageObj = {
    name: configDataForm.value.backGroundImageName,
    url: configDataForm.value.backGroundImageFullUrl,
  };
}
// 表单规则
const rules = ref({
  backGroundImageObj: [{ required: true, message: '必填项', trigger: 'blur' }],
  iconNameObj: [{ required: true, message: '必填项', trigger: 'blur' }],
  textColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  linkUrl: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 200, trigger: 'blur' },
  ],
});
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 处理输入框变化的事件
 *
 * @param inputKey 输入框的键名
 */
const handleInputChange = (inputKey) => {
  ruleFormRef.value.validateField(inputKey, (valid) => {
    console.log(
      `🚀 ~ ruleFormRef.value.validateField ~ ${inputKey}-valid:`,
      valid,
    );
  });
};

/**
 * 处理取消
 */
const handleCancel = () => {
  emits('handle-show-edit-form', false);
};
/**
 * 处理保存
 */
const handleSave = (form) => {
  if (!form) return;

  form.validate((valid, fields) => {
    if (valid) {
      // console.log('configDataForm-FooterEditForm:', configDataForm.value);

      // 待数据更新完成后才执行
      nextTick(() => {
        emits('handle-edit-components-content', false);
        emits('update:modelValue', {
          backGroundImageObj: configDataForm.value.backGroundImageObj,
          backGroundImageName: configDataForm.value.backGroundImageName,
          backGroundImageFileKey:
            configDataForm.value.backGroundImageFileKey || 'backgroundImage',
          backGroundColor: configDataForm.value.backGroundColor,
          title: configDataForm.value.title,
          fontColor: configDataForm.value.fontColor,
          iconImageObj: configDataForm.value.iconImageObj,
          iconName: configDataForm.value.iconName,
          iconFileKey: configDataForm.value.iconFileKey,
          linkUrl: configDataForm.value.linkUrl,
          imageHeight: configDataForm.value.imageHeight,
        });
      });
      // TODO 待完成保存逻辑
    } else {
      console.log('fields:', fields);
    }
  });
};

// #endregion
</script>

<style scoped>
.footer-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin: auto;
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.footer-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: var(--el-color-white);
}

.footer-btn-container button {
  margin: 8px;
}

:deep(.el-form) {
  width: 100%;
  padding: 20px;
}

.card-frame {
  margin-bottom: unset !important;
}

.el-row-customer {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  padding-top: 10px;
  margin: 10px 0;
  border: 1px solid;
  border-color: var(--el-color-grey-light-45);

  /* flex-direction: column; */
}

.copyright-form-item-form {
  width: calc(100% - 44px) !important;
}

.copyright-form-item-remove {
  width: 24px !important;
  margin: 0 5px;
}

:deep(.el-color-picker__trigger) {
  width: 100px;
}
</style>
