<template>
  <div class="main-wrap">
    <location :content-nav-list="contentNavList"></location>
    <div class="detail-title-container">
      <div class="detail-title-container-title">
        {{ materialArticlesInfo.currentMaterial.title }}
      </div>
      <div class="detail-title-container-time">
        <div class="time"></div>

        {{
          materialArticlesInfo.currentMaterial.publishTime
            ? formatDate(materialArticlesInfo.currentMaterial.publishTime)
            : materialArticlesInfo.currentMaterial.publishTime
        }}
        <div class="read-count"></div>
        {{ materialArticlesInfo.currentMaterial.readCount }}
      </div>
      <div class="detail-title-container-digest">
        【摘要】：{{ materialArticlesInfo.currentMaterial.digest }}
      </div>
    </div>
    <div class="detail-content-container">
      <ArticleContent
        :content="materialArticlesInfo.currentMaterial.content"
        :attachment-file-list="
          materialArticlesInfo.currentMaterial.attachmentFileList
        "
      />
    </div>
    <div class="detail-pager-container">
      <span
        v-if="materialArticlesInfo && materialArticlesInfo.previousMaterial"
        class="detail-pager-container-pre"
        >上一篇：<a :href="materialArticlesInfo.previousMaterial.url">{{
          materialArticlesInfo.previousMaterial.title
        }}</a>
      </span>
      <span class="detail-pager-container-blank"></span>
      <span
        v-if="materialArticlesInfo && materialArticlesInfo.nextMaterial"
        class="detail-pager-container-next"
        >下一篇：
        <a :href="materialArticlesInfo.nextMaterial.url + ''">{{
          materialArticlesInfo.nextMaterial.title
        }}</a>
      </span>
    </div>
  </div>
</template>
<script setup>
// #region 引用

import ArticleContent from '@/views/components/website/article-content/ArticleContent.vue';

import useSSRInitialStateStore from '@/stores/ssr-state';
import { useSSRContext } from 'vue';
import { getColumnPageUrl, getMaterialPageUrl } from '@/utils/business/website';
import { formatDate } from '@/utils/common/date-util';
import location from '../components/Location.vue';
// #endregion

// #region props/emit

// #endregion

// #region 变量/常量

const configInfo = ref(); // 配置信息
const configPageList = ref(); // 配置页面列表
// 栏目信息
const columnInfo = ref();
// 栏目信息
const materialArticlesInfo = ref({});
const previewId = ref();
// 导航地址信息
const contentNavList = ref([]);
const route = useRoute();
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 获取栏目内容缓存内容
 */

onServerPrefetch(async () => {
  const ssrContext = useSSRContext();
  configInfo.value = ssrContext.initialState.data.current.configInfo;
  configPageList.value = ssrContext.initialState.data.current.configPageList;
  columnInfo.value = ssrContext.initialState.data.current.columnInfo;
  previewId.value = ssrContext.initialState.data.current.previewId;
  materialArticlesInfo.value =
    ssrContext.initialState.data.current.materialArticlesInfo;
  contentNavList.value.push({
    title: '正文',
    url: getMaterialPageUrl(
      route.params.columnId,
      route.params.materialId,
      previewId.value,
    ),
  });
  ssrContext.initialState.data.current.contentNavList = contentNavList.value;

  if (
    materialArticlesInfo.value &&
    materialArticlesInfo.value.previousMaterial
  ) {
    materialArticlesInfo.value.previousMaterial.url = getMaterialPageUrl(
      route.params.columnId,
      materialArticlesInfo.value.previousMaterial.id,
      previewId.value,
    );
  }
  if (materialArticlesInfo.value && materialArticlesInfo.value.nextMaterial) {
    materialArticlesInfo.value.nextMaterial.url = getMaterialPageUrl(
      route.params.columnId,
      materialArticlesInfo.value.nextMaterial.id,
      previewId.value,
    );
  }
  ssrContext.initialState.data.current.materialArticlesInfo =
    materialArticlesInfo.value;

  if (materialArticlesInfo.value) {
    useHead({
      title: `${materialArticlesInfo.value.currentMaterial.title} -  ${columnInfo.value.columnName}  -  ${configInfo.value.homeTitle}`,
      meta: [
        // 设置栏目名称
        {
          name: 'keywords',
          content: columnInfo.value.columnName,
        },
        // 设置栏目摘要
        {
          name: 'description',
          content: columnInfo.value.digest,
        },
      ],
    });
  }
});
// 客户获取数据
if (!import.meta.env.SSR) {
  const ssrInitialState = useSSRInitialStateStore();
  materialArticlesInfo.value =
    ssrInitialState.data.current.materialArticlesInfo;
  contentNavList.value = ssrInitialState.data.current.contentNavList;
  previewId.value = ssrInitialState.data.current.previewId;
}

// #endregion
</script>
<style scoped>
@media screen and (device-width >= 1180px) {
  .main-wrap {
    padding-top: 15px;
  }

  .detail-title-container {
    width: 1200px;
    margin: 0 auto 18px;
  }

  .detail-title-container-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 2;
    color: #000;
    text-align: center;
  }

  .detail-title-container-time {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    color: rgb(0 0 0 / 60%);
  }

  .detail-title-container-time .time {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background: url('./image/date.png') no-repeat left center;
    background-size: auto 100%;
  }

  .detail-title-container-time .read-count {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-left: 20px;
    background: url('./image/eye.png') no-repeat left center;
    background-size: auto 100%;
  }

  .detail-title-container-digest {
    padding: 10px;
    margin: 6px 0;
    font-size: 14px;
    line-height: 2;
    color: rgb(0 0 0 / 80%);
    background-color: #f4f4f5;

    /* box-shadow: 0 0 6px rgba(35, 52, 78, 0.3); */
  }

  .detail-content-container {
    width: 1200px;
    padding: 30px 40px;
    margin: 0 auto 28px;
    overflow: hidden;
    box-shadow: 0 0 6px rgb(35 52 78 / 30%);
  }

  .detail-pager-container {
    display: flex;
    width: 1200px;
    margin: 0 auto 28px;
    overflow: hidden;
  }

  .detail-pager-container-pre,
  .detail-pager-container-next {
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .detail-pager-container-pre a {
    color: #008bd5;
  }

  .detail-pager-container-blank {
    flex-grow: 1;
  }

  .detail-pager-container-next a {
    color: #008bd5;
  }
}
</style>
<style scoped>
@media screen and (device-width <= 1180px) {
  .detail-title-container {
    padding: 0 3vw;
    font-size: 4vw;
  }

  .detail-title-container-title {
    font-size: 5.2vw;
    font-weight: 600;
    line-height: 1.5em;
    color: #000;
  }

  .detail-title-container-time {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 3vw 0;
    color: rgb(0 0 0 / 80%);
  }

  .detail-title-container-time .time {
    display: inline-block;
    width: 5vw;
    height: 5vw;
    margin-right: 1vw;
    background: url('./image/date.png') no-repeat left center;
    background-size: auto 100%;
  }

  .detail-title-container-time .read-count {
    display: inline-block;
    width: 5vw;
    height: 5vw;
    margin-right: 1vw;
    margin-left: 3vw;
    background: url('./image/eye.png') no-repeat left center;
    background-size: auto 100%;
  }

  .detail-title-container-digest {
    padding: 2vw;
    margin: 4vw 0;
    font-size: 3.8vw;
    line-height: 2;
    color: rgb(0 0 0 / 60%);
    background-color: #f4f4f5;
  }

  .detail-content-container {
    padding: 2vw;
    margin: 0 3vw;
    overflow: hidden;
    box-shadow: 0 0 6px rgb(35 52 78 / 30%);
  }

  .detail-pager-container {
    display: flex;
    flex-direction: row;
    margin: 3vw;
    font-size: 4vw;
    line-height: 2.5em;
  }

  .detail-pager-container-pre,
  .detail-pager-container-next {
    width: calc(50% - 2vw);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .detail-pager-container-pre a {
    color: #008bd5;
  }

  .detail-pager-container-blank {
    width: 4vw;
  }

  .detail-pager-container-next a {
    color: #008bd5;
  }
}
</style>
