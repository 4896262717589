<template>
  <div class="footer-form-container">
    <el-form
      ref="ruleFormRef"
      :model="configDataForm"
      :rules="rules"
      class="account-form"
      :size="formSize"
    >
      <div class="card-frame other-card-frame">
        <div class="card-frame-top">基础配置</div>
        <div class="card-frame-container">
          <el-form-item
            label="内容文本："
            :prop="`content`"
            :rules="rules.content"
            label-width="100px"
            @change="handleInputChange(`content`)"
          >
            <el-input
              v-model="configDataForm.content"
              type="textarea"
              :autosize="{ minRows: 5 }"
            />
          </el-form-item>
          <el-form-item
            label="内容颜色："
            prop="fontColor"
            :rules="rules.color"
            label-width="100px"
          >
            <el-color-picker v-model="configDataForm.fontColor" />
          </el-form-item>
        </div>
      </div>
      <div class="card-frame other-card-frame">
        <div class="card-frame-top">概况统计项</div>
        <div class="card-frame-container">
          <el-row
            v-for="(item, index) in configDataForm.overviewItems"
            :key="item"
            class="el-row-customer"
          >
            <span class="copyright-form-item-form">
              <el-col :span="24">
                <el-form-item
                  :label="'标题：'"
                  :prop="`overviewItems[${index}].title`"
                  :rules="rules.text"
                  label-width="100px"
                  @change="handleInputChange(`overviewItems[${index}].title`)"
                >
                  <el-input v-model="item.title" />
                </el-form-item>
                <el-form-item
                  :label="'数量：'"
                  :prop="`overviewItems[${index}].num`"
                  :rules="rules.text"
                  label-width="100px"
                  @change="handleInputChange(`overviewItems[${index}].num`)"
                >
                  <el-input v-model="item.num" />
                </el-form-item>
                <el-form-item
                  label="背景颜色："
                  :prop="`overviewItems[${index}].bgColor`"
                  :rules="rules.color"
                  label-width="100px"
                >
                  <el-color-picker v-model="item.bgColor" />
                </el-form-item>
                <el-form-item
                  label="标题颜色："
                  :prop="`overviewItems[${index}].titleFontColor`"
                  :rules="rules.color"
                  label-width="100px"
                >
                  <el-color-picker v-model="item.titleFontColor" />
                </el-form-item>
                <el-form-item
                  label="数量颜色："
                  :prop="`overviewItems[${index}].numFontColor`"
                  :rules="rules.color"
                  label-width="100px"
                >
                  <el-color-picker v-model="item.numFontColor" />
                </el-form-item>
              </el-col>
            </span>
            <span class="copyright-form-item-remove">
              <template v-if="configDataForm.overviewItems.length > 1">
                <el-link type="danger" @click="handleDel(index)">
                  移除
                </el-link>
              </template>
              <template v-else>
                <el-tooltip content="至少保留一项版权内容项" placement="top">
                  <el-link type="danger" disabled> 移除 </el-link>
                </el-tooltip>
              </template>
            </span>
          </el-row>
          <el-row>
            <el-col :span="24">
              <template v-if="configDataForm.overviewItems.length < 6">
                <el-button style="width: 100%" @click="handleAddItem()">
                  <el-icon><Plus /></el-icon>添加项</el-button
                >
              </template>
              <template v-else>
                <el-tooltip content="最多添加6项" placement="top">
                  <el-button style="width: 100%" disabled>
                    <el-icon><Plus /></el-icon>添加项</el-button
                  >
                </el-tooltip>
              </template>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
    <div class="footer-btn-container">
      <el-button @click="handleCancel()">取消</el-button>
      <el-button type="primary" @click="handleSave(ruleFormRef)"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script setup>
// #region 引用
import {
  verifyCharacterLength,
  verifyLinkUrl,
} from '@/utils/common/form-verify-util';
import { Plus } from '@element-plus/icons-vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  componentData: {
    type: Object,
    default() {},
  },
});
/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'update:modelValue',
  'handle-edit-components-content',
]);

// #endregion

// #region 变量/常量

// 栏目数据列表

// 表单size
const formSize = ref('default');
const ruleFormRef = ref();

// 表单绑定对象
const configDataForm = ref({
  content: '示例内容示例内容示例内容示例内容示例内容示例内容',
  fontColor: '#333333',
  overviewItems: [
    {
      title: '示例概况标题1',
      num: '',
      bgColor: '#e2e9f1',
      titleFontColor: '#666666',
      numFontColor: '#194193',
    },
    {
      title: '示例概况标题2',
      num: '',
      bgColor: '#e2e9f1',
      titleFontColor: '#666666',
      numFontColor: '#194193',
    },
  ],
});

if (props.componentData.configData) {
  configDataForm.value = JSON.parse(props.componentData.configData);
}
// 表单规则
const rules = ref({
  color: [{ required: true, message: '必填项', trigger: 'blur' }],
  text: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 50, trigger: 'blur' },
  ],
  content: [{ required: true, message: '必填项', trigger: 'blur' }],
});
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 处理输入框变化的事件
 *
 * @param inputKey 输入框的键名
 */
const handleInputChange = (inputKey) => {
  ruleFormRef.value.validateField(inputKey, (valid) => {
    console.log(
      `🚀 ~ ruleFormRef.value.validateField ~ ${inputKey}-valid:`,
      valid,
    );
  });
};

/**
 * 图标图片改变后调用
 */
const handleChange = (index) => {
  handleInputChange(`items[${index}].itemFileObj`);
};

/**
 * 处理取消
 */
const handleCancel = () => {
  emits('handle-show-edit-form', false);
};
/**
 * 处理保存
 */
const handleSave = (form) => {
  if (!form) return;

  form.validate((valid, fields) => {
    if (valid) {
      // console.log('configDataForm-FooterEditForm:', configDataForm.value);

      // 待数据更新完成后才执行
      nextTick(() => {
        emits('handle-edit-components-content', false);
        emits('update:modelValue', configDataForm.value);
      });
      // TODO 待完成保存逻辑
    } else {
      console.log('fields:', fields);
    }
  });
};

/**
 * 处理删除（图标）
 */
const handleDel = (index) => {
  configDataForm.value.overviewItems.splice(index, 1);
  //   handleResetRules();
};

/**
 * 添加项目
 */
const handleAddItem = () => {
  configDataForm.value.overviewItems.push({
    title: '',
    num: '',
    bgColor: '',
    titleFontColor: '',
    numFontColor: '',
  });
};

// #endregion
</script>

<style scoped>
.footer-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin: auto;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.footer-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: var(--el-color-white);
}

.footer-btn-container button {
  margin: 8px;
}

:deep(.el-form) {
  width: 100%;
  padding: 20px;
}

.card-frame {
  margin-bottom: unset !important;
}

.el-row-customer {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  width: 100%;
  padding-top: 10px;
  margin: 10px 0;
  border: 1px solid;
  border-color: var(--el-color-grey-light-45);

  /* flex-direction: column; */
}

.copyright-form-item-form {
  width: calc(100% - 44px) !important;
}

.copyright-form-item-remove {
  width: 24px !important;
  margin: 0 5px;
}

:deep(.el-color-picker__trigger) {
  width: 100px;
}
</style>
