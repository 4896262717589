<template>
  <div class="top-nav-container">
    <div
      :class="{
        'top-nav   top-nav-fixed': props.componentData.fromType === 3,
        'top-nav   top-nav-absolute': props.componentData.fromType !== 3,
      }"
    >
      <div
        class="top-nav-menu-container"
        @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
        @mouseleave="handleShowOperationBtn(false)"
      >
        <div class="top-nav-menu-logo">
          <a :href="indexHrefUrl"
            ><img :src="configDataForm.leftLogoImageFullUrl" fit="cover"
          /></a>
        </div>
        <div class="top-nav-menu-list">
          <span class="menu-close" @click="handleShowMoreMenu"> </span>
          <span
            v-for="item in configDataForm.columnList"
            :key="item.id"
            :class="{
              active: item.id == activeIndex,
              'top-nav-menu-list-item': true,
            }"
          >
            <span class="menu-item-icon">
              <img
                v-if="item.data && item.data.iconUrl"
                :src="item.data.iconUrl"
              />
            </span>
            <a v-if="item.data" :href="item.data.hrefURL">
              {{ item.label }}
            </a>
          </span>
        </div>
        <div class="top-nav-more-menu" @click="handleShowMoreMenu"></div>
        <client-only>
          <operation-container
            v-if="showOperationBtn && !showEditForm"
            :component-data="props.componentData"
            :show-edit-content-button="props.showEditContentButton"
            :show-edit-components-button="props.showEditComponentsButton"
            :show-delete-components-button="props.showDeleteComponentsButton"
            :show-insert-components-button="props.showInsertComponentsButton"
            :show-sort-components-button="props.showSortComponentsButton"
            @handle-show-edit-form="handleShowEditForm"
            @handle-change-components="handleChangeComponents"
            @handle-delete-components="handleDeleteComponents"
            @handle-insert-components="handleInsertComponents"
            @handle-sort-components="handleSortComponents"
          ></operation-container>

          <el-drawer
            v-model="showEditForm"
            size="60%"
            :title="props.componentData.componentName"
          >
            <top-nav-edit-form
              v-if="showEditForm"
              v-model="configDataForm"
              :component-data="props.componentData"
              @handle-show-edit-form="handleShowEditForm"
              @handle-edit-components-content="handleEditComponentsContent"
            ></top-nav-edit-form>
          </el-drawer>
        </client-only>
      </div>
    </div>
    <div class="top-nav">
      <div
        class="top-nav-menu-container"
        @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
        @mouseleave="handleShowOperationBtn(false)"
      >
        <div class="top-nav-menu-logo">
          <a :href="indexHrefUrl"
            ><img :src="configDataForm.leftLogoImageFullUrl" fit="cover"
          /></a>
        </div>
        <div
          class="top-nav-menu-list-mask-layer"
          :class="{ show: showMobileMenuList }"
        ></div>
        <div class="top-nav-menu-list" :class="{ show: showMobileMenuList }">
          <span class="menu-close" @click="handleShowMoreMenu"> </span>
          <span
            v-for="item in configDataForm.columnList"
            :key="item.id"
            :class="{
              active: item.id == activeIndex,
              'top-nav-menu-list-item': true,
            }"
          >
            <span class="menu-item-icon">
              <img
                v-if="item.data && item.data.iconUrl"
                :src="item.data.iconUrl"
              />
            </span>
            <a v-if="item.data" :href="item.data.hrefURL">
              {{ item.label }}
            </a>
          </span>
        </div>
        <div class="top-nav-more-menu"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import TopNavEditForm from './TopNavEditFormOne.vue';
import OperationContainer from '../common/OperationContainer.vue';

// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量
// 默认选中key
// const activeIndex = ref('1');
// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);
// #endregion
// 创建变量设默认值
const configDataForm = ref('');
// 是否移动菜单列表样式
const showMobileMenuList = ref(false);

// #region 生命周期

if (props.componentData.configData) {
  // ssr服务器渲染直接调用
  configDataForm.value = JSON.parse(props.componentData.configData);

  // if (configDataForm.value.activeIndex) {
  //   activeIndex.value = configDataForm.value.activeIndex;
  // } else if (configDataForm.value.columnList.length > 0) {
  //   activeIndex.value = configDataForm.value.columnList[0].id.toString();
  // }
  // 后端编辑内容存在数据更新问题处理
  // 待数据更新完成后再执行
  if (props.componentData !== 3) {
    nextTick(() => {
      configDataForm.value = JSON.parse(props.componentData.configData);
      // if (configDataForm.value.activeIndex) {
      //   activeIndex.value = configDataForm.value.activeIndex;
      // } else if (configDataForm.value.columnList.length > 0) {
      //   activeIndex.value = configDataForm.value.columnList[0].id.toString();
      // }
    });
  }
}

// 默认选中key
const activeIndex = computed(() => {
  if (configDataForm.value.activeIndex) {
    return `${configDataForm.value.activeIndex.toString()}`;
  }
  return configDataForm.value.columnList[0].id.toString();
});

// 首页链接
const indexHrefUrl = computed(() => {
  return configDataForm.value.columnList[0].data
    ? configDataForm.value.columnList[0].data.hrefURL
    : configDataForm.value.columnList[0].hrefURL;
});

// #endregion

// #region 业务方法

const handleShowMoreMenu = (e) => {
  showMobileMenuList.value = !showMobileMenuList.value;
};

// 操作显不显示编辑内容按钮
/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};
/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};
/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};

// #endregion
</script>
<style lang="scss">
.top-nav-container {
  position: relative;
  width: 100%;

  .top-nav.top-nav-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .top-nav.top-nav-absolute {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }
}
</style>

<style scoped lang="scss">
/* 在屏幕宽度大于等于 1180px 时应用以下样式 */
@media screen and (device-width >= 1180px) {
  .top-nav {
    width: 100%;
    background-color: #334d60;
    transition: all 0.3s;

    .top-nav-menu-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px auto 10px;

      .top-nav-menu-logo {
        height: 80px;
        margin-right: 20px;

        img {
          height: 100%;
        }
      }
      // 导航菜单
      .top-nav-menu-list {
        display: flex;
        flex-wrap: wrap;

        .top-nav-menu-list-item {
          padding-bottom: 10px;
          margin: 8px;
          font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑',
            sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          letter-spacing: 1px;
          cursor: pointer;

          .menu-item-icon {
            display: none;
          }
        }

        .top-nav-menu-list-item a {
          color: #fff;
        }

        .top-nav-menu-list-item.active {
          color: #22cbf2;
          border-bottom: 2px solid #a8e1ee;
        }

        .top-nav-menu-list-item.active a {
          color: #22cbf2;
        }
      }
    }

    .top-nav-more-menu {
      display: none;
    }
  }
}

/* 在屏幕宽度小于等于 1180px 时应用以下样式 */
@media screen and (device-width <= 1180px) {
  .top-nav {
    width: 100% !important;
    min-width: auto !important;
    height: 16vw;
    background-color: #334d60;
    transition: all 0.3s;

    .top-nav-menu-container {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: normal;
      justify-content: flex-start;
      margin: 0;

      .top-nav-menu-logo {
        display: flex;
        justify-content: flex-start;
        margin-top: 2vw;
        img {
          height: 12vw;
          margin-left: 2vw;
        }
      }

      .top-nav-menu-list-mask-layer {
        display: none;
        width: 100%;
        height: 100vh;
        background-color: #666;
        opacity: 0.6;
      }

      .top-nav-menu-list-mask-layer.show {
        position: fixed;
        top: 0;
        left: 0;
        display: block;
      }

      .top-nav-menu-list {
        display: none;
      }

      .top-nav-menu-list.show {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 9999;
        display: block;
        width: 80%;
        height: 100vh;
        padding: 3vw 0;
        padding: 2vw;
        background-color: #fff;
        animation-name: fadeInTop;
        animation-duration: 0.2s;

        .menu-close {
          display: flex;
          width: 10vw;
          height: 10vw;
          margin-right: 2vw;
          margin-left: auto;
          background: url('image/nav-close.png') no-repeat center center;
          background-size: 100%;
        }

        .top-nav-menu-list-item {
          display: flex;
          align-items: center;
          padding: 2vw;
          padding-left: 2vw;
          margin-bottom: 0.8vw;
          font-size: 4.5vw;
          line-height: 10vw;
          border-bottom: 0.25vw dashed #666;

          a {
            display: block;
            width: calc(100% - 10vw);
            color: #666;
          }

          .menu-item-icon {
            width: 6vw;
            height: 6vw;
            margin: 2vw;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .top-nav-menu-list-item.active {
          color: #666;
          border-bottom: 0.25vw dashed #a8e1ee;
          a {
            color: #22cbf2;
          }
        }
      }

      .top-nav-more-menu {
        display: flex;
        width: 10vw;
        height: 10vw;
        margin-top: 2vw;
        margin-right: 2vw;
        margin-left: auto;
        background: url('image/top-nav-menu-white.png') no-repeat center center;
        background-size: 100%;
      }
    }
  }

  /* 从左到右进入 */
  /* stylelint-disable-next-line keyframes-name-pattern */
  @keyframes fadeInTop {
    from {
      opacity: 0;
      /* stylelint-disable-next-line function-no-unknown */
      transform: stranslate(0, -1000px);
    }

    to {
      opacity: 1;
      /* stylelint-disable-next-line function-no-unknown */
      transform: stranslate(0, 0);
    }
  }
}
</style>
