<template>
  <div style="width: 100%" class="copyright-two">
    <div
      class="copyright-container"
      :style="{
        'background-color': configDataForm.backgroundColor,
        color: configDataForm.fontColor,
      }"
      @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
      @mouseleave="handleShowOperationBtn(false)"
    >
      <div
        v-for="item in configDataForm.items"
        :key="item"
        class="item"
        :class="item.type == 2 && item.iconFullUrl ? 'img' : ''"
      >
        <a
          v-if="item.type == 1 && item.linkUrl"
          :style="{
            color: configDataForm.fontColor,
          }"
          :href="item.linkUrl"
          target="_blank"
        >
          {{ item.text }}
        </a>
        <span
          v-else-if="item.type == 1"
          :style="{
            color: configDataForm.fontColor,
          }"
        >
          {{ item.text }}
        </span>
        <img v-else :src="item.iconFullUrl" />
      </div>
      <client-only>
        <operation-container
          v-if="showOperationBtn && !showEditForm"
          :component-data="props.componentData"
          :show-edit-content-button="props.showEditContentButton"
          :show-edit-components-button="props.showEditComponentsButton"
          :show-delete-components-button="props.showDeleteComponentsButton"
          :show-insert-components-button="props.showInsertComponentsButton"
          :show-sort-components-button="props.showSortComponentsButton"
          @handle-show-edit-form="handleShowEditForm"
          @handle-change-components="handleChangeComponents"
          @handle-delete-components="handleDeleteComponents"
          @handle-insert-components="handleInsertComponents"
          @handle-sort-components="handleSortComponents"
        ></operation-container>
      </client-only>
    </div>
    <client-only>
      <el-drawer
        v-model="showEditForm"
        size="60%"
        :title="props.componentData.componentName"
      >
        <copyright-edit-form
          v-if="showEditForm"
          v-model="configDataForm"
          :component-data="props.componentData"
          @handle-show-edit-form="handleShowEditForm"
          @handle-edit-components-content="handleEditComponentsContent"
        ></copyright-edit-form>
      </el-drawer>
    </client-only>
  </div>
</template>

<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import OperationContainer from '../common/OperationContainer.vue';
import copyrightEditForm from './CopyrightEditForm.vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

// console.log('props-configDataForm:', props);

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量
const configDataForm = ref({
  items: [],
  backgroundColor: '#000000',
  fontColor: '#ffffff',
});

if (props.componentData.configData) {
  // 服务器渲染的直接调用
  configDataForm.value = JSON.parse(props.componentData.configData);
  configDataForm.value.items.sort((a, b) => {
    return a.sortNum - b.sortNum;
  });
  // 后台编辑的处理一下
  // nextTick(() => {
  //   configDataForm.value = JSON.parse(props.componentData.configData);
  //   configDataForm.value.items.sort((a, b) => {
  //     return a.sortNum - b.sortNum;
  //   });
  // });
}
// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法
// 操作显不显示编辑内容按钮
/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};
/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};
/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // console.log('configDataForm--1-CopyrightEditForm:', configDataForm);
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};

/**
 * 处理链接跳转
 */
const handleLink = (url) => {
  window.open(url);
};

// #endregion
</script>

<!-- web端样式 -->
<style scoped>
@media screen and (device-width >= 1180px) {
  .copyright-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
  }

  .copyright-container .item {
    display: inline;
    margin: 4px;
    font-size: 14px;
    color: #fff;
  }
}
</style>

<!-- 移动端样式 -->
<style scoped>
@media screen and (device-width <= 1180px) {
  .copyright-container {
    display: block;
    width: 100%;
    padding: 2vw 0;
    font-size: 3.5vw;
    line-height: 2;
    text-align: center;
  }

  .copyright-container .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0.5vw;
    color: #fff;
  }

  .copyright-container .item.img {
    display: inline;
  }

  .copyright-container .item.img img {
    display: inline;
    height: 3vw;
  }

  .copyright-container .item.img + div {
    display: inline;
  }

  .copyright-container .item > * {
    /* display: block; */
  }
}
</style>
