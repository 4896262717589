<template>
  <div class="image-main-wrap">
    <el-carousel
      ref="carouselObj"
      :interval="5000"
      :height="'100%'"
      class="carousel-list"
      arrow="always"
      @change="handleCarouselChange"
    >
      <el-carousel-item
        v-for="item in props.contentList"
        :key="item"
        :name="item.title"
      >
        <el-image
          style="width: 100%; height: 100%"
          :src="item.imageUrl"
          fit="cover"
        ></el-image>
      </el-carousel-item>
    </el-carousel>
    <div class="thumbnail-wrap">
      <div
        v-if="isShowScrollPrevNext"
        class="thumbnail-arrow"
        @click="handleThumbnailScroll('prev')"
      >
        <el-icon><ArrowLeftBold /></el-icon>
      </div>
      <div ref="thumbnailScroller" class="thumbnail-scroller">
        <div class="thumbnail-list">
          <div
            v-for="(item, index) in props.contentList"
            :key="index"
            :class="'thumbnail-item' + (index === activeIndex ? ' active' : '')"
            @click="handleThumbnailClick(index)"
          >
            <el-image
              class="thumbnail-item-img"
              :src="item.imageUrl"
              fit="cover"
            ></el-image>
            <div class="thumbnail-item-title">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isShowScrollPrevNext"
        class="thumbnail-arrow"
        @click="handleThumbnailScroll('next')"
      >
        <el-icon><ArrowRightBold /></el-icon>
      </div>
    </div>
    <div class="detail">
      <div class="content-warp">
        <div class="title">{{ props.contentList[activeIndex].title }}</div>
        <div class="digest">
          {{ props.contentList[activeIndex].digest }}
        </div>
      </div>
      <div v-if="isShowLeftNavCur" class="nav-warp">
        <LeftNav></LeftNav>
      </div>
    </div>
  </div>
</template>

<script setup>
// #region 引用
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons-vue';
import { useSSRContext } from 'vue';
import useSSRInitialStateStore from '@/stores/ssr-state';
import LeftNav from '../LeftNav.vue';
// #endregion

// #region props/emit
const props = defineProps({
  contentList: {
    type: Array,
    default: () => [],
  },
});
// #endregion

// #region 变量/常量

const isShowLeftNavCur = ref(false);
const carouselObj = ref();
const thumbnailScroller = ref();
const activeIndex = ref(0);
// const currentScrollIndex = ref(0);
const scrollerLeft = ref(0); // 初始滚动位置
const scrollStep = ref(170); // 每个缩略图的宽度
const isShowScrollPrevNext = ref(false); // 是否显示缩略图的滚动条上一个下一个按钮

// #endregion

// #region 业务方法

/**
 * 滚动到指定索引的缩略图
 */
const scrollToThumbnail = (index) => {
  const item = thumbnailScroller.value.querySelector(
    `.thumbnail-item:nth-child(${index + 1})`,
  );
  if (item) {
    item.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }
};

/**
 * 平滑滚动
 */
const smoothScroll = (targetLeft) => {
  const startLeft = thumbnailScroller.value.scrollLeft;
  const startTime = performance.now();

  function ease(t, b, c, d) {
    // 简单的缓动函数，这里使用的是线性缓动
    // 你可以根据需要替换为 easeInOutQuad 等其他缓动函数
    return (c * t) / d + b;
  }

  function animateScroll(currentTime) {
    const timeElapsed = currentTime - startTime;
    const run = ease(timeElapsed, startLeft, targetLeft - startLeft, 500); // 500ms 是动画持续时间
    thumbnailScroller.value.scrollLeft = run;
    if (timeElapsed < 500) {
      requestAnimationFrame(animateScroll);
    }
  }

  requestAnimationFrame(animateScroll);
};

/**
 * 前后滚动
 */
const handleThumbnailScroll = (direction) => {
  let newLeft;
  if (direction === 'next') {
    newLeft = scrollerLeft.value + scrollStep.value;
    // 限制最大滚动位置，防止超出范围
    if (
      newLeft >
      thumbnailScroller.value.scrollWidth - thumbnailScroller.value.clientWidth
    ) {
      newLeft =
        thumbnailScroller.value.scrollWidth -
        thumbnailScroller.value.clientWidth;
    }
  } else if (direction === 'prev') {
    newLeft = scrollerLeft.value - scrollStep.value;
    // 限制最小滚动位置
    if (newLeft < 0) {
      newLeft = 0;
    }
  }
  // 使用 requestAnimationFrame 实现平滑滚动
  smoothScroll(newLeft);
};

/**
 * 处理轮播图切换
 */
const handleCarouselChange = (index) => {
  activeIndex.value = index;
  if (isShowScrollPrevNext.value) {
    scrollToThumbnail(index);
  }
};

/**
 * 处理缩略图点击
 */
const handleThumbnailClick = (index) => {
  carouselObj.value.setActiveItem(index);
};

onServerPrefetch(async () => {
  const ssrContext = useSSRContext();
  isShowLeftNavCur.value =
    ssrContext.initialState.data.current.topNavComponent.isShowLeftNav;
  ssrContext.initialState.data.current.isShowLeftNavCur =
    isShowLeftNavCur.value;
});

onMounted(() => {
  if (!import.meta.env.SSR) {
    const ssrInitialState = useSSRInitialStateStore();
    isShowLeftNavCur.value = ssrInitialState.data.current.isShowLeftNavCur;
    isShowScrollPrevNext.value =
      thumbnailScroller.value.scrollWidth > thumbnailScroller.value.clientWidth;
  }
});

// #endregion
</script>

<style scoped lang="scss">
/* 在屏幕宽度大于等于 1180px 时应用以下样式 */
@media screen and (device-width >= 1180px) {
  .image-main-wrap {
    // display: flex;
    // width: 450px;
  }

  .el-carousel {
    position: relative;
    width: 100%;
    height: 500px;
    margin: auto;

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }

    :deep(.el-carousel__arrow) {
      width: 50px;
      height: 50px;
      background-color: rgba($color: #fff, $alpha: 30%) !important;

      .el-icon {
        font-size: 26px;
        color: #ddd;
      }
    }
  }

  .thumbnail-wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #e8e8e8;

    .thumbnail-scroller {
      display: flex;
      justify-content: center;
      width: calc(100% - 120px);
      overflow: auto hidden;
      scrollbar-width: none;
    }

    .thumbnail-arrow {
      align-self: center;
      width: 60px;
      height: 60px;
      font-size: 36px;
      line-height: 60px;
      color: #999;
      text-align: center;
      cursor: pointer;
    }
  }

  .thumbnail-list {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 15px;

    .thumbnail-item {
      display: flex;
      flex-direction: column;
      width: 160px;
      height: 160px;
      text-align: center;
      cursor: pointer;
      border: 1px solid #bbb;

      &.active {
        border: 2px solid #2588ee;
      }

      .thumbnail-item-img {
        width: 100%;
        height: 120px;
      }

      .thumbnail-item-title {
        height: 40px;
        padding: 0 5px;
        overflow: hidden;
        font-size: 14px;
        line-height: 40px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .detail {
    display: flex;
    flex-direction: row;
    padding-bottom: 15px;

    .content-warp {
      display: flex;
      flex: 1;
      flex-direction: column;

      .title {
        padding: 20px 0;
        font-size: 24px;
        font-weight: 700;
        color: #333;
      }
      .digest {
        font-size: 16px;
        line-height: 30px;
        color: #444;
      }
    }

    .nav-warp {
      display: flex;
      width: 200px;
      margin-top: 20px;
      margin-left: 50px;
    }
  }
}

/* 在屏幕宽度小于等于 1180px 时应用以下样式 */
@media screen and (device-width <= 1180px) {
  .image-main-wrap {
    // display: flex;
    // width: 450px;
  }

  .el-carousel {
    position: relative;
    width: 100%;
    height: 50vw;
    margin: auto;

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }

    :deep(.el-carousel__arrow) {
      width: 12vw;
      height: 12vw;
      background-color: rgba($color: #fff, $alpha: 30%) !important;

      .el-icon {
        font-size: 7vw;
        color: #ddd;
      }
    }
  }

  .thumbnail-wrap {
    display: none;
    flex-direction: row;
    width: 100%;
    background-color: #e8e8e8;

    .thumbnail-scroller {
      display: flex;
      justify-content: center;
      width: calc(100% - 120px);
      overflow: auto hidden;
      scrollbar-width: none;
    }

    .thumbnail-arrow {
      align-self: center;
      width: 60px;
      height: 60px;
      font-size: 36px;
      line-height: 60px;
      color: #999;
      text-align: center;
      cursor: pointer;
    }
  }

  .thumbnail-list {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 15px;

    .thumbnail-item {
      display: flex;
      flex-direction: column;
      width: 160px;
      height: 160px;
      text-align: center;
      cursor: pointer;
      border: 1px solid #bbb;

      &.active {
        border: 2px solid #2588ee;
      }

      .thumbnail-item-img {
        width: 100%;
        height: 120px;
      }

      .thumbnail-item-title {
        height: 40px;
        padding: 0 5px;
        overflow: hidden;
        font-size: 14px;
        line-height: 40px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .content-warp {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;

    .title {
      padding: 5vw 0;
      font-size: 5.5vw;
      font-weight: 700;
      color: #333;
    }
    .digest {
      font-size: 4vw;
      line-height: 2em;
      color: #444;
    }
  }
}
</style>
