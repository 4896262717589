<template>
  <div class="image-text-form-container">
    <el-form
      ref="ruleFormRef"
      :model="configDataForm"
      :rules="rules"
      label-width="180px"
      :size="formSize"
    >
      <div class="card-frame other-card-frame">
        <div class="card-frame-top">基础信息</div>
        <div class="card-frame-container">
          <el-form-item label="背景图片：" prop="backGroundImageObj">
            <zc-upload
              v-model="configDataForm.backGroundImageObj"
              :limit-single-size="1048576"
              :image-thumbnail-width="240"
              type="singleImage"
              :enable-popup="false"
              :show-no-image="true"
              :small-text="true"
              tip="说明：上传的图片大小不得超过1M，建议尺寸1920*1080px"
              @change="handleInputChange('backGroundImageObj')"
            >
              <template #singleImageError>
                <div class="el-image__error image-error-show">图片丢失</div>
              </template>
            </zc-upload>
          </el-form-item>
          <el-form-item
            label="标题图标："
            prop="iconImageObj"
            :rules="rules.backGroundImageObj"
          >
            <zc-upload
              v-model="configDataForm.iconImageObj"
              :limit-single-size="1048576"
              type="singleImage"
              :enable-popup="false"
              :show-no-image="true"
              :small-text="true"
              tip="说明：上传的图片大小不得超过1M，建议尺寸40*40px"
              @change="handleInputChange('iconImageObj')"
            >
              <template #singleImageError>
                <div class="el-image__error image-error-show">图片丢失</div>
              </template>
            </zc-upload>
          </el-form-item>
          <el-form-item label="标题：" prop="title">
            <el-input v-model="configDataForm.title" />
          </el-form-item>
          <el-form-item label="标题字体颜色：" prop="fontColor">
            <el-color-picker v-model="configDataForm.fontColor" />
          </el-form-item>
        </div>
      </div>
      <div class="card-frame other-card-frame">
        <div class="card-frame-top">左侧内容区域</div>
        <div class="card-frame-container">
          <el-form-item
            label="左侧上图片："
            prop="leftTopImageObj"
            :rules="rules.backGroundImageObj"
          >
            <zc-upload
              v-model="configDataForm.leftTopImageObj"
              :limit-single-size="1048576"
              :image-thumbnail-width="120"
              type="singleImage"
              :enable-popup="false"
              :show-no-image="true"
              :small-text="true"
              tip="说明：上传的图片大小不得超过1M，建议尺寸460*200px"
              @change="handleInputChange('leftTopImageObj')"
            >
              <template #singleImageError>
                <div class="el-image__error image-error-show">图片丢失</div>
              </template>
            </zc-upload>
          </el-form-item>
          <el-form-item
            label="左侧上标题："
            prop="leftTopTitle"
            :rules="rules.title"
          >
            <el-input v-model="configDataForm.leftTopTitle" />
          </el-form-item>
          <el-form-item
            label="左侧上描述："
            prop="leftTopSubTitle"
            :rules="rules.subTitle"
          >
            <el-input
              v-model="configDataForm.leftTopSubTitle"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 20 }"
            />
          </el-form-item>
          <el-form-item
            label="左侧上背景颜色："
            prop="leftTopBackgroundColor"
            :rules="rules.backgroundColor"
          >
            <el-color-picker v-model="configDataForm.leftTopBackgroundColor" />
          </el-form-item>
          <el-form-item
            label="左侧上跳转："
            prop="leftTopLinkUrl"
            :rules="rules.linkUrl"
          >
            <el-input v-model="configDataForm.leftTopLinkUrl" />
          </el-form-item>
          <el-form-item
            label="左侧下图片："
            prop="leftBottomImageObj"
            :rules="rules.backGroundImageObj"
          >
            <zc-upload
              v-model="configDataForm.leftBottomImageObj"
              :limit-single-size="1048576"
              :image-thumbnail-width="120"
              type="singleImage"
              :enable-popup="false"
              :show-no-image="true"
              :small-text="true"
              tip="说明：上传的图片大小不得超过1M，建议尺寸460*200px"
              @change="handleInputChange('leftBottomImageObj')"
            >
              <template #singleImageError>
                <div class="el-image__error image-error-show">图片丢失</div>
              </template>
            </zc-upload>
          </el-form-item>
          <el-form-item
            label="左侧下标题："
            prop="leftBottomTitle"
            :rules="rules.title"
          >
            <el-input v-model="configDataForm.leftBottomTitle" />
          </el-form-item>
          <el-form-item
            label="左侧下描述："
            prop="leftBottomSubTitle"
            :rules="rules.subTitle"
          >
            <el-input
              v-model="configDataForm.leftBottomSubTitle"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 20 }"
            />
          </el-form-item>
          <el-form-item
            label="左侧下背景颜色："
            prop="leftBottomBackgroundColor"
            :rules="rules.backgroundColor"
          >
            <el-color-picker
              v-model="configDataForm.leftBottomBackgroundColor"
            />
          </el-form-item>
          <el-form-item
            label="左侧下跳转："
            prop="leftBottomLinkUrl"
            :rules="rules.linkUrl"
          >
            <el-input v-model="configDataForm.leftBottomLinkUrl" />
          </el-form-item>
        </div>
      </div>
      <div class="card-frame other-card-frame">
        <div class="card-frame-top">右侧内容区域</div>
        <div class="card-frame-container">
          <el-form-item
            label="图片："
            prop="rightImageObj"
            :rules="rules.backGroundImageObj"
          >
            <zc-upload
              v-model="configDataForm.rightImageObj"
              :limit-single-size="1048576"
              :image-thumbnail-width="120"
              type="singleImage"
              :enable-popup="false"
              :show-no-image="true"
              :small-text="true"
              tip="说明：上传的图片大小不得超过1M，建议尺寸860*510px"
              @change="handleInputChange('rightImageObj')"
            >
              <template #singleImageError>
                <div class="el-image__error image-error-show">图片丢失</div>
              </template>
            </zc-upload>
          </el-form-item>
          <el-form-item label="标题：" prop="rightTitle" :rules="rules.title">
            <el-input v-model="configDataForm.rightTitle" />
          </el-form-item>
          <el-form-item
            label="描述："
            prop="rightSubTitle"
            :rules="rules.subTitle"
          >
            <el-input
              v-model="configDataForm.rightSubTitle"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 20 }"
            />
          </el-form-item>
          <el-form-item
            label="背景颜色："
            prop="rightBackgroundColor"
            :rules="rules.backgroundColor"
          >
            <el-color-picker v-model="configDataForm.rightBackgroundColor" />
          </el-form-item>
          <el-form-item
            label="左侧下跳转："
            prop="rightLinkUrl"
            :rules="rules.linkUrl"
          >
            <el-input v-model="configDataForm.rightLinkUrl" />
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="image-text-btn-container">
      <el-button @click="handleCancel()">取消</el-button>
      <el-button type="primary" @click="handleSave(ruleFormRef)"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script setup>
// #region 引用
import {
  verifyCharacterLength,
  verifyLinkUrl,
} from '@/utils/common/form-verify-util';

// #endregion

// #region props/emit

/**
 * 属性
 */
const props = defineProps({
  componentData: {
    type: Object,
    default() {},
  },
});
/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'update:modelValue',
  'handle-edit-components-content',
]);

// #endregion

// #region 变量/常量

// 栏目数据列表

// 表单size
const formSize = ref('default');
const ruleFormRef = ref();
// 表单绑定对象
const configDataForm = ref({
  backGroundImageObj: null,
  backGroundImageName: '',
  backGroundImageFileKey: 'backgroundImage',
  backGroundColor: '#008bd5',
  title: '示例标题',
  fontColor: '#ffffff',
  iconImageObj: null,
  iconName: '示例图标1.png',
  iconFileKey: 'iconImage',
  leftTopImageObj: null,
  leftTopImageName: '示例图标1.png',
  leftTopImageFileKey: 'itemFile1',
  leftTopTitle: '信息门户',
  leftTopSubTitle: '信息门户',
  leftTopBackgroundColor: '#DBC283',
  leftTopLinkUrl: 'https://www.baidu.com',
  leftBottomImageObj: null,
  leftBottomImageName: '示例图标1.png',
  leftBottomImageFileKey: 'itemFile2',
  leftBottomTitle: '信息门户',
  leftBottomSubTitle: '信息门户',
  leftBottomBackgroundColor: '#75A5CF',
  leftBottomLinkUrl: 'https://www.baidu.com',
  rightImageObj: null,
  rightImageName: '示例图标1.png',
  rightImageFileKey: 'itemFile3',
  rightTitle: '信息门户',
  rightSubTitle: '信息门户',
  rightBackgroundColor: '#4EACA2',
  rightLinkUrl: 'https://www.baidu.com',
  items: [
    {
      sortNum: 1,
      title: '信息门户',
      subTitle: '信息门户',
      itemFileName: '示例图标1.png',
      itemFileKey: 'itemFile1',
      backGroundColor: '#008bd5',
      linkType: 2,
      linkUrl: 'https://www.baidu.com',
    },
    {
      sortNum: 2,
      title: '信息门户',
      subTitle: '信息门户',
      backGroundColor: '#008bd5',
      itemFileName: '示例图标2.png',
      itemFileKey: 'itemFile2',
      linkType: 2,
      linkUrl: 'https://www.baidu.com',
    },
    {
      sortNum: 3,
      title: '信息门户',
      subTitle: '信息门户',
      itemFileName: '示例图标2.png',
      backGroundColor: '#008bd5',
      itemFileKey: 'itemFile3',
      linkType: 2,
      linkUrl: 'https://www.baidu.com',
    },
  ],
});

if (props.componentData.configData) {
  configDataForm.value = JSON.parse(props.componentData.configData);

  configDataForm.value.iconImageObj = {
    name: configDataForm.value.iconName,
    url: configDataForm.value.iconFullUrl,
  };

  configDataForm.value.backGroundImageObj = {
    name: configDataForm.value.backGroundImageName,
    url: configDataForm.value.backGroundImageFullUrl,
  };

  if (configDataForm.value.items && configDataForm.value.items.length > 0) {
    // 左上
    const leftTopItem = configDataForm.value.items[0];
    configDataForm.value.leftTopImageObj = {
      name: leftTopItem.itemFileName,
      url: leftTopItem.itemFileFullUrl,
    };
    configDataForm.value.leftTopImageName = leftTopItem.itemFileName;
    configDataForm.value.leftTopImageFileKey = 'itemFile1';
    configDataForm.value.leftTopTitle = leftTopItem.title;
    configDataForm.value.leftTopSubTitle = leftTopItem.subTitle;
    configDataForm.value.leftTopLinkUrl = leftTopItem.linkUrl;
    configDataForm.value.leftTopBackgroundColor =
      leftTopItem.backgroundColor || '#DBC283';
    // 左下
    const leftBottomItem = configDataForm.value.items[1];
    configDataForm.value.leftBottomImageObj = {
      name: leftBottomItem.itemFileName,
      url: leftBottomItem.itemFileFullUrl,
    };
    configDataForm.value.leftBottomImageFileKey = 'itemFile2';
    configDataForm.value.leftBottomImageName = leftBottomItem.itemFileName;
    configDataForm.value.leftBottomTitle = leftBottomItem.title;
    configDataForm.value.leftBottomSubTitle = leftBottomItem.subTitle;
    configDataForm.value.leftBottomLinkUrl = leftBottomItem.linkUrl;
    configDataForm.value.leftBottomBackgroundColor =
      leftBottomItem.backgroundColor || '#75A5CF';
    // 右边
    const rightItem = configDataForm.value.items[2];
    configDataForm.value.rightImageObj = {
      name: rightItem.itemFileName,
      url: rightItem.itemFileFullUrl,
    };
    configDataForm.value.rightImageFileKey = 'itemFile3';
    configDataForm.value.rightImageName = rightItem.itemFileName;
    configDataForm.value.rightTitle = rightItem.title;
    configDataForm.value.rightSubTitle = rightItem.subTitle;
    configDataForm.value.rightLinkUrl = rightItem.linkUrl;
    configDataForm.value.rightBackgroundColor =
      rightItem.backgroundColor || '#4EACA2';
  }
}

// 表单规则
const rules = ref({
  backGroundImageObj: [{ required: true, message: '必填项', trigger: 'blur' }],
  fontColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  backgroundColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  subTitle: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 400, trigger: 'blur' },
  ],
  title: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 20, trigger: 'blur' },
  ],
  linkUrl: [
    { validator: verifyLinkUrl, clen: 100, trigger: 'blur' },
    { validator: verifyLinkUrl, clen: 50, trigger: 'blur' },
  ],
});
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 处理输入框变化的事件
 *
 * @param inputKey 输入框的键名
 */
const handleInputChange = (inputKey) => {
  ruleFormRef.value.validateField(inputKey, (valid) => {
    console.log(
      `🚀 ~ ruleFormRef.value.validateField ~ ${inputKey}-valid:`,
      valid,
    );
  });
};

/**
 * 处理取消
 */
const handleCancel = () => {
  emits('handle-show-edit-form', false);
};
/**
 * 处理保存
 */
const handleSave = (form) => {
  if (!form) return;

  form.validate((valid) => {
    if (valid) {
      // 待数据更新完成后才执行
      nextTick(() => {
        emits('handle-edit-components-content', false);
        emits('update:modelValue', {
          backGroundImageObj: configDataForm.value.backGroundImageObj,
          backGroundImageName: configDataForm.value.backGroundImageName,
          backGroundImageFileKey:
            configDataForm.value.backGroundImageFileKey || 'backgroundImage',
          backGroundColor: configDataForm.value.backGroundColor,
          title: configDataForm.value.title,
          fontColor: configDataForm.value.fontColor,
          iconImageObj: configDataForm.value.iconImageObj,
          iconName: configDataForm.value.iconName,
          iconFileKey: configDataForm.value.iconFileKey,
          items: [
            {
              sortNum: 1,
              title: configDataForm.value.leftTopTitle,
              subTitle: configDataForm.value.leftTopSubTitle,
              itemFileObj: configDataForm.value.leftTopImageObj,
              itemFileName: configDataForm.value.leftTopImageName,
              itemFileKey: configDataForm.value.leftTopImageFileKey,
              backGroundColor: configDataForm.value.leftTopBackgroundColor,
              linkType: 1,
              linkUrl: configDataForm.value.leftTopLinkUrl,
            },
            {
              sortNum: 2,
              title: configDataForm.value.leftBottomTitle,
              subTitle: configDataForm.value.leftBottomSubTitle,
              itemFileObj: configDataForm.value.leftBottomImageObj,
              itemFileName: configDataForm.value.leftBottomImageName,
              itemFileKey: configDataForm.value.leftBottomImageFileKey,
              linkType: 1,
              backGroundColor: configDataForm.value.leftBottomBackgroundColor,
              linkUrl: configDataForm.value.leftBottomLinkUrl,
            },
            {
              sortNum: 3,
              title: configDataForm.value.rightTitle,
              subTitle: configDataForm.value.rightSubTitle,
              itemFileObj: configDataForm.value.rightImageObj,
              itemFileName: configDataForm.value.rightImageName,
              itemFileKey: configDataForm.value.rightImageFileKey,
              linkType: 2,
              backGroundColor: configDataForm.value.rightBackgroundColor,
              linkUrl: configDataForm.value.rightLinkUrl,
            },
          ],
        });
      });
      // TODO 待完成保存逻辑
    }
  });
};
// #endregion
</script>

<style scoped>
:deep(.el-form) {
  width: 100%;
  padding: 20px;
}

:deep(.el-color-picker__trigger) {
  width: 100px;
}
</style>
