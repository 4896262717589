<template>
  <div class="location">
    <span>当前位置：</span>
    <span><a>首页</a></span>
    <span v-for="(item, index) in curMapColumnList" :key="index" class="item">
      <a :href="item.url">{{ item.title }}</a>
    </span>
  </div>
</template>

<script setup>
// #region 引用
import useSSRInitialStateStore from '@/stores/ssr-state';
import { useSSRContext } from 'vue';
// #endregion

// #region props/emit
const props = defineProps({
  contentNavList: {
    type: Array,
    default: () => [],
  },
});
// #endregion

// #region 变量/常量

const ssrInitialState = useSSRInitialStateStore();
const curMapColumnList = ref([]); // 当前位置的栏目列表

// #endregion

// #region 业务方法

onServerPrefetch(async () => {
  const ssrContext = useSSRContext();
  curMapColumnList.value =
    ssrContext.initialState.data.current.curMapColumnList;
  if (props.contentNavList.length > 0) {
    curMapColumnList.value.push(...props.contentNavList);
  }
});

if (!import.meta.env.SSR) {
  // 客户端从initialState读取数据
  curMapColumnList.value = ssrInitialState.data.current.curMapColumnList;
}

// #endregion
</script>

<style scoped>
@media screen and (device-width >= 1180px) {
  .location {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    height: 50px;
    padding: 0 0 0 10px;
    margin: 0 auto;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 50px;
    background-color: #eee;
  }
  .location .item,
  .location span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }

  .location span a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    color: #333;
    text-decoration: none;
  }

  .location span:first-of-type::before {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 2px;
    content: '';
    background: url('./image/home.png') no-repeat;
    background-size: 14px 14px;
  }

  .location span:not(:first-of-type, :last-of-type)::after {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 5px;
    content: '';
    background: url('./image/next.png') no-repeat center center;
    background-size: 12px 12px;
  }
}
</style>

<style scoped>
@media screen and (device-width <= 1180px) {
  .location {
    display: flex;
    align-items: center;
    width: 100%;
    height: 12.5vw;
    padding: 2vw 3vw;
    margin: 0 auto;
    margin-bottom: 1vw;
    font-size: 4vw;
    line-height: 2;
  }

  .location .item,
  .location span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .location span a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #333;
    text-decoration: none;
  }

  .location span:first-of-type::before {
    display: inline-block;
    width: 4vw;
    height: 4vw;
    margin-right: 1vw;
    content: '';
    background: url('./image/location.png');
    background-position-y: 0.1vw;
    background-size: cover;
  }

  .location span:not(:first-of-type, :last-of-type)::after {
    display: inline-block;
    width: 3.5vw;
    height: 3.5vw;
    margin: 0 1vw;
    content: '';
    background: url('./image/next.png') no-repeat center center;
    background-size: 3.5vw 3.5vw;
  }
}
</style>
